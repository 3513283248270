import React from 'react'
import createReactClass from 'create-react-class'

import Subheader from 'material-ui/Subheader'
import s from '../empty.css'

import IconButton from 'material-ui/IconButton'
import CancelIcon from 'material-ui/svg-icons/navigation/cancel'
import AutoRenewIcon from 'material-ui/svg-icons/action/autorenew'
import FilterListIcon from 'material-ui/svg-icons/content/filter-list'
import ViewModuleIcon from 'material-ui/svg-icons/action/view-module'

import WapplrAvatar from '../Wapplravatar'
import wapplravatarhelpers from '../Wapplravatar/helpers'
import Chip from 'material-ui/Chip'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

const SearchFeedHeader = createReactClass({
    getInitialState: function() {
        const sticky = ('undefined' !== typeof window && window.scrollY && this.props.enablestickystyle) ? window.scrollY : 0
        return {
            isMounted: false,
            showrenew: false,
            showfilterlist: this.props.showfilterlist,
            showposts: this.props.showposts,
            sticky: sticky
        }
    },
    showRenew: function() {
        if (this.state.isMounted) {
            this.setState({
                showrenew: true,
                showfilterlist: false,
                showposts: false
            })
        }
    },
    showFilterlist: function() {
        if (this.state.isMounted) {
            this.setState({
                showfilterlist: true,
                showposts: false
            })
        }
    },
    stickyOn: function() {
        if (this.state.isMounted) {
            if (!this.state.sticky) {
                this.setState({ sticky: true })
                this.state.sticky = true
            }
        }
    },
    stickyOff: function() {
        if (this.state.isMounted) {
            if (this.state.sticky) {
                this.setState({ sticky: false })
                this.state.sticky = false
            }
        }
    },
    lastSticky: 0,
    stickyListener: function() {
        const sticky = ('undefined' !== typeof window && window.scrollY && this.props.enablestickystyle) ? window.scrollY : 0
        if (sticky && !this.lastSticky) {
            this.stickyOn()
            this.lastSticky = 1
        }
        if (!sticky && this.lastSticky) {
            this.stickyOff()
            this.lastSticky = 0
        }
    },
    isIOs: function() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    },
    componentDidMount() {
        this.state.isMounted = true
        this.setState({ isMounted: true })
        if (this.props.enablestickystyle) {
            if (this.isIOs()) {
                window.addEventListener('scroll', this.stickyListener)
            } else {
                window.addEventListener('scroll', this.stickyListener)
            }
        }
        setTimeout(this.stickyListener)
    },
    componentWillUnmount: function() {
        this.state.isMounted = false
        this.setState({ isMounted: false })
        if (this.props.enablestickystyle) {
            if (this.isIOs()) {
                window.removeEventListener('scroll', this.stickyListener)
            } else {
                window.removeEventListener('scroll', this.stickyListener)
            }
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    clear: function(e) {

        const {
            historyHelpers, store, enableSearchQueryDataInheritance = [
                'text', 'user', 'sgt', 'slt', 'asgt', 'aslt'
            ]
        } = this.props
        const so = historyHelpers.getDataFromHistory({ store })
        const h = {
            text: so.text,
            user: so.user, ...enableSearchQueryDataInheritance.filter((key) => typeof so[key] !== 'undefined').reduce((o, key) => {
                o[key] = so[key]
                return o
            }, {})
        }
        const searchtext = historyHelpers.objectToString(h)
        historyHelpers.historyPush({ search: searchtext })
        if (e) e.preventDefault()

    },
    clearAuthor: function(e) {

        const historyHelpers = this.props.historyHelpers
        const so = historyHelpers.getDataCurrent()
        so.user = ''
        so.page = ''
        const searchtext = historyHelpers.objectToString(so)
        historyHelpers.historyPush({ search: searchtext })
        if (e) e.preventDefault()

    },
    renew: function(e) {

        const historyHelpers = this.props.historyHelpers
        const search = historyHelpers.getDataCurrent()
        search.page = ''
        const history = this.props.history
        const to = (history.location && history.location.pathname) ? history.location.pathname : ''

        historyHelpers.objectToSearch(to, search)
        if (e) e.preventDefault()

    },
    getActiveFiltersNumber: function() {
        const {
            disableSearchQueryDatas = [
                'text', 'page', 'user', 'posttype', 'sgt', 'slt'
            ]
        } = this.props
        const { historyHelpers, store } = this.props
        const so = historyHelpers.getDataFromHistory({ store })
        let n = 0
        Object.keys(so).map(function(t) {
            let go = true
            disableSearchQueryDatas.map(function(dt) {
                if (t === dt) go = false
            })
            if (go) {
                if (typeof so[t] == 'object' && so[t].length) n = n + so[t].length
                if (typeof so[t] == 'object' && !so[t].length) {
                    Object.keys(so[t]).map(function() {
                        n = n + 1
                    })
                }
                if (typeof so[t] == 'string') n = n + 1
            }
        })
        return n
    },
    showfilterlist: function(e) {
        if (this.props.onShowFilterList) this.props.onShowFilterList(e)
    },
    defaultResulstText: function({ skip, currentpage, limit, count, length }) {
        let s = Number(skip)
        const l = (currentpage * limit < count) ? currentpage * limit : count
        const r = (count > 1) ? 'results' : 'result'
        let t
        if (s > 0) {
            s = s + 1
            t = 'shown ' + s + '-' + l + ' of ' + count + ' ' + r
        } else {
            t = 'shown ' + length + ' of ' + count + ' ' + r
        }
        return t
    },
    getFoundAnotherSerachItem: function({ so }) {
        const {
            disableSearchQueryDatas = [
                'text', 'page', 'user', 'posttype', 'sgt', 'slt'
            ]
        } = this.props

        let foundanothersearchitem = false
        Object.keys(so).map(function(o) {
            let go = true
            disableSearchQueryDatas.map(function(dt) {
                if (o === dt) go = false
            })
            if (go && so[o]) {
                foundanothersearchitem = true
            }
        })
        return foundanothersearchitem
    },

    render: function() {

        const {
            style = s,
            className,
            historyHelpers,
            store,
            posts,
            data,
            author,
            avatarstyle = s,
            curlang = {},
            getthumb
        } = this.props

        const {
            activeSearchSubHeaderText = 'Search: ',
            notFoundSearchSubHeaderText = 'No matches',
            emptySearchSubHeaderText = 'Add a text',
            clearFiltersText = 'clear filters',
            updateFiltersText = 'update filters',
            showFiltersText = 'show filters',
            showPostsText = 'show posts',
            getResultsText = this.defaultResulstText
        } = curlang

        const touchEnd = this.touchEnd
        const so = historyHelpers.getDataFromHistory({ store })
        const resultstext = (data && posts && posts.length) ? ' | ' + getResultsText({
            ...data,
            length: posts.length
        }) : ''
        const foundanothersearchitem = this.getFoundAnotherSerachItem({ so })

        const filtersNumber = (foundanothersearchitem) ? this.getActiveFiltersNumber() : 0
        let rclassName = (className) ? style.searchfeedheader + ' ' + className : style.searchfeedheader
        if (this.state.sticky) rclassName = rclassName + ' ' + style.searchfeedheadersticky

        if (author && !author.getName) wapplravatarhelpers({ user: author })

        return (
            <Subheader className={rclassName}>
                {
                    <div className={style.searchfeedheaderLeft}>
                        {(posts && posts.length && so.text) ?
                            activeSearchSubHeaderText + so.text + resultstext :
                            (so.text) ?
                                notFoundSearchSubHeaderText :
                                emptySearchSubHeaderText}
                    </div>
                }
                <div className={style.searchfeedheaderRight}>
                    {(author) ?
                        <div className={style.chip}>
                            <Chip onRequestDelete={function(e) {
                                touchEnd(e, 'clearAuthor', 'click')
                            }}>
                                <div className={style.chipavatar}><WapplrAvatar user={author} style={avatarstyle}
                                                                                getthumb={getthumb} /></div>
                                <div className={style.chipname}>{author.getName()}</div>
                            </Chip>
                        </div> : null
                    }
                    {(/*this.state.showrenew && !this.state.showfilterlist || */foundanothersearchitem && !this.state.showfilterlist && !this.state.showrenew) ?
                        <div
                            className={(filtersNumber) ? style.searchfeedheaderbutton + ' ' + style.searchfeedheaderSuperRightMargin : style.searchfeedheaderbutton}>
                            <IconButton label={clearFiltersText} onTouchTap={function(e) {
                                touchEnd(e, 'clear', 'click')
                            }} onTouchStart={function() {
                            }}>
                                <CancelIcon />
                                {(filtersNumber) ?
                                    <div className={style.searchfeedheaderSuper}>
                                        {filtersNumber}
                                    </div>
                                    : null
                                }
                            </IconButton>
                        </div> : null
                    }
                    {(this.state.showrenew) ?
                        <div className={style.searchfeedheaderbutton}>
                            <IconButton label={updateFiltersText} onTouchTap={function(e) {
                                touchEnd(e, 'renew', 'click')
                            }} onTouchStart={function() {
                            }}>
                                <AutoRenewIcon />
                            </IconButton>
                        </div>
                        : null
                    }
                    {(this.state.showfilterlist && !this.state.showposts) ?
                        <div
                            className={(filtersNumber) ? style.searchfeedheaderbutton + ' ' + style.searchfeedheaderSuperRightMargin : style.searchfeedheaderbutton}>
                            <IconButton label={showFiltersText} onTouchTap={function(e) {
                                touchEnd(e, 'showfilterlist', 'click')
                            }} onTouchStart={function() {
                            }}>
                                <FilterListIcon />
                                {(filtersNumber) ?
                                    <div className={style.searchfeedheaderSuper}>
                                        {filtersNumber}
                                    </div>
                                    : null
                                }
                            </IconButton>
                        </div>
                        : null
                    }
                    {(this.state.showposts && !this.state.showfilterlist) ?
                        <div className={style.searchfeedheaderbutton}>
                            <IconButton label={showPostsText} onTouchTap={function(e) {
                                touchEnd(e, 'showfilterlist', 'click')
                            }} onTouchStart={function() {
                            }}>
                                <ViewModuleIcon />
                            </IconButton>
                        </div>
                        : null
                    }
                </div>
            </Subheader>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <SearchFeedHeader {...this.props} ref={(setRef) ? function(e) {
                setRef('searchheader', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, avatarstyle = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style, avatarstyle }

        return (
            <R {...input} />
        )
    }
})
