import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

export default createReactClass({
    getInitialState() {
        return { isMounted: false }
    },
    componentWillMount() {
        this.setState({ isMounted: true })
    },
    render: function() {
        let { content, children, style } = this.props
        const parentProps = this.props

        function r(props) {

            if (!props.state.isMounted) return (<div />)

            if (children && React.isValidElement(children)) return children

            if (children) return (<div>{children}</div>)

            if (content && content.displayName) {
                const C = content
                return (<C {...parentProps} />)
            }

            if (content && React.isValidElement(content)) return content

            if (content) return React.Children.only(content)

            return (<div />)

        }

        const R = (style) ? withStyles(style)(r) : r
        return (
            <R state={this.state} />
        )
    }
})
