/*Import dependencies*/
import React from 'react'
import createReactClass from 'create-react-class'
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme'

/*Components*/
import { wapplrhelpers } from '../../../components'

/*Templates*/
import ContentHome from '../../contents/content_home'
import ContentSearch from '../../contents/content_search'
import ContentArchive from '../../contents/content_archive'
import posttypesPlugins from '../../posttypes'

/*Modules*/
import someconfig from './config'
import gettaxonomies from './taxonomies'
import getmenus from './getmenus'
import getfeeddatas from './feeddatas'
import searchdata from './searchdata'
import refsobject from './refsobject'
import geteditdata from './editdata'

import requests from '../requests'

export default function(c) {

    const {
        history,
        style,
        headerstyle,
        logostyle,
        logohref,
        startlogostyle,
        logotext,
        logospan,
        curlang,
        searchRoute,
        defaultSearchPostType,
        defaultPostSearchObjectName,
        defaultPostSlug,
        maincolor,
        localstorename,
        routes,
        maxuploadfilesize,
        enableuploadtypes,
        getthumb,
        defaultthumbnail,
        termspostid,
        cookiepostid,
        privancyid,
        contactpostid,
        googleTrackingId,
        siteurl,
        sitename,
        siteimage,
        staticscript,
        staticserver,
        getthumbstyle
    } = someconfig(c)

    const uploadconfig = {
        maxuploadfilesize,
        enableuploadtypes
    }

    const { onDrawerChange, pageRefs, getPageDataObject } = wapplrhelpers.page()
    const {
        getMenu,
        menuRefs,
        getMenuDataObject,
        menuFromTaxonomies,
        menuFromPostTypes
    } = wapplrhelpers.menu({ localstorename })
    const { getFeed, feedRefs, getFeedDataObject } = wapplrhelpers.feed()
    const {
        searchBarOnFocusHandle,
        searchBarOnCloseHandle,
        setSearchData,
        searchDefaultsFromTaxonomies,
        searchBarRefs,
        getSearchBar,
        getSearchBarDataObject
    } = wapplrhelpers.search()
    const { userConstructHelpers } = wapplrhelpers.avatar

    const store = (c && c.store) ? c.store : null
    const state = (c && c.store && c.store.getState) ? c.store.getState() : null

    const { taxonomies, posttypes } = gettaxonomies({ state })
    const searchData = searchdata({ setSearchData, searchDefaultsFromTaxonomies, taxonomies, history })

    const menus = getmenus({
        style,
        posttypesPlugins,
        curlang,
        searchData,
        taxonomies,
        posttypes,
        menuFromTaxonomies,
        menuFromPostTypes,
        routes,
        getthumb,
        getthumbstyle,
        termspostid,
        cookiepostid,
        privancyid,
        contactpostid
    })

    const feedDatas = getfeeddatas({ taxonomies, posttypes, getFeedDataObject })

    const menuData = getMenuDataObject('main')
    const searchBarData = getSearchBarDataObject('main')
    const pageData = getPageDataObject('main')

    const { refsObject, setRef } = refsobject()

    const { editdata } = geteditdata({
        curlang,
        posttypesPlugins,
        history,
        searchData,
        posttypesData: posttypes,
        uploadconfig,
        routes,
        getthumb,
        getthumbstyle,
        taxonomies,
        store: c.store
    })

    const storedConfig = c.storedConfig

    const modules = posttypesPlugins.initmodules({
        storedConfig,
        curlang,
        menus,
        history,
        routes,
        getthumb,
        getthumbstyle,
        posttypes,
        taxonomies,
        searchData,
        defaultSearchPostType,
        uploadconfig,
        requests,
        posttypesPlugins
    })

    storedConfig.curlang = curlang
    storedConfig.configroutes = routes
    storedConfig.searchRoute = searchRoute
    storedConfig.defaultSearchPostType = defaultSearchPostType
    storedConfig.dataPosttypes = posttypes
    storedConfig.dataTaxonomies = taxonomies
    storedConfig.storedSearchData = searchData
    storedConfig.maincolor = maincolor
    storedConfig.googleTrackingId = googleTrackingId
    storedConfig.meta = {
        siteurl,
        sitename,
        siteimage
    }

    lightBaseTheme.palette.primary1Color = '#3f454a'
    lightBaseTheme.palette.primary2Color = '#3f454a'
    lightBaseTheme.palette.accent1Color = maincolor
    lightBaseTheme.palette.pickerHeaderColor = maincolor

    const updatefunctions = {}

    const input = {

        /*defaults*/

        sitename,
        siteurl,
        drawerwidth: 320,
        menus: menus,
        menuName: 'main',
        style: style,
        headerstyle: headerstyle,
        history: history,
        logostyle: logostyle,
        startlogostyle: startlogostyle,
        logotext: logotext,
        logospan: logospan,
        logohref: logohref,
        muiTheme: lightBaseTheme,

        /*advanced*/

        curlang: curlang,
        routes: routes,
        taxonomiesData: taxonomies,
        posttypesData: posttypes,

        store: store,
        userConstructHelpers: userConstructHelpers,
        getMenu: getMenu,
        getSearchBar: getSearchBar,
        getFeed: getFeed,
        menuData: menuData,
        pageData: pageData,
        searchBarData: searchBarData,
        feedDatas: feedDatas,
        refsObject: refsObject,
        menuRefs: menuRefs,
        searchBarRefs: searchBarRefs,
        feedRefs: feedRefs,
        pageRefs: pageRefs,
        setRef: setRef,
        onDrawerChange: onDrawerChange,

        searchBarOnFocusHandle: searchBarOnFocusHandle,
        searchBarOnCloseHandle: searchBarOnCloseHandle,
        searchData: searchData,
        searchRoute: searchRoute,
        defaultSearchPostType: defaultSearchPostType,
        postSearchObjectName: defaultPostSearchObjectName,
        postSlug: defaultPostSlug,

        uploadconfig: uploadconfig,
        getthumb: getthumb,
        getthumbstyle: getthumbstyle,
        defaultthumbnail,

        localstorename,
        termspostid,
        cookiepostid,
        privancyid,
        contactpostid,

        updatefunctions,
        maincolor,

        staticscript,
        staticserver

    }


    /* return templates */
    return {
        home:
            createReactClass({
                render() {
                    const { response, getFetch, helpers } = this.props
                    return (
                        <ContentHome
                            {...input}
                            response={response}
                            helpers={helpers}
                            getFetch={getFetch}
                            name='home'
                        />)
                }
            }),
        search:
            createReactClass({
                render() {
                    const { response, getFetch, helpers } = this.props
                    return (
                        <ContentSearch
                            {...input}
                            name='search'
                            response={response}
                            helpers={helpers}
                            getFetch={getFetch}
                        />
                    )
                }
            }),
        archive:
            createReactClass({
                render() {
                    const { response, getFetch, helpers } = this.props
                    return (
                        <ContentArchive
                            {...input}
                            name='archive'
                            response={response}
                            helpers={helpers}
                            getFetch={getFetch}
                        />
                    )
                }
            }),

        ...posttypesPlugins.templates({
            input: {
                ...input,
                editdata,
                ...modules
            }
        })
    }
}
