import React from 'react'
import createReactClass from 'create-react-class'

function getMetaData({ props = {} }) {

    const { state = {}, data = {} } = props
    let { title } = props

    const { content = {} } = data
    const { response = {}, query = {}, url} = state

    const requestid = query.id

    if (typeof title == 'function') title = title({ state })

    let {
        siteurl = '',
        sitename = '',
        siteimage = '',
        description = ''
    } = content

    let currentpost = null
    let siteimagewidth = 0
    let siteimageheight = 0

    if (requestid) {
        Object.keys(response).map(function(key) {
            if (response[key] && response[key][0] && response[key][0].id === requestid) {
                currentpost = response[key][0]
            }
        })
    }

    if (currentpost) {
        if (currentpost.title) title = currentpost.title + ' - ' + sitename //title;
        if (currentpost.content_extended) {
            description = currentpost.content_extended.slice(0, 150) + '...'
            if (description && description.replace) {
                description = description.replace(/<br>/gm, ' ')
                description = description.replace(/(\r\n|\n|\r)/gm, ' ')
                description = description.replace(/ {2}/gm, ' ')
                description = description.replace(/\s+/g, ' ')
            }
        }
        if (currentpost.cover && currentpost.cover !== '/thumb.jpg') {
            siteimage = currentpost.cover
            if (currentpost.coverrel && currentpost.coverrel.width && currentpost.coverrel.height) {
                siteimagewidth = currentpost.coverrel.width
                siteimageheight = currentpost.coverrel.height
            }
        }
    }

    const rsiteimage = (siteimage && !siteimage.match('http')) ? siteurl + siteimage : siteimage
    const rurl = (siteurl && url) ? siteurl + url : (url) ? url : siteurl

    return {
        currentpost,
        currenturl: rurl,
        title,
        description,
        siteimage: rsiteimage,
        sitename,
        siteurl,
        siteimagewidth,
        siteimageheight
    }

}

export default function({ wapplrcomponents = {}, storedConfig = {}}) {

    const { maincolor = '#ffffff', googleTrackingId } = storedConfig

    return {

        ...wapplrcomponents,

        heads: createReactClass({

            render: function() {

                const { styles = [], state = {} } = this.props



                const {
                    currenturl,
                    title,
                    description,
                    siteimage,
                    siteimagewidth,
                    siteimageheight,
                    sitename
                } = getMetaData({ props: this.props })

                const { isBot } = state

                // noinspection HtmlUnknownTarget,JSUnresolvedLibraryURL
                return (
                    <head>
                        <meta charSet='utf-8' />
                        <meta httpEquiv='x-ua-compatible' content='ie=edge' />
                        <title>{title}</title>
                        <meta name='description' content={description} />
                        <meta name='viewport' content='width=device-width, initial-scale=1' />

                        <meta property='og:locale' content='en_US' />
                        <meta property='og:type' content='website' />
                        <meta property='og:title' content={title} />
                        <meta property='og:description' content={description} />
                        <meta property='og:url' content={currenturl} />
                        <meta property='og:site_name' content={sitename} />
                        <meta property='og:image' content={siteimage} />

                        {(siteimagewidth && siteimageheight) ?
                            <meta property='og:image:width' content={siteimagewidth} /> : null}
                        {(siteimagewidth && siteimageheight) ?
                            <meta property='og:image:height' content={siteimageheight} /> : null}

                        <meta name='twitter:card' content='summary' />
                        <meta name='twitter:description' content={description} />
                        <meta name='twitter:title' content={title} />
                        <meta name='twitter:image' content={siteimage} />

                        <link rel='manifest' href='/manifest.json' />
                        <meta name='theme-color' content={maincolor} />
                        <meta name='mobile-web-app-capable' content='yes' />

                        <link rel='icon' type='image/png' href='/favicon.png?flatchooser' />

                        {!isBot && googleTrackingId &&
                            <script
                                dangerouslySetInnerHTML={{
                                    __html:
                                        'window.ga=function(){ga.q.push(arguments)};ga.q=[];ga.l=+new Date;' +
                                        `ga('create','${googleTrackingId}','auto');ga('send','pageview')`
                                }}
                            />
                        }
                        {!isBot && googleTrackingId &&
                            <script src='https://www.google-analytics.com/analytics.js' async defer />
                        }

                        {styles.map(style =>
                            <style
                                key={style.id}
                                id={style.id}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: style.cssText }}
                            />
                        )}
                    </head>
                )
            }
        })

    }
}
