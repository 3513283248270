import React from 'react'
import createReactClass from 'create-react-class'

import s from '../empty.css'

const AppbarComponent = createReactClass({
    getInitialState: function() {
        const { style, enablestickystyle, centerfull } = this.props

        return {
            centerFull: centerfull,
            className: (enablestickystyle) ? style.appbar + ' ' + style.appbarnosticky + ' ' + style.appbarComponentRoot : style.appbar + ' ' + style.appbarComponentRoot
        }
    },
    stickyOn: function() {
        const { style } = this.props
        this.setState({
            className: style.appbar + ' ' + style.appbarsticky + ' ' + style.appbarComponentRoot
        })
    },
    stickyOff: function() {
        const { style } = this.props
        this.setState({
            className: style.appbar + ' ' + style.appbarnosticky + ' ' + style.appbarComponentRoot
        })
    },
    render: function() {
        const { style = s, left, right, center, down, className } = this.props

        const Center = (center && React.isValidElement(center)) ? null : center
        const centerChildren = (Center) ? Center(this.props) : center

        return (
            <div>
                <div className={(className) ? this.state.className + ' ' + className : this.state.className}>
                    <div className={style.appbarComponentLeft}>{(left) ? left : null}</div>
                    <div
                        className={(this.state.centerFull) ? style.appbarComponentCenter + ' ' + style.appbarComponentCenterFull : style.appbarComponentCenter}>
                        {(centerChildren && centerChildren.length) ? [...centerChildren] : centerChildren}
                    </div>
                    <div className={style.appbarComponentRight}>{(right) ? right : null}</div>
                </div>
                {(down) ? <div className={style.appbarComponentDown}>{down}</div> : null}
            </div>
        )
    }
})

export default createReactClass({
    render: function() {
        const { setRef } = this.props
        return <AppbarComponent {...this.props} ref={function(e) {
            setRef('appbar', e)
        }} />
    }
})
