// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GlRmj {\n    display: block;\n    width: 250px;\n    height: 250px;\n    margin-left: auto;\n    margin-right: auto;\n    padding: 50px;\n    border-radius: 200px;\n    position: relative;\n    max-width: 100%;\n}\n\n.GlRmj svg {\n    width: 100% !important;\n    height: auto !important;\n    color: rgba(0, 0, 0, 0.1) !important;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: -10px;\n    display: block !important;\n}\n\n.jwW2O {\n    text-align: center;\n    vertical-align: middle;\n    letter-spacing: 0;\n    text-transform: uppercase;\n    font-weight: 500;\n    font-size: 14px;\n    color: rgba(0, 0, 0, 0.28) !important;\n    position: absolute;\n    padding: 0 !important;\n    margin-left: auto;\n    margin-right: auto;\n    left: 0;\n    right: 0;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notfounditems": "GlRmj",
	"notfounditemstext": "jwW2O"
};
module.exports = ___CSS_LOADER_EXPORT___;
