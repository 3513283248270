export default function() {
    const pages = {}

    function pageRefs(a, e) {
        pages[a] = e
    }

    let pageDataObjects = {}

    function getPageDataObject(a) {

        pageDataObjects[a] = {
            dataSource: {},
            dataCurrent: {
                drawerOpen: false,
                left: 0
            }
        }

        let pageData = {
            getDataSource: function() {
                return pageDataObjects[a].dataSource
            },
            getDataCurrent: function() {
                return pageDataObjects[a].dataCurrent
            },
            setDataCurrent: function(b, v) {
                pageDataObjects[a].dataCurrent[b] = v
            },
            setData: function(b) {
                pageDataObjects[a] = b
                return pageData
            }
        }
        return pageData
    }

    let drawerCloses = {}

    function onDrawerChange(open, closeFunction, pagename, drawername) {
        if (pages[pagename]) {
            if (open === 0) {
                if (!drawerCloses.currentDrawer) return pages[pagename].close()
                if (drawerCloses.currentDrawer === drawername) {
                    drawerCloses.currentDrawer = null
                    return pages[pagename].close()
                }
            } else {
                if (drawerCloses.currentDrawer &&
                    drawerCloses[drawerCloses.currentDrawer] &&
                    drawerCloses.currentDrawer !== drawername) {
                    drawerCloses[drawerCloses.currentDrawer]()
                }
                drawerCloses.currentDrawer = drawername
                drawerCloses[drawername] = closeFunction
                return pages[pagename].open(open)
            }
        }
        return null
    }

    return { pageRefs, getPageDataObject, onDrawerChange }
}


