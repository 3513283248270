import React from 'react'
import createReactClass from 'create-react-class'

import serialize from 'serialize-javascript'

const DefaultHeads = createReactClass({
    render: function() {
        const { title, description, styles = [] } = this.props
        return (
            <head>
                <meta charSet='utf-8' />
                <meta httpEquiv='x-ua-compatible' content='ie=edge' />
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta name='viewport' content='width=device-width, initial-scale=1' />
                {styles.map(style =>
                    <style
                        key={style.id}
                        id={style.id}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: style.cssText }}
                    />
                )}
            </head>
        )
    }
})

export default createReactClass({
    render: function() {
        const { scripts = [], state = null, children, Heads = DefaultHeads, containerElementId = 'app' } = this.props
        const isBot = state?.isBot

        return (
            <html className='no-js' lang='hu'>
            <Heads {...this.props} />
            <body>
            <div id={containerElementId} dangerouslySetInnerHTML={{ __html: children }} />
            {state && (
                <script
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html:
                            `window.APP_STATE=${serialize(state, { isJSON: true })}`
                    }}
                />
            )}
            {!isBot && scripts.map(script => <script key={script} src={script} />)}
            </body>
            </html>
        )
    }
})
