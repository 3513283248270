import gettemplates from './templates'
import getcomponents from './components'
import getroutes from './routes'
import getcontents from './contents'
import getstates from './states'
import requests from './requests'

export function getTemplates(c) {
    return gettemplates(c)
}

export function getComponents(c) {
    return getcomponents(c)
}

export function getRoutes(c) {
    return getroutes(c)
}

export function getContents(c) {
    return getcontents(c)
}

export function getStates() {
    return getstates()
}

export function getFirstReq() {
    return requests.first()
}
