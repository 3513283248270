import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'
import ReactDOM from 'react-dom'

import s from '../empty.css'

/*Components*/
import AppBar from './AppbarComponent'
import E from './internalElements'

const AddFixedDrawer = createReactClass({
    render: function() {

        const { state, type = 'drawer', swapdirection, fixeddrawer } = this.props
        const input = { ...this.props, menu: fixeddrawer, type: this.props.type || 'drawer' }

        if (!state.narrow && fixeddrawer) {
            if (!swapdirection && type === 'drawer') return E.addfixeddrawer1(input)
            if (!swapdirection && type === 'menu') return E.addfixeddrawer2(input)
            if (swapdirection && type === 'drawer') return E.addfixeddrawer3(input)
            if (swapdirection && type === 'menu') return E.addfixeddrawer4(input)
        }

        return null
    }
})

const AddSideMenuByType = createReactClass({
    render: function() {
        const { state, menu, type, swapdirection, logo, fixeddrawer } = this.props
        const input = { ...this.props }
        if (state.narrow && fixeddrawer) input.menu = [...menu, { divider: 1 }, ...fixeddrawer]

        if (swapdirection && logo && type === 'drawer' && state.narrow) return E.addsidebymenutype1(input)
        if (swapdirection && logo && type === 'menu' && state.narrow) return E.addsidebymenutype2(input)
        if (swapdirection && logo && type && fixeddrawer) return <AddFixedDrawer {...input} />
        if (swapdirection && logo && type && !fixeddrawer) return E.addsidebymenutype3(input)
        if (state.narrow && type === 'drawer') return E.addsidebymenutype4(input)
        if (state.narrow && type === 'menu') return E.addsidebymenutype5(input)
        if (!state.narrow && fixeddrawer) return <AddFixedDrawer  {...input} />

        return null

    }
})

const Addmenu = createReactClass({
    render: function() {

        const { state, type, logo } = this.props
        const input = { ...this.props }

        if (!state.narrow && type === 'drawer' && logo) return E.addmenu1(input)
        if (!state.narrow && type === 'menu' && logo) return E.addmenu1(input)
        if (!state.narrow && type === 'drawer' && !logo) return E.addmenu2(input)
        if (!state.narrow && type === 'menu' && !logo) return E.addmenu2(input)
        return E.addmenu3(input)

    }
})

const AppbarLeftHelper = createReactClass({
    render: function() {
        const { input } = this.props
        if (input.onesided && !input.swapdirection) return <div className={input.style.onesided}>
            <AddSideMenuByType {...input} /><Addmenu {...input} /></div>
        if (input.onesided && input.swapdirection) return E.addmenu3({ ...input, swapdirection: false })
        return (!input.swapdirection) ? <Addmenu {...input} /> : <AddSideMenuByType {...input} />
    }
})

const AppbarRightHelper = createReactClass({
    render: function() {
        const { input } = this.props
        if (input.onesided && !input.swapdirection) return null
        if (input.onesided && input.swapdirection) return <div className={input.style.onesided}><Addmenu {...input}
                                                                                                         logo={null} /><AddSideMenuByType {...input}
                                                                                                                                          logo={null} />
        </div>
        return (!input.swapdirection) ? <AddSideMenuByType {...input} /> : <Addmenu {...input} />
    }
})

const List = createReactClass({
    render: function() {
        const { list } = this.props
        if (list) {
            return E.addlist({ ...this.props, menu: list })
        }
        return null
    }
})

const Appbar = createReactClass({
    render: function() {

        const { style, setRef, list, state, children, enablestickystyle, className } = this.props
        const input = this.props

        return (
            <AppBar
                setRef={setRef}
                style={style}
                enablestickystyle={enablestickystyle}
                centerfull={!!(state.centerfull)}
                left={<AppbarLeftHelper input={input} />}
                right={<AppbarRightHelper input={input} />}
                center={children}
                down={(list) ? <List {...input} /> : null}
                state={state}
                className={className}
            />
        )
    }
})

export default createReactClass({
    getInitialState() {

        const { data, breakpoint = 0 } = this.props
        const drawerOpen = (data && data.getDataCurrent) ? data.getDataCurrent().drawerOpen : false
        const openmenus = (data && data.getDataCurrent) ? data.getDataCurrent().openmenus : null
        const centerfull = (data && data.getDataCurrent) ? data.getDataCurrent().centerfull : null
        const drawerScrollTop = (data && data.getDataCurrent) ? data.getDataCurrent().drawerScrollTop : 0

        return {
            drawerOpen: drawerOpen,
            breakpoint: breakpoint,
            narrow: false,
            hidelogo: 0,
            logobreakpoint: 0,
            width: 1920,
            isMounted: false,
            showNav: false,
            items: {},
            centerfull: centerfull,
            openmenus: openmenus,
            drawerScrollTop: drawerScrollTop
        }
    },
    normaliseOpenMenuObject: function({ openmenus }) {
        const r = {}
        if (openmenus) {
            Object.keys(openmenus).map(function(menuid) {
                if (openmenus[menuid]) r[menuid] = openmenus[menuid]
            })
        }
        return r
    },
    setOpenMenu: async function({ menu }) {
        const { openmenus = {} } = this.state
        const { data = {} } = this.props
        const newopenmenustate = this.normaliseOpenMenuObject({
            openmenus: {
                ...openmenus,
                [menu.id]: (menu.items) ? 1 : null
            }
        })
        let drawerContainer = (this.state.items['drawer']) ? this.state.items['drawer'].getDrawerContainer() : null
        let dY = (drawerContainer) ? drawerContainer.scrollTop : 0

        if (data.setDataCurrent) data.setDataCurrent('openmenus', newopenmenustate)
        if (data.setDataCurrent) data.setDataCurrent('drawerScrollTop', dY)

        await this.setState({
            openmenus: newopenmenustate
        })

        drawerContainer = (this.state.items['drawer']) ? this.state.items['drawer'].getDrawerContainer() : null
        if (drawerContainer) drawerContainer.scrollTop = dY

    },
    removeOpenMenu: async function({ menu }) {
        const { openmenus = {} } = this.state
        const { data = {} } = this.props
        const newopenmenustate = this.normaliseOpenMenuObject({
            openmenus: {
                ...openmenus,
                [menu.id]: null
            }
        })

        let drawerContainer = (this.state.items['drawer']) ? this.state.items['drawer'].getDrawerContainer() : null
        const dY = (drawerContainer) ? drawerContainer.scrollTop : 0

        if (data.setDataCurrent) data.setDataCurrent('openmenus', newopenmenustate)
        if (data.setDataCurrent) data.setDataCurrent('drawerScrollTop', dY)

        await this.setState({
            openmenus: newopenmenustate
        })

        drawerContainer = (this.state.items['drawer']) ? this.state.items['drawer'].getDrawerContainer() : null
        if (drawerContainer) drawerContainer.scrollTop = dY

    },
    lastmenuid: 0,
    getLastMenuId: function() {
        return this.lastmenuid
    },
    setLastMenuId: function(id) {
        this.lastmenuid = id
        return this.lastmenuid
    },
    setMenuIds: function({ menus = [] }) {
        const getLastMenuId = this.getLastMenuId
        const setLastMenuId = this.setLastMenuId
        const setMenuIds = this.setMenuIds

        menus.map(function(menu) {
            if (!menu.id) {
                const lastmenuid = getLastMenuId()
                menu.id = setLastMenuId(lastmenuid + 1)
            }
            if (menu.items) setMenuIds({ menus: menu.items })
        })
        return menus
    },
    centerFullOn: function() {
        if (this.state && !this.state.centerfull || !this.state) {
            this.props.data.setDataCurrent('centerfull', true)
            this.setState({
                centerfull: true
            })
        }
    },
    centerFullOff: function() {
        this.props.data.setDataCurrent('centerfull', false)
        this.setState({
            centerfull: false
        })
    },
    setRef: function(a, e) {
        if (!this.state) this.state = {}
        if (!this.state.items) this.state.items = {}
        this.state.items[a] = e
        if (a === 'drawer' && e) {
            this.addDrawerScrollTop()
            this.saveDrawerScrollTop()
        }
    },
    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    getDimensions: function(initial) {
        const appbar = this.getElement(this.state && this.state.items && this.state.items.appbar)
        const nav = this.getElement(this.state && this.state.items && this.state.items.nav)
        const logo = this.getElement(this.state && this.state.items && this.state.items.logo)

        let width = ('undefined' == typeof window) ? 1920 : window.innerWidth
        if (appbar) width = appbar.offsetWidth
        let breakpoint = this.props.breakpoint || this.state && this.state.breakpoint || 0
        if (breakpoint === 0) {
            breakpoint = nav && nav.offsetWidth && nav.offsetWidth + 100 || 0
            if (this.props.swapdirection && logo && nav) breakpoint = nav.offsetWidth + logo.offsetWidth + 100 || 0
        }

        let hidelogo = this.state && this.state.hidelogo || 0
        let logobreakpoint = this.props.logobreakpoint || this.state && this.state.logobreakpoint || 0
        let logowidth = (logobreakpoint) ? logobreakpoint : (logo && this.props.logo) ? logo.offsetWidth + 100 : 0
        if (logowidth && width < breakpoint || logowidth && this.state.narrow) hidelogo = (logowidth < width) ? 0 : logowidth

        if (this.state && this.state.isMounted) {
            const r = {
                breakpoint: breakpoint,
                narrow: (width < breakpoint),
                hidelogo: hidelogo,
                logobreakpoint: this.state && this.state.logobreakpoint || hidelogo,
                width: width,
                isMounted: true,
                showNav: true
            }
            if (initial) {
                r.centerfull = (width >= 640)
                return r
            }
        }

        return { width: this.lastWidth }

    },
    lastWidth: 0,
    updateDimensions: function(initial) {
        const newStates = this.getDimensions(initial)
        if (!this.state) this.state = {}
        if (newStates.width !== this.lastWidth && this.state) {
            if (newStates.breakpoint !== this.state.breakpoint ||
                newStates.narrow !== this.state.narrow ||
                newStates.hidelogo !== this.state.hidelogo ||
                newStates.logobreakpoint !== this.state.logobreakpoint ||
                newStates.showNav !== this.state.showNav ||
                initial && newStates.centerfull !== this.state.centerfull ||
                newStates.isMounted !== this.state.isMounted) {
                this.lastWidth = newStates.width
                if (this.props.data) this.props.data.setDataCurrent('narrow', newStates.narrow)
                if (this.props.data && initial) this.props.data.setDataCurrent('centerfull', newStates.centerfull)
                if (this.props.data) this.props.data.setDataCurrent('hidelogo', newStates.hidelogo)
                if (this.props.data) this.props.data.setDataCurrent('showNav', newStates.showNav)
                if (this.props.data) this.props.data.setDataCurrent('breakpoint', newStates.breakpoint)
                if (this.props.data) this.props.data.setDataCurrent('logobreakpoint', newStates.logobreakpoint)
                if (this.state.isMounted !== null) this.setState(newStates)
            }
        }
    },
    resizeListener: function() {
        const appbar = this.getElement(this.state && this.state.items && this.state.items.appbar)
        if (appbar && appbar.offsetWidth !== this.appBarWidth) {
            this.appBarWidth = appbar.offsetWidth
            this.updateDimensions()
        }
    },
    resizeListenerCatchOn: function() {
        this.resizeListener()
        setTimeout(this.resizeListener, 500)
    },
    lastSticky: 0,
    stickyListener: function() {

        const { getStickyElement, enablestickystyle } = this.props

        let sticky = ('undefined' !== typeof window && window.scrollY && enablestickystyle) ? window.scrollY : 0
        if (getStickyElement && enablestickystyle) {
            const se = getStickyElement()
            sticky = (se && se.scrollTop) ? se.scrollTop : 0
        }

        const appbar = this.state.items.appbar

        if (sticky && !this.lastSticky && appbar) {
            appbar.stickyOn()
            this.lastSticky = 1
        }
        if (!sticky && this.lastSticky && appbar) {
            appbar.stickyOff()
            this.lastSticky = 0
        }

    },
    componentWillMount: function() {
    },
    /*addPreventScrollScript: function() {
        const {style} = this.props;
        const drawer = document.getElementsByClassName(style.drawer)[0];
        if (drawer && !drawer.onmousewheel){
            drawer.onmousewheel = function(event){
                const wD = event.wheelDelta || -event.detail || null;
                if (typeof wD == "number"){
                    this.scrollTop += ( wD < 0 ? 1 : -1 ) * 40;
                    event.preventDefault();
                }
            }
        }
    },*/
    saveDrawerScrollTop: function() {
        const { data } = this.props
        let drawer = (this.state && this.state.items['drawer']) ? this.state.items['drawer'].getDrawerContainer() : null
        if (drawer && !drawer.onmousewheel) {
            drawer.onscroll = function() {
                if (data && data.setDataCurrent) {
                    data.setDataCurrent('drawerScrollTop', drawer.scrollTop)
                }
            }
        }
    },
    addDrawerScrollTop: function() {
        const { data } = this.props
        let drawer = (this.state && this.state.items['drawer']) ? this.state.items['drawer'].getDrawerContainer() : null
        const drawerScrollTop = (data && data.getDataCurrent) ? data.getDataCurrent().drawerScrollTop : 0
        if (drawerScrollTop && drawer) {
            drawer.scrollTop = drawerScrollTop
        }
    },
    componentDidMount: function() {

        //setTimeout(this.addPreventScrollScript, 500)

        const { data, enablestickystyle, getStickyElement, forceDrawerOpen } = this.props
        const storedDrawerOpen = (data) ? data.getDataCurrent().storedDrawerOpen : null
        const drawerScrollTop = (data) ? data.getDataCurrent().drawerScrollTop : 0

        if (storedDrawerOpen || forceDrawerOpen && typeof window !== 'undefined' && window.innerWidth > 800) {

            data.setDataCurrent('storedDrawerOpen', null)
            data.setDataCurrent('drawerOpen', true)

            this.setState({
                drawerOpen: true,
                isMounted: true,
                drawerScrollTop: drawerScrollTop
            })

        } else {
            this.setState({
                isMounted: true,
                drawerScrollTop: drawerScrollTop
            })
        }

        window.addEventListener('resize', this.updateDimensions)
        if (enablestickystyle) {
            if (getStickyElement) {
                const t = this
                setTimeout(function() {
                    const se = getStickyElement()
                    if (se && se.parentElement) se.addEventListener('scroll', t.stickyListener, false)
                }, 10)
            } else {
                window.addEventListener('scroll', this.stickyListener)
            }
        }

        document.addEventListener('mouseup', this.resizeListenerCatchOn)

        const updateDimensions = this.updateDimensions
        setTimeout(function() {
            updateDimensions(true)
        })
        setTimeout(this.stickyListener)
    },
    componentWillUnmount: function() {
        const { enablestickystyle, getStickyElement } = this.props
        window.removeEventListener('resize', this.updateDimensions)
        if (enablestickystyle) {
            if (getStickyElement) {
                const se = getStickyElement()
                if (se) se.removeEventListener('scroll', this.stickyListener)
            } else {
                window.removeEventListener('scroll', this.stickyListener)
            }
        }
        document.removeEventListener('mouseup', this.resizeListenerCatchOn)
        this.state.isMounted = null
    },
    render: function() {

        const {
            style = s,
            parentroute = '',
            disableInitWithStyles,
            breakpoint,
            data,
            menu,
            fixeddrawer,
            list,
            popperRoot
        } = this.props
        if (breakpoint) this.state.breakpoint = breakpoint

        this.state.dataSource = data && data.getDataSource() || {}
        this.state.drawerOpen = (data) ? data.getDataCurrent().drawerOpen : this.state.drawerOpen
        this.state.narrow = (data) ? data.getDataCurrent().narrow : this.state.narrow
        this.state.hidelogo = (data) ? data.getDataCurrent().hidelogo : this.state.hidelogo
        this.state.showNav = (data) ? data.getDataCurrent().showNav : this.state.showNav
        this.state.breakpoint = (data) ? data.getDataCurrent().breakpoint : this.state.breakpoint
        this.state.logobreakpoint = (data) ? data.getDataCurrent().logobreakpoint : this.state.logobreakpoint
        this.state.centerfull = (data) ? data.getDataCurrent().centerfull : this.state.centerfull
        this.state.openmenus = (data) ? data.getDataCurrent().openmenus : this.state.openmenus
        this.state.drawerScrollTop = (data) ? data.getDataCurrent().drawerScrollTop : this.state.drawerScrollTop

        const setOpenMenu = this.setOpenMenu
        const removeOpenMenu = this.removeOpenMenu

        const input = {
            ...this.props,
            state: this.state,
            setRef: this.setRef,
            style: style,
            parentroute: parentroute,
            setOpenMenu,
            removeOpenMenu,
            popperRoot
        }
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Appbar) : Appbar

        const rmenu = this.setMenuIds({ menus: menu })
        const rfixeddrawer = (fixeddrawer) ? this.setMenuIds({ menus: fixeddrawer }) : null
        const rlist = (list) ? this.setMenuIds({ menus: list }) : null

        return (
            <R {...input} menu={rmenu} fixeddrawer={rfixeddrawer} list={rlist} />
        )
    }
})
