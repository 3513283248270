import React from 'react'
import ReactDOM from 'react-dom'
import MobileDetect from 'mobile-detect'

function isLeftClickEvent(event) {
    return event.button === 0
}

function isModifiedEvent(event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export default class LinkComponent extends React.Component {
    rightListIcon = {}

    linkElementRef = (a) => {
        if (!this.state) this.state = {}
        this.state.Menuitem = a
    }

    onClickHandle = () => {
        const E = ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(this.state.Menuitem)
        if (E) E.removeAttribute('href')
    }

    handleButtonIsJustADropAndDown = (event) => {
        if ((!this.props.to && this.props.Menuitem && this.props.Menuitem.muiName === 'FlatButton') || (!this.props.to && this.props.menuItems)) {
            const submenu = this.props.storediconmenu && this.props.storediconmenu.element

            if (submenu && submenu.state && !this.isUnMounted) {
                if (!submenu.state.open) {
                    const E = ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(this.state.Menuitem)
                    if (E) E.removeAttribute('href')
                    submenu.open(null, event)
                    submenu.state.open = true
                } else {
                    submenu.close()
                    submenu.state.open = false
                }
            }

            return 'prevent'
        }
    }

    handleDropAndDownButtonWasClicked = () => {
        if (this.props.storediconmenu && this.props.storediconmenu.element || this.rightListIcon.element) {
            const submenu = this.props.storediconmenu && this.props.storediconmenu.element || this.rightListIcon.element
            if (submenu.state && submenu.state.preventDefault) {
                if ((this.props.Menuitem && this.props.Menuitem.muiName === 'FlatButton') ||
                    (this.props.Menuitem && this.props.Menuitem.muiName === 'MenuItem')) {
                    submenu.state.preventDefault = null
                    const E = ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(this.state.Menuitem)
                    if (E) E.removeAttribute('href')
                    return true
                } else {
                    submenu.state.preventDefault = null
                    return 'prevent'
                }
            }
        }
    }

    handleIsPushing = () => {
        if ((this.props.to && this.props.history && this.props.history.push) ||
            (this.props.hash && this.props.history && this.props.history.push) ||
            (this.props.search && this.props.history && this.props.history.push)) {

            if (this.props.Menuitem && this.props.Menuitem.muiName === 'ListItem' &&
                this.props.state && this.props.state.items && this.props.state.items.drawer && window && window.innerWidth < 800) {
                this.props.state.items.drawer.handleToggle()
                setTimeout(this.historyPush, 500)
            } else {
                if (this.isIOs()) {
                    setTimeout(this.historyPush, 500)
                } else {
                    this.historyPush()
                }
            }

            return 'prevent'
        }
    }

    normalizeHistoryPushObject = (o) => {

        let to = o.pathname
        let hash = o.hash
        let search = o.search

        if (!search && to && to.match(/\?/) && !to.match(/#/)) {
            search = to.split('?')[1]
            to = to.split('?')[0]
        }

        if (!hash && to && !to.match(/\?/) && to.match(/#/)) {
            hash = to.split('#')[1]
            to = to.split('#')[0]
        }

        if (!search && !hash && to && to.match(/#/) && to.match(/\?/)) {
            search = to.split('?')[1]
            to = to.split('?')[0]
            hash = search.split('#')[1]
            search = search.split('#')[0]
        }

        o.pathname = to
        o.hash = hash
        o.search = search

        return o

    }

    historyPush = () => {

        const history = this.props.history
        const to = this.props.to
        const hash = this.props.hash
        const search = this.props.search

        const historyHelpers = (this.props.searchData) ? this.props.searchData.getHistoryHelpers() : null
        if (historyHelpers && hash || historyHelpers && to) historyHelpers.resetToDefaults()

        if (to || hash || hash === '' || search || search === '') {

            const pushHistoryObject = {}

            if (to) pushHistoryObject.pathname = to
            if (hash || hash === '') {
                pushHistoryObject.hash = hash
            }
            if (search || search === '') {
                pushHistoryObject.search = search
            }

            this.normalizeHistoryPushObject(pushHistoryObject)

            history.push(pushHistoryObject)
        }

    }

    handleClick = (event) => {

        if (this.props.onClick) {
            const fc = this.props.onClick(event)
            if (fc) return fc
        }
        if (this.props.onTouchTap) {
            const ft = this.props.onTouchTap(event)
            if (ft) return ft
        }
        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
        }

        if (event.defaultPrevented === true) {
            return false
        }

        const Menuitem = this.state.Menuitem
        if (Menuitem && this.props.Menuitem && this.props.Menuitem.muiName === 'ListItem') {
            this.onNestedListToggle()
        }

        const ddwc = this.handleDropAndDownButtonWasClicked()
        if (ddwc) {
            if (ddwc === 'prevent') {
                event.preventDefault()
            }
            return false
        }

        const hip = this.handleIsPushing()
        if (hip) {
            if (hip === 'prevent') {
                event.preventDefault()
            }
            return false
        }

        const bjdd = this.handleButtonIsJustADropAndDown(event)
        if (bjdd) {
            if (bjdd === 'prevent') {
                event.preventDefault()
            }
            return false
        }

    }

    handleListRightIcon = (event) => {
        if ((event && event.type === 'mouseup') || (event && event.type === 'touchend')) {
            this.rightListIcon = { element: { state: { preventDefault: event } } }
        } else {
            this.rightListIcon = {}
        }
    }

    handleMenuRightIconLeave = () => {
        const submenu = this.props.storediconmenu && this.props.storediconmenu.element
        if (submenu && submenu.state && submenu.state.preventDefault) submenu.state.preventDefault = null
    }

    isIOs = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }

    isMobile = () => {
        const md = new MobileDetect(window.navigator.userAgent)
        return md.mobile() || md.tablet() || md.phone()
    }

    isTouchable = () => {
        return 'ontouchstart' in window || 'undefined' !== typeof navigator && navigator.maxTouchPoints
    }

    addEventListener = (e, t, f, a) => {
        const isTouchable = this.isTouchable()
        const isMobile = this.isMobile()
        const touchevents = (isMobile && isTouchable)
        let type = t
        if (touchevents) {
            if (type === 'mouseup') {
                type = 'touchend'
            }
        }
        e.addEventListener(type, f, a)
    }

    addListeners = () => {
        const { to } = this.props

        if (to && to.match('http')) {
        } else {
            const E = ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(this.state.Menuitem)
            const Menuitem = this.state.Menuitem

            if (Menuitem && this.props.Menuitem && this.props.Menuitem.muiName === 'FlatButton') {
                if (E) this.addEventListener(E, 'mouseup', this.handleClick, false)
            }
            if (Menuitem && this.props.Menuitem && this.props.Menuitem.muiName === 'ListItem' && this.props.to) {

                const handleListRightIcon = this.handleListRightIcon

                if (this.isIOs() && E && E.getElementsByTagName('button')[0]) {
                    this.addEventListener(E.getElementsByTagName('button')[0], 'mouseup', this.handleListRightIcon, false)
                } else {
                    Menuitem.handleRightIconButtonMouseUp = function(event) {
                        handleListRightIcon(event)
                    }
                }

                Menuitem.handleRightIconButtonMouseLeave = function() {
                    handleListRightIcon(null)
                }

            }
            if (Menuitem && this.props.Menuitem && this.props.Menuitem.muiName === 'MenuItem' && this.props.to) {
                const submenu = this.props.storediconmenu && this.props.storediconmenu.element
                if (submenu) submenu.handleMouseLeave = this.handleMenuRightIconLeave
            }
        }
    }

    componentDidMount = () => {
        this.addListeners()
    }

    componentWillUnmount = () => {
        this.isUnMounted = true
        if (this.state && this.state.Menuitem && this.props.Menuitem && this.props.Menuitem.muiName === 'FlatButton') {
            const E = ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(this.state.Menuitem)
            if (E) E.removeEventListener('mouseup', this.handleClick, false)
            if (E) E.removeEventListener('touchend', this.handleClick, false)
        }
    }

    onNestedListToggle = () => {
        const { setOpenMenu, removeOpenMenu, menudata = {} } = this.props
        const { Menuitem } = this.state

        if (menudata && menudata.items) {
            if (!Menuitem.state.open) {
                setOpenMenu({ menu: menudata })
            } else {
                removeOpenMenu({ menu: menudata })
            }
        }
    }

    render() {
        const { Menuitem, Lefticon, nestedItems = [], nestedLevel = 0, state = {}, menudata = {} } = this.props
        const { openmenus = {} } = state
        const open = !!(Menuitem && Menuitem.muiName === 'ListItem' && menudata && menudata.id && openmenus && openmenus[menudata.id])

        const input = {
            ...this.props,
            handleClick: this.handleClick,
            linkElementRef: this.linkElementRef,
            onClickHandle: this.onClickHandle,
            nestedItems: nestedItems,
            nestedLevel: nestedLevel,
            open: open
        }

        if (Lefticon && Menuitem && Menuitem.muiName === 'MenuItem') {
            return E.linkelement1(input)
        }
        if (Lefticon && Menuitem && Menuitem.muiName === 'FlatButton') {
            return E.linkelement2(input)
        }
        if (Menuitem && Menuitem.muiName === 'ListItem') {
            return E.linkelement3(input)
        }
        if (Menuitem && Menuitem.muiName === 'FlatButton') {
            return E.linkelement4(input)
        }
        return E.linkelement5(input)
    }
}

const E = {
    linkelement1: function(props) {
        const { Menuitem, to, handleClick, linkElementRef, className, Lefticon, children } = props
        const blank = !!(to && to.match('http'))

        return (
            <Menuitem href={(to) ? to : ''} onClick={(!blank) ? handleClick : function() {
            }} target={(blank) ? '_blank' : '_self'} ref={linkElementRef} className={className} leftIcon={Lefticon}>
                {children}
            </Menuitem>
        )
    },
    linkelement2: function(props) {
        const { Menuitem, to, linkElementRef, className, Lefticon, children, style, onClickHandle } = props
        const blank = !!(to && to.match('http'))

        return (
            <Menuitem href={(to) ? to : '#'} onClick={(!blank) ? onClickHandle : function() {
            }} target={(blank) ? '_blank' : '_self'} ref={linkElementRef} className={className + ' ' + style.iconlink}
                      icon={Lefticon} label={children} />
        )
    },
    linkelement3: function(props) {
        const {
            Menuitem,
            to,
            handleClick,
            linkElementRef,
            className,
            Lefticon,
            children,
            style,
            nestedItems,
            nestedListStyle,
            nestedLevel,
            open
        } = props
        const blank = !!(to && to.match('http'))

        return (
            <Menuitem href={to} onClick={(!blank) ? handleClick : function() {
            }} target={(blank) ? '_blank' : '_self'} ref={linkElementRef} className={className + ' ' + style.listitem}
                      leftIcon={Lefticon}
                      primaryText={children} primaryTogglesNestedList={!!(nestedItems)} nestedItems={nestedItems}
                      nestedListStyle={nestedListStyle} nestedLevel={nestedLevel} open={open} />
        )
    },
    linkelement4: function(props) {
        const { Menuitem, to, linkElementRef, className, onClickHandle, children } = props
        const blank = !!(to && to.match('http'))

        return (
            <Menuitem href={(to) ? to : '#'} onClick={(!blank) ? onClickHandle : function() {
            }} target={(blank) ? '_blank' : '_self'} ref={linkElementRef} className={className}>{children}</Menuitem>
        )
    },
    linkelement5: function(props) {
        const { Menuitem, to, handleClick, linkElementRef, className, children } = props
        const blank = !!(to && to.match('http'))

        return (
            <Menuitem href={(to) ? to : '#'} onClick={(!blank) ? handleClick : function() {
            }} target={(blank) ? '_blank' : '_self'} ref={linkElementRef} className={className}>{children}</Menuitem>
        )
    }
}
