import { minpoststatus as userminpoststatus, setrequired as usersetrequired } from './user/setrequired'

import { minpoststatus as imageminpoststatus, setrequired as imagesetrequired } from './image/setrequired'

import { minpoststatus as postminpoststatus, setrequired as postsetrequired } from './post/setrequired'

import postConfig from './post/config'

import { minpoststatus as playerminpoststatus, setrequired as playersetrequired } from './player/setrequired'

import playerConfig from './player/config'

import {
    minpoststatus as notificationminpoststatus,
    setrequired as notificationsetrequired
} from './notification/setrequired'

const postName = postConfig.name

const playerName = playerConfig.name

function allsetrequired(p = {}) {
    let r = p.requireddata
    const { posttype } = p
    if (posttype === 'User') {
        r = usersetrequired(p)
    }
    if (posttype === 'Image') {
        r = imagesetrequired(p)
    }
    if (posttype === postName) {
        r = postsetrequired(p)
    }
    if (posttype === playerName) {
        r = playersetrequired(p)
    }
    if (posttype === 'Notification') {
        r = notificationsetrequired(p)
    }
    return r
}

function allminpoststatus(p = {}) {
    let r = p.requireddata
    const { posttype } = p
    if (posttype === 'User') {
        r = userminpoststatus(p)
    }
    if (posttype === 'Image') {
        r = imageminpoststatus(p)
    }
    if (posttype === postName) {
        r = postminpoststatus(p)
    }
    if (posttype === playerName) {
        r = playerminpoststatus(p)
    }
    if (posttype === 'Notification') {
        r = notificationminpoststatus(p)
    }
    return r
}

export default {
    setrequired: allsetrequired,
    minpoststatus: allminpoststatus
}
