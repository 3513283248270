import 'whatwg-fetch'

export default function(self) {
    return {
        default: self.fetch.bind(self),
        Headers: self.Headers,
        Request: self.Request,
        Response: self.Response
    }
}
