import React from 'react'
import createReactClass from 'create-react-class'

import Formsy from 'formsy-react'
import Wapplrgallery from './index'

export default createReactClass({
    mixins: [Formsy.Mixin],
    componentDidMount: function() {
        const { value } = this.props
        this.setValue(value)
    },
    changeValue(event, data) {
        const { onChange } = this.props
        this.setValue(data)
        if (onChange) onChange(event, data)
    },
    isChanged: function(a, b) {
        let changed = false
        const isChanged = this.isChanged
        if (a && b && typeof a == 'object' && typeof b == 'object') {
            const keys = (a.length === undefined) ? Object.keys(a) : [...a.keys()]
            keys.map(function(key) {
                if (typeof a[key] !== typeof b[key]) changed = true
                if (!changed) {
                    if (a[key] && typeof a[key] == 'object') {
                        changed = isChanged(a[key], b[key])
                    } else {
                        if (a[key] !== b[key]) changed = true
                    }
                }
            })
        } else {
            if (typeof a !== typeof b) changed = true
            if (a !== b) changed = true
        }
        return changed
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)
        return changed1 || changed2

    },
    render() {

        const required = this.showRequired()
        const error = this.showError()
        const errorMessage = this.getErrorMessage()

        return (
            <Wapplrgallery {...this.props}
                           formsy={true}
                           posts={this.getValue()}
                           onChange={this.changeValue}
                           error={error}
                           errorMessage={errorMessage}
                           required={required}
            />
        )
    }
})
