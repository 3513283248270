import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import s from '../empty.css'

import Wapplrfeed from '../Wapplrfeed'
import Pagination from '../Wapplrfeed/Pagination'

import NoMatches from './NoMatches'
import nms from './NoMatches/NoMatches.css'

import Subheader from 'material-ui/Subheader'

import Cropper from 'react-easy-crop'
import Slider from 'material-ui/Slider'

const Editor = createReactClass({
    getInitialState: function() {
        return {
            ...this.getData(this.props)
        }
    },
    isChanged: function(a, b) {
        let changed = false
        const isChanged = this.isChanged
        if (a && b && typeof a == 'object' && typeof b == 'object') {
            const keys = (a.length === undefined) ? Object.keys(a) : [...a.keys()]
            keys.map(function(key) {
                if (typeof a[key] !== typeof b[key]) changed = true
                if (!changed) {
                    if (a[key] && typeof a[key] == 'object') {
                        changed = isChanged(a[key], b[key])
                    } else {
                        if (a[key] !== b[key]) changed = true
                    }
                }
            })
        } else {
            if (typeof a !== typeof b) changed = true
            if (a !== b) changed = true
        }
        return changed
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)
        return changed1 || changed2

    },
    getData: function(props) {
        let { image, value, maskHeightPercent = 56 } = props
        if (typeof image == 'function') image = image()
        if (typeof value == 'function') value = value()
        if (!value) value = { x: 0, y: 0, zoom: 1, rotate: 0, croppedAreaPixels: null, media: null }
        return {
            image: image,
            crop: {
                x: (typeof value.x !== 'undefined') ? value.x : 0,
                y: (typeof value.y !== 'undefined') ? value.y : 0
            },
            zoom: (typeof value.zoom !== 'undefined') ? value.zoom : 1,
            aspect: 100 / maskHeightPercent,
            rotate: (typeof value.rotate !== 'undefined') ? value.rotate : 0,
            media: (value.media) ? value.media : null,
            initialCroppedAreaPixels: (value.croppedAreaPixels) ? value.croppedAreaPixels : null
        }
    },
    onMediaLoaded: function(media) {
        if (JSON.stringify(media) !== JSON.stringify(this.state.media) || !this.state.mediaLoaded) {
            this.setState({
                mediaLoaded: true,
                media
            })
        }
    },
    onCropChange: function(crop) {
        if (crop.x !== this.state.crop.x || crop.y !== this.state.crop.y) {
            this.setState({ crop })
        }
    },
    onCropComplete: async function(croppedArea, croppedAreaPixels) {
        const { crop, zoom, rotate, media } = this.state
        const { onChange } = this.props
        if (onChange) {
            onChange({
                value: {
                    x: Math.round(crop.x),
                    y: Math.round(crop.y),
                    zoom: zoom,
                    rotate: rotate,
                    croppedAreaPixels,
                    media
                }
            })
        }
    },
    onZoomChange: function(zoom) {
        if (this.state.zoom !== zoom && this.state.mediaLoaded) {
            this.setState({ zoom })
        }
    },
    onRotateChange: function(e, rotate) {
        if (this.state.rotate !== rotate) {
            this.setState({ rotate })
        }
    },
    render: function() {
        const {
            style,
            showGrid = true,
            enableRotate = true,
            enableZoomSlider = true,
            scaleText = 'Scale',
            rotateText = 'Rotate'
        } = this.props
        const onZoomChange = this.onZoomChange

        return (
            <div className={style.editor}>
                <div className={style.cropper}>
                    <Cropper
                        image={this.state.image}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        rotation={this.state.rotate}
                        initialCroppedAreaPixels={this.state.initialCroppedAreaPixels}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                        onMediaLoaded={this.onMediaLoaded}
                        showGrid={showGrid}
                    />
                </div>
                {(enableRotate || enableZoomSlider) ?
                    <div className={style.sliders}>
                        {(enableRotate) ?
                            <div className={style.slider}>
                                <div className={style.sliderTitle}>{rotateText}</div>
                                <div className={style.sliderElement}>
                                    <Slider
                                        defaultValue={this.state.rotate}
                                        onChange={this.onRotateChange}
                                        min={0}
                                        max={360}
                                    />
                                </div>
                            </div>
                            : null
                        }
                        {(enableZoomSlider) ?
                            <div className={style.slider}>
                                <div className={style.sliderTitle}>{scaleText}</div>
                                <div className={style.sliderElement}>
                                    <Slider
                                        defaultValue={this.state.zoom}
                                        onChange={function(e, zoom) {
                                            onZoomChange(zoom)
                                        }}
                                        min={1}
                                        max={3}
                                    />
                                </div>
                            </div>
                            : null
                        }
                    </div> : null}

            </div>
        )
    }
})

const Gallery = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { posts, postsdata, posttype, posttypepath, posttypename, isMounted = false } = this.getData()
        return {
            isMounted: isMounted,
            posts: posts || null,
            postsdata: postsdata || null,
            posttype: posttype || '',
            posttypepath: posttypepath || '',
            posttypename: posttypename || '',
            modalopen: false
        }
    },
    componentDidMount: function() {
        if (!this.state.isMounted) {
            this.setState({
                isMounted: true
            })
        }
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    getScrollDiv: function() {
        const { scrollDiv, getParent } = this.props
        return (scrollDiv) ? scrollDiv : (getParent) ? getParent() : null
    },
    paginationOnClick: async function(e) {

        const scrollDiv = this.getScrollDiv()

        if (e) e.preventDefault()
        if (this.state.isMounted) {
            const { globalstate, getFetch } = this.props
            const state = globalstate
            state.query.page = (state.query.page) ? Number(state.query.page) + 1 : 2
            const response = await getFetch({ state })
            const wy = (scrollDiv && scrollDiv.scrollTop) ? scrollDiv.scrollTop : 0
            this.addToPosts({ response: response })
            if (wy) scrollDiv.scrollTop = wy
        }

    },
    setPosts: function({ posts, modalclose }) {
        if (this.state.isMounted) {
            if (modalclose) {
                this.setState({
                    posts: posts,
                    modalopen: false
                })
            } else {
                this.setState({
                    posts: posts
                })
            }
        }
    },
    addToPosts: function({ response }) {
        if (this.state.isMounted) {
            const posts = (this.state.posts && this.state.posts.length) ? [...this.state.posts] : []
            const postsdata = (this.state.postsdata) ? { ...this.state.postsdata } : {}

            const newposts = (response && response.archive && response.archive.posts) ? response.archive.posts : null
            const newpostsdata = (response && response.archive && response.archive.data) ? response.archive.data : {}

            if (newposts && newposts.length) {
                posts.push(...newposts)
                this.setState({
                    posts: posts,
                    postsdata: { ...newpostsdata, skip: postsdata.skip, skippedpages: postsdata.skippedpages }
                })
            }
        }
    },
    setRef: function(a, e) {
        if (a && e) {
            const { setRef } = this.props
            this.refElements[a] = e
            if (setRef) setRef(a, e)
        }
    },
    getData: function() {
        const { posts, selectedposts, postsdata, posttype, posttypepath, posttypename, isMounted } = this.props
        return { posts, selectedposts, postsdata, posttype, posttypepath, posttypename, isMounted }
    },
    onChange: function(e, posts) {
        const { onChange } = this.props
        this.setState({
            posts: posts
        })
        if (onChange) onChange(e, posts)
    },
    deleteicon: function({ post, style }) {
        const { deleteicon } = this.props
        const props = { ...this.props }
        props.onChange = this.onChange
        if (deleteicon) {
            return deleteicon({ post, style, galleryprops: props })
        } else {
            return null
        }
    },

    plusbutton: function() {
        const { plusbutton } = this.props
        const modalopen = this.modalopen
        const modalclose = this.modalclose
        const refElements = this.refElements
        if (refElements && !refElements['wapplrgallery']) {
            refElements['wapplrgallery'] = this
        }
        if (plusbutton) return plusbutton({ ...this.props, modalopen, modalclose, refElements })
        return null
    },
    modalclose: function() {
        this.setState({
            modalopen: false
        })
    },
    modalopen: function() {
        this.setState({
            modalopen: true
        })
    },
    modal: function() {
        const { modal, style } = this.props
        const modalclose = this.modalclose
        const state = this.state
        const setRef = this.setRef
        const setPosts = this.setPosts
        const refElements = this.refElements
        const props = { ...this.props }

        if (modal) return modal({ state, modalclose, style, setRef, refElements, setPosts, galleryprops: props })
        return null
    },
    getDataCurrent: function() {
        if (!this.currentData) {
            const { selectedposts = [] } = this.getData()
            const i = {}
            selectedposts.map(function(p) {
                i[p.id] = p
            })
            this.currentData = {
                images: i
            }
        }
        return this.currentData
    },
    dataCurrentToPostsArray: function() {
        const a = []
        if (this.currentData && this.currentData.images) {
            const posts = this.state.posts || []
            const { selectedposts = [] } = this.getData()
            const images = this.currentData.images
            Object.keys(images).map(function(id) {
                if (images[id]) {
                    let founded = false
                    posts.map(function(p) {
                        if (p.id === id) {
                            a.push(p)
                            founded = true
                        }
                    })
                    if (!founded) {
                        selectedposts.map(function(p) {
                            if (p.id === id) {
                                a.push(p)
                                founded = true
                            }
                        })
                    }
                }
            })
        }
        return a
    },
    setDataCurrent: function(b, v) {
        const { selectsimple } = this.props
        if (selectsimple) {
            let last = null
            if (v) {
                Object.keys(v).map(function(id) {
                    if (v[id]) last = v[id]
                })
            }
            this.currentData[b] = (last) ? { [last.id]: last } : {}
        } else {
            this.currentData[b] = v
        }
    },
    getSearchData: function() {
        const { searchData } = this.props
        const getDataCurrent = this.getDataCurrent
        const setDataCurrent = this.setDataCurrent
        return {
            ...searchData,
            getDataCurrent: getDataCurrent,
            setDataCurrent: setDataCurrent
        }
    },
    render: function() {

        const {
            style,
            history,
            feedRefs,
            feedDatas,
            postSlug,
            curlang = {},
            historyHelpers,
            restoreicon,
            editicon,
            duplicateicon,
            publicicon,
            title,
            type = 'list',
            disablehref = false,
            selectable = false,
            selectsimple = false,
            formsy,
            getthumb,
            onClick,
            feedprops = {},
            className,
            modalClassName,
            editor
        } = this.props

        const {
            notfoundtextgallery,
            loadmoredatatext
        } = curlang

        const state = this.state
        const setRef = this.setRef
        const paginationOnClick = this.paginationOnClick
        const deleteicon = this.deleteicon

        const searchData = this.getSearchData()
        const searchObjectName = 'images'

        const plusbutton = this.plusbutton()
        const modal = this.modal()
        const scrollDiv = this.getScrollDiv()

        let cn = (formsy) ? style.formsy + ' ' + style.subpagebg : style.subpagebg
        if (className) cn = cn + ' ' + className

        let mCn = style.gallerymodal
        if (modalClassName) mCn = mCn + ' ' + modalClassName

        //console.log(editor);

        return (
            <div className={cn}>
                {(title) ?
                    <div className={style.subheadercontainer}>
                        <Subheader className={style.subheader}>
                            {title}
                        </Subheader>
                    </div> : null
                }
                {(plusbutton) ? <div className={style.galleryheaderbutton}>{plusbutton}</div> : null}
                <div className={style['page-padding']}>
                    {(state.posts && state.posts.length) ?
                        <div>
                            {(editor) ?
                                <Editor {...editor} post={state.posts[0]} style={style} />
                                :
                                <Wapplrfeed
                                    type={type}
                                    ref={(feedRefs) ? function(e) {
                                        feedRefs('archive_posts' + state.posttypepath, e)
                                    } : null}
                                    feedData={(feedDatas) ? feedDatas['archive_posts' + state.posttypepath] : null}
                                    history={history}
                                    style={style}
                                    data={state.posts}
                                    enableLocation={!(disablehref)}
                                    enableTitleHref={!(disablehref)}
                                    enableThumbHref={!(disablehref)}
                                    enablePermalink={!(disablehref)}
                                    to={(state.posttype) ? '/' + state.posttype.toLowerCase() : postSlug}
                                    selectable={selectable}
                                    selectsimple={selectsimple}
                                    searchData={searchData}
                                    searchObjectName={searchObjectName}
                                    deleteicon={deleteicon}
                                    restoreicon={restoreicon}
                                    editicon={editicon}
                                    duplicateicon={duplicateicon}
                                    publicicon={publicicon}
                                    disableInitWithStyles={true}
                                    getthumb={getthumb}
                                    onClick={onClick}
                                    {...feedprops}
                                />
                            }
                            {(state.isMounted && state.postsdata && state.postsdata.nextpages) ?
                                <Pagination
                                    onClick={paginationOnClick}
                                    ref={function(e) {
                                        setRef('pagination', e)
                                    }}
                                    infiniteScroll={true}
                                    style={style}
                                    className={style['page-subheader']}
                                    history={history}
                                    historyHelpers={historyHelpers}
                                    data={state.postsdata}
                                    disableInitWithStyles={true}
                                    scrollDiv={scrollDiv}
                                    loadMoreDataText={loadmoredatatext}
                                /> : null
                            }
                        </div>
                        :
                        <div>
                            <NoMatches style={nms} notFoundText={notfoundtextgallery} />
                        </div>
                    }
                </div>
                {(modal) ? <div className={mCn}>{modal}</div> : null}
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Gallery {...this.props} ref={(setRef) ? function(e) {
                setRef('wapplrgallery', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
