import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import s from '../empty.css'
import FileUpload from 'material-ui/svg-icons/file/file-upload'
import CircularProgress from 'material-ui/CircularProgress'

const Uploadbutton = createReactClass({
    getInitialState: function() {
        return {
            process: false,
            files: null
        }
    },
    setProcess: function({ files }) {
        const f = (files) ? files : (this.state && this.state.files) ? this.state.files : null
        this.setState({
            files: f,
            process: true
        })
    },
    setFiles: function({ files }) {
        this.setState({
            files: files
        })
    },
    endProcess: function(p = {}) {
        const { reset } = p
        if (reset) {
            this.setState({
                files: null,
                process: false
            })
        } else {
            this.setState({
                process: false
            })
        }
    },
    render: function() {
        const { style = s, uploadText = 'Upload', inputprops } = this.props
        const state = this.state
        const mb = 1024 * 1024

        return (
            <div>
                <div className={style.uploaditems}>
                    {(!state.process) ? <FileUpload /> :
                        <CircularProgress className={style.circularprogress} thickness={2} />}
                    <div className={style.uploaditemstext}>
                        {uploadText}
                    </div>
                    <input {...inputprops} className={style.inputfield} type={'file'} />
                </div>
                {(state.files) ?
                    <div className={style.filescontainer}>
                        {state.files.map(function(file, i) {
                            const size = Math.floor((file.size / mb) * 1000) / 1000 + ' MB'
                            const processing = file.processing
                            const fileresponse = file.response
                            return (
                                <div className={style.file} key={i}>
                                    <span className={style.filename}>{file.name}</span>
                                    <span className={style.filesize}>{size}</span>
                                    {(processing) ? <span className={style.processing}>P</span> : null}
                                    {(fileresponse && fileresponse.success) ?
                                        <span className={style.success}>OK</span> : null}
                                    {(fileresponse && !fileresponse.success) ?
                                        <span className={style.error}>{fileresponse.message}</span> : null}
                                </div>
                            )
                        })}
                    </div>
                    : null
                }
            </div>

        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Uploadbutton {...this.props} ref={(setRef) ? function(e) {
                setRef('uploadbutton', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props

        this.style = style
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
