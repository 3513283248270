import React from 'react'
import createReactClass from 'create-react-class'
import FlatButton from 'material-ui/FlatButton'
import LinkComponent from './LinkComponent'
import MenuItem from 'material-ui/MenuItem'
import MenuComponent from './MenuComponent'
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right'
import Divider from 'material-ui/Divider'
import Subheader from 'material-ui/Subheader'
import MenuItems from './MenuItems'

const Parentitem = createReactClass({
    render: function() {
        const { children, className, style, Parent, state, parentprops = {} } = this.props

        if (state && state.isMounted || !state) {
            if (Parent) return (<Parent
                className={(state && state.showNav || !state || !style) ? className : className + ' ' + style.hidden}
                style={style} {...parentprops}>{children}</Parent>)
            return (<div
                className={(state && state.showNav || !state || !style) ? className : className + ' ' + style.hidden}>{children}</div>)
        }
        return null
    }
})

const NestedLevelFallback = createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
})

function getlefticon({ m, props, iconClassName }) {
    if (typeof m.lefticon == 'string' && m[m.lefticon]) {
        const R = m[m.lefticon]
        return <R {...props} iconClassName={iconClassName} />
    }
    return m.lefticon
}

function getmenuname({ m, props }) {
    if (typeof m.name == 'function') return m.name(props)
    return m.name
}

function getmenulink({ m, props }) {
    if (typeof m.link == 'function') return m.link(props)
    return m.link
}

function items(m, index, props, nestedLevel = 0) {

    const {
        style,
        history,
        parentroute,
        Menuitem,
        classname,
        state,
        searchData,
        sendprops,
        setOpenMenu,
        removeOpenMenu,
        popperRoot
    } = props

    const listitem = (Menuitem && Menuitem.muiName === 'ListItem')
    const menuitem = (Menuitem && Menuitem.muiName === 'MenuItem')

    let linkclassname = (classname) ? style[classname + '-link'] : style.link
    if (m.justicon && !menuitem && !listitem) linkclassname = (classname) ? style.justiconlink + ' ' + style[classname + '-link'] : style.justiconlink + ' ' + style.link
    if (m.justicon && m.disablemoreicon && !menuitem && !listitem || m.justicon && !menuitem && !listitem && !m.items) linkclassname = (classname) ? style.justiconlinkwithoutmoreicon + ' ' + style[classname + '-link'] : style.justiconlinkwithoutmoreicon + ' ' + style.link
    if (m.classname) {
        linkclassname = (style[m.classname]) ? linkclassname + ' ' + style[m.classname] : linkclassname + ' ' + m.classname
    }

    const NLF = m.NestedLevelFallback || NestedLevelFallback

    if (m.divider) return <NLF key={index}><Divider inset={m.inset} className={style.divider} /></NLF>
    if (m.subheader) return <NLF key={index}><Subheader inset={m.inset}
                                                        className={style.subheader}>{m.subheader}</Subheader></NLF>
    if (m.html) return <NLF key={index}>{m.html}</NLF>
    if (m.component) {
        const C = m.component
        if (m.props) return <NLF key={index}><C state={state} {...m.props} {...sendprops} /></NLF>
        return <NLF key={index}><C state={state} {...sendprops} /></NLF>
    }

    const input = {
        linkclassname,
        state,
        index,
        m,
        parentroute,
        history,
        Menuitem,
        style,
        searchData,
        sendprops,
        setOpenMenu,
        removeOpenMenu,
        popperRoot
    }

    if (m.items && m.items.length) {
        const storediconmenu = {}
        input.storediconmenu = storediconmenu
        input.addsubopenref = function(a, e) {
            if (a === 'iconmenu') storediconmenu.element = e
        }
        input.menurighticon = (menuitem) ? ArrowDropRight : null
        input.nestedLevel = nestedLevel

        input.nestedListStyle = (listitem) ? { padding: 0 } : {}

        input.nestedItems = (listitem) ? function(lprops) {
            nestedLevel = nestedLevel + 1

            const menugo = []
            if (lprops.menu && lprops.menu.length) {
                lprops.menu.map(function(m) {
                    let go = true
                    if (m.role) go = m.role(props)
                    return (go) ? menugo.push(m) : null
                })
            }

            if (menugo.length) {
                return [
                    ...menugo.map(function(m, index) {
                        let go = true
                        if (m.role) go = m.role(props)
                        return (go) ? items(m, index, lprops, nestedLevel) : null
                    })
                ]
            }

            return []
        }({
            style: style,
            menu: m.items,
            history: history,
            parentroute: parentroute,
            Menuitem: Menuitem,
            classname: (classname) ? classname : style['sub-navigation'],
            state: state,
            searchData: searchData,
            sendprops: sendprops,
            setOpenMenu,
            removeOpenMenu,
            popperRoot
        }) : []

        return E.link1(input)

    } else {
        nestedLevel = 0
        return E.link2(input)
    }
}

const E = {
    link1: function(props) {
        const {
            linkclassname,
            state,
            index,
            m,
            parentroute,
            history,
            Menuitem,
            style,
            nestedLevel,
            nestedItems,
            storediconmenu,
            addsubopenref,
            menurighticon,
            searchData,
            nestedListStyle,
            sendprops,
            setOpenMenu,
            removeOpenMenu,
            popperRoot
        } = props

        const listitem = (Menuitem && Menuitem.muiName === 'ListItem')
        const menuitem = (Menuitem && Menuitem.muiName === 'MenuItem')
        const iconClassName = (listitem) ? 'list' : (menuitem) ? 'menu' : ''
        let link = getmenulink({ m, props })
        if (parentroute && !m.disableParentRoute) link = parentroute + link

        const menugo = []
        if (m.items && m.items.length) {
            m.items.map(function(m) {
                let go = true
                if (m.role) go = m.role(props)
                return (go) ? menugo.push(m) : null
            })
        }

        return (
            <LinkComponent
                nestedListStyle={nestedListStyle}
                className={linkclassname}
                state={state}
                key={index}
                to={(link) ? link : null}
                hash={(m.hash) ? m.hash : null}
                history={history}
                Menuitem={Menuitem || FlatButton}
                Lefticon={getlefticon({ m, props, iconClassName })}
                style={style}
                nestedLevel={nestedLevel}
                nestedItems={(listitem) ? nestedItems : null}
                menuItems={!!(menuitem)}
                storediconmenu={storediconmenu}
                searchData={searchData}
                menudata={m}
                setOpenMenu={setOpenMenu}
                removeOpenMenu={removeOpenMenu}
                popperRoot={popperRoot}
                onClick={(m.onClick) ? function(e) {
                    return m.onClick(props, e)
                } : null}
            >
                {getmenuname({ m, props })}
                {(!listitem && menugo.length) ?
                    <div
                        className={(m.disablemoreicon && !menuitem && !listitem) ? style.sub + ' ' + style.hiddeniconbutton : style.sub}>
                        <MenuComponent type={!!(menuitem || listitem)} style={style} icon={menurighticon}
                                       anchorOrgin={menurighticon && { horizontal: 'right', vertical: 'top' }}
                                       targetOrgin={menurighticon && { horizontal: 'left', vertical: 'top' }}
                                       setRef={addsubopenref}
                                       popperRoot={popperRoot}
                        >
                            <MenuItems style={style} menu={m.items} history={history}
                                       parentroute={parentroute} Menuitem={MenuItem}
                                       classname={'side'} state={state} searchData={searchData}
                                       sendprops={sendprops}
                                       popperRoot={popperRoot}
                            />
                        </MenuComponent>
                    </div> : null
                }
            </LinkComponent>
        )
    },
    link2: function(props) {

        const {
            linkclassname,
            state,
            index,
            m,
            parentroute,
            history,
            Menuitem,
            style,
            searchData,
            setOpenMenu,
            removeOpenMenu,
            popperRoot
        } = props

        const listitem = (Menuitem && Menuitem.muiName === 'ListItem')
        const menuitem = (Menuitem && Menuitem.muiName === 'MenuItem')
        const iconClassName = (listitem) ? 'list' : (menuitem) ? 'menu' : ''
        let link = getmenulink({ m, props })
        if (parentroute && !m.disableParentRoute) link = parentroute + link

        return (
            <LinkComponent
                className={linkclassname}
                state={state}
                key={index}
                to={(link) ? link : null}
                hash={(m.hash) ? m.hash : null}
                history={history}
                Menuitem={Menuitem || FlatButton}
                Lefticon={getlefticon({ m, props, iconClassName })}
                style={style}
                searchData={searchData}
                menudata={m}
                setOpenMenu={setOpenMenu}
                removeOpenMenu={removeOpenMenu}
                popperRoot={popperRoot}
                onClick={(m.onClick) ? function(e) {
                    return m.onClick(props, e)
                } : null}
            >
                {getmenuname({ m, props })}
            </LinkComponent>
        )
    },
    parent: function(props) {
        const { style, menu, Menuitem, classname, Parent, setRef, state, parentprops } = props
        const listitem = (Menuitem && Menuitem.muiName === 'ListItem')

        let menugo = []
        menu.map(function(m) {
            let go = true
            if (m.role) go = m.role(props)
            return (go) ? menugo.push(m) : null
        })

        if (menugo.length) {
            let onlyDividers = true
            menugo.map(function(menu) {
                if (!menu.divider) onlyDividers = false
            })
            if (onlyDividers) {
                menugo = []
            }
        }

        if (menugo.length) {
            return (
                <Parentitem state={state} setRef={setRef}
                            className={(classname) ? style[classname + '-navigation'] : style.navigation}
                            style={(!listitem && style) ? style : null} Parent={Parent} parentprops={parentprops}>
                    {menu.map(function(m, index) {
                        let go = true
                        if (m.role) go = m.role(props)
                        return (go) ? items(m, index, props) : null
                    })}
                </Parentitem>
            )
        } else {
            return null
        }
    }
}

export default createReactClass({
    render() {
        return E.parent(this.props)
    }
})
