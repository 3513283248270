function capitalizeFirstLetter(string) {
    if (string && typeof string == 'string') return string.charAt(0).toUpperCase() + string.slice(1)
    return string
}

const helpers = {
    paths: function({ url }) {
        let path = (url) ? url.split('?')[0] : ''
        if (path && path.slice(0, 1) === '/') path = path.slice(1)
        if (path && path.slice(-1) === '/') path = path.slice(0, -1)
        return (path) ? path.split('/') : []
    },
    archivepath: function({ url, posttypes, returnpath }) {

        const paths = this.paths({ url })
        let foundedarchivepath = ''

        if (paths && paths.length) {
            Object.keys(posttypes).forEach(function(key) {
                const path = posttypes[key].path
                const name = posttypes[key].name
                paths.map(function(cpath) {
                    if (cpath && path && cpath === path) {
                        foundedarchivepath = name
                        if (returnpath) foundedarchivepath = path
                    }
                })
            })
        }

        return foundedarchivepath
    },
    search: function({
                         state,
                         params,
                         replacequery = {},
                         taxonomies,
                         storedSearchData,
                         defaultSearchPostType,
                         posttypes
                     }) {

        const url = (state && state.url) ? state.url : ''
        const foundedarchivepath = this.archivepath({ url, posttypes, returnpath: true })

        if (foundedarchivepath && params && params[foundedarchivepath]) {
            const paths = this.paths({ url })
            let foundtaxonomy = null
            paths.map(function(path, i) {
                if (path && path !== foundedarchivepath && path === params[foundedarchivepath]) {
                    if (paths[i - 1] !== foundedarchivepath) foundtaxonomy = paths[i - 1]
                }
            })
            if (foundtaxonomy) {
                replacequery[foundtaxonomy] = params[foundedarchivepath]
            }
        }

        const query = (state && state.query) ? state.query : {}

        if (replacequery) {
            Object.keys(replacequery).map(function(k) {
                if (!query[k]) query[k] = replacequery[k]
            })
        }

        if (!query.posttype && defaultSearchPostType) query.posttype = defaultSearchPostType
        if (query.posttype) query.posttype = capitalizeFirstLetter(query.posttype)
        const historyHelpers = storedSearchData.getHistoryHelpers()
        const searchDataObject = historyHelpers.getDataFromHistory({ data: query })

        let q = {
            t: searchDataObject.text,
            c: {},
            p: searchDataObject.page,
            l: searchDataObject.limit,
            u: searchDataObject.user,
            su: searchDataObject.secuser,
            pt: searchDataObject.posttype,
            sgt: searchDataObject.sgt,
            slt: searchDataObject.slt,
            asgt: searchDataObject.asgt,
            aslt: searchDataObject.aslt,
            so: searchDataObject.so,
            sh: searchDataObject.sh
        }

        function getChildCats(p, data) {
            data.map(function(t) {
                p[t.id] = { id: t.id }
                if (t.items) getChildCats(p, t.items)
            })
        }

        function findCat(p = [], f, data) {
            data.map(function(t) {
                if (t.id === f) p.push(t)
                if (t.items) findCat(p, f, t.items)
            })
            return p
        }

        Object.keys(taxonomies).forEach(function(key) {
            const ts = taxonomies[key].path

            const tic = taxonomies[key].coll
            const childtaxs = {}

            if (searchDataObject[ts]) {
                Object.keys(searchDataObject[ts]).map(function(tk) {
                    const t = searchDataObject[ts][tk]
                    const c = findCat([], t.id, taxonomies[key].items)
                    if (c[0] && c[0].items) getChildCats(childtaxs, c[0].items)
                })
            }

            if (searchDataObject[ts]) q.c[tic] = { ...searchDataObject[ts], ...childtaxs }
        })

        if (q.c) {
            const taxs = {}
            Object.keys(q.c).map(function(t) {
                if (!taxs[t]) taxs[t] = []
                if (q.c[t]) {
                    Object.keys(q.c[t]).map(function(tk) {
                        taxs[t].push(tk)
                    })
                }
            })
            q.c = taxs
        }
        return q
    },
    defaultPostsDataQuery: function() {

        const userQuery = helpers.defaultUserQuery()

        return `
        count
        skip
        limit
        pages
        skippedpages
        currentpage
        nextpages
        author {
            ${userQuery}
        }`
    },
    defaultPostQuery: function() {

        const userQuery = helpers.defaultUserQuery()
        const imageRelQuery = helpers.imageQuery()

        return `
        id
        title
        author {
            ${userQuery}
        }
        authorstatus
        status
        subtitle
        state
        share
        publishedDate
        cover
        coverrel {
            ${imageRelQuery}
        }
        content_brief
        content_extended
        gallery {
            ${imageRelQuery}
        }
        category`
    }

}

export default helpers
