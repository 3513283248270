// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n._6LBe3 {\n    display: table-cell;\n    width: 100%;\n}\n\n.M19pH {\n    display: table;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nextprevbuttons": "_6LBe3",
	"buttonsdiv": "M19pH"
};
module.exports = ___CSS_LOADER_EXPORT___;
