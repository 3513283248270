/*States*/
import { actions, reducers } from '../states'

export default function() {
    return {
        actions: actions,
        reducers: reducers,
        getInitialState: function(req, resp) {
            const initialState = {}
            initialState.query = req.wappRequest.query || {}
            initialState.url = req.url || '/'
            initialState.user = req.user || null
            initialState.response = resp || {}
            return initialState
        }
    }
}
