export default function({ user }) {
    function getName() {
        const businessname = (user && user.businessname) ? user.businessname : ''
        if (businessname) return businessname
        const firstname = (user && user.name && user.name.first) ? user.name.first : ''
        const lastname = (user && user.name && user.name.last) ? user.name.last : ''
        return (firstname && lastname) ? firstname + ' ' + lastname : (firstname) ? firstname : lastname
    }

    function getLink() {
        const id = (user && user.id) || 0
        if (id) return '/user/' + id
        return ''
    }

    user.getName = getName
    user.getLink = getLink

    return { getName, getLink }
}
