import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import s from '../empty.css'
import EditIcon from 'material-ui/svg-icons/editor/mode-edit'
import IconButton from 'material-ui/IconButton'
import ArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down'
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up'

import WapplrAvatar from '../Wapplravatar'
import wapplravatarhelpers from '../Wapplravatar/helpers'

const Post = createReactClass({
    getInitialState() {
        const { enablehiddencontent } = this.props
        return {
            isMounted: true,
            hiddencontent: !!(enablehiddencontent)
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    hiddencontent: function() {
        this.setState({
            hiddencontent: !this.state.hiddencontent
        })
    },
    click: function(e, d) {

        const { type, link } = d
        const { history } = this.props

        if (type === 'back') {
            history.go(-1)
        }

        if (type === 'push') {
            history.push(link)
        }

        if (type === 'hiddencontent') {
            this.hiddencontent()
        }

        e.preventDefault()

    },
    render: function() {
        const {
            style = s, post, showauthor, avatarstyle, enablehiddencontent,
            history, edit, statusname = '', publiciconcontent, className,
            Editicon = EditIcon, avatarDisableInitWithStyles, avatarUrlTargetBlank, avatarBig, buttons, edittooltip,
            getthumb, getthumbstyle,
            namePrefix = 'post'
        } = this.props

        const user = (showauthor && post && post.author && wapplravatarhelpers({ user: post.author })) ? post.author : null
        const touchEnd = this.touchEnd

        const state = this.state
        const cn = (className) ? (style[className]) ? style[className] : className : null

        return (
            <div className={(cn) ? cn + ' ' + style.postcont : style.postcont}>
                {(post) ?
                    <div className={style.post}>
                        <div className={style.postheader}>
                            <div className={style.titlecontainer}>
                                {(user) ?
                                    <div className={style.avatarbox}>
                                        <WapplrAvatar disableInitWithStyles={avatarDisableInitWithStyles} user={user}
                                                      style={avatarstyle} enableUserLink={true}
                                                      targetBlank={avatarUrlTargetBlank} history={history}
                                                      big={avatarBig}
                                                      getthumbstyle={getthumbstyle} getthumb={getthumb} />
                                    </div>
                                    : null
                                }
                                {(publiciconcontent) ?
                                    <div className={style.lefticon}>
                                        {publiciconcontent}
                                    </div>
                                    : null
                                }
                                <div name={namePrefix + '_title'} className={style.title}>{post.title}</div>
                                {(edit) ?
                                    <div className={style.titleeditcontainer}>
                                        <IconButton
                                            onTouchTap={function(e) {
                                                touchEnd(e, 'click', 'click', { type: 'push', link: edit })
                                            }}
                                            onTouchStart={function() {
                                            }}
                                            className={style.titleeditbutton}
                                            tooltip={(edittooltip) ? edittooltip : null}
                                        >
                                            <Editicon />
                                        </IconButton>
                                    </div>
                                    :
                                    null
                                }
                                {(enablehiddencontent && post.content_brief || enablehiddencontent && post.content_extended) ?
                                    <div className={style.titlehiddencontainer}>
                                        <IconButton
                                            onTouchTap={function(e) {
                                                touchEnd(e, 'click', 'click', { type: 'hiddencontent' })
                                            }}
                                            onTouchStart={function() {
                                            }}
                                            className={style.titlehiddenbutton}
                                        >
                                            {(state.hiddencontent) ? <ArrowDown /> : <ArrowUp />}
                                        </IconButton>
                                    </div>
                                    :
                                    null
                                }
                                {(buttons) ? <div className={style.buttons}>{buttons}</div> : null}
                            </div>
                            <div className={style.subtitle}>{(statusname) ? statusname : post.subtitle}</div>
                        </div>
                        {(enablehiddencontent && state.hiddencontent) ? null :
                            <div className={style.postcontent}>
                                <div className={style.content}
                                     dangerouslySetInnerHTML={{ __html: post.content_extended || post.content_brief }} />
                            </div>
                        }
                    </div>
                    : null}
            </div>
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props

        this.style = style
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Post) : Post
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
