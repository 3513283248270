import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import RaisedButton from 'material-ui/RaisedButton'
import CircularProgress from 'material-ui/CircularProgress'

import Formsy from 'formsy-react'

import {
    FormsyAutoComplete,
    FormsyCheckbox,
    FormsyDate,
    FormsyRadio,
    FormsyRadioGroup,
    FormsySelect,
    FormsyText,
    FormsyTime,
    FormsyToggle
} from 'formsy-material-ui/lib'
import s from '../../empty.css'

const comps = {
    text: FormsyText,
    button: RaisedButton,
    date: FormsyDate,
    radio: FormsyRadio,
    checkbox: FormsyCheckbox,
    radioGroup: FormsyRadioGroup,
    select: FormsySelect,
    time: FormsyTime,
    toggle: FormsyToggle,
    autocomplete: FormsyAutoComplete
}

const Formitems = createReactClass({

    render: function() {
        const { style, setRef, state } = this.props
        const props = this.props
        const formdata = state.items || []
        const r = []

        formdata.map(function(itemdata, formindex) {

            const C = itemdata.component || comps[itemdata.type]
            if (!C) return <div />

            const attr = {
                name: 'wapplr_' + formindex
            }

            Object.keys(itemdata.attr).forEach(function(i) {
                const attribute = itemdata.attr[i]
                if (attribute) {
                    attr[i] = (typeof attribute == 'function') ? attribute(state, props) : attribute
                }
            })

            r.push(
                <div
                    className={(itemdata.className && style[itemdata.className]) ? style[itemdata.type] + ' ' + style[itemdata.className] : style[itemdata.type]}
                    key={formindex}
                    style={(itemdata.style) ? (typeof itemdata.style == 'function') ? itemdata.style(state, props) : itemdata.style : null}>
                    {(itemdata.type === 'button' && state.processing) ?
                        <C ref={function(e) {
                            setRef(attr.name, e)
                        }} {...attr} icon={<CircularProgress size={24} thickness={3} />} /> :
                        <C ref={function(e) {
                            setRef(attr.name, e)
                        }} {...attr} />
                    }
                </div>
            )
        })

        return <div>{r}</div>

    }
})

const Forms = createReactClass({

    getInitialState() {
        this.refElements = {}
        const { formdata = [] } = this.props

        return {
            canSubmit: this.props.canSubmit || false,
            processing: false,
            c: false,
            items: formdata
        }
    },

    enableButton() {
        this.setState({
            canSubmit: true
        })
    },

    disableButton() {
        this.setState({
            canSubmit: false
        })
    },

    processingStart(disable) {
        this.setState({
            processing: true,
            canSubmit: !disable
        })
    },

    processingEnd(disable) {
        this.setState({
            processing: false,
            canSubmit: !disable
        })
    },

    submitForm(a, b, c) {
        this.onSubmit(a, b, c, {
            elements: this.refElements,
            disableButton: this.disableButton,
            enableButton: this.enableButton,
            processingStart: this.processingStart,
            processingEnd: this.processingEnd
        })
    },
    onSubmit: function() {
    },
    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },
    notifyFormError(data) {
        const { notifyFormError } = this.props
        if (notifyFormError) notifyFormError(data)
    },

    componentDidMount() {
        const { submitForm } = this.props
        if (submitForm) this.onSubmit = submitForm
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    render() {
        const { className, style } = this.props

        const setRef = this.setRef
        const refElements = this.refElements
        const input = { ...this.props, setRef: setRef, refElements: refElements, state: this.state }

        const cn = (className) ? (style[className]) ? style[className] : className : ''

        return (
            <div>
                <div className={cn}>
                    <Formsy.Form
                        ref={function(e) {
                            setRef('formsy', e)
                        }}
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        onValidSubmit={this.submitForm}
                        onInvalidSubmit={this.notifyFormError}
                    >
                        <div>
                            {(this.state.isMounted) ? <Formitems {...input} /> : null}
                        </div>
                    </Formsy.Form>
                </div>
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Forms {...this.props} ref={(setRef) ? function(e) {
                setRef('forms', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
