import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import Subheader from 'material-ui/Subheader'
import s from '../empty.css'

import WapplrAvatar from '../Wapplravatar'
import wapplravatarhelpers from '../Wapplravatar/helpers'
import Chip from 'material-ui/Chip'

const Breadcrumb = createReactClass({
    getInitialState: function() {
        const sticky = ('undefined' !== typeof window && window.scrollY && this.props.enablestickystyle) ? window.scrollY : 0
        return {
            sticky: sticky
        }
    },
    stickyOn: function() {
        if (!this.state.sticky) {
            this.setState({ sticky: true })
            this.state.sticky = true
        }
    },
    stickyOff: function() {
        if (this.state.sticky) {
            this.setState({ sticky: false })
            this.state.sticky = false
        }
    },
    lastSticky: 0,
    stickyListener: function() {
        const sticky = ('undefined' !== typeof window && window.scrollY && this.props.enablestickystyle) ? window.scrollY : 0
        if (sticky && !this.lastSticky) {
            this.stickyOn()
            this.lastSticky = 1
        }
        if (!sticky && this.lastSticky) {
            this.stickyOff()
            this.lastSticky = 0
        }
    },
    isIOs: function() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    },
    componentDidMount() {
        if (this.props.enablestickystyle) {
            if (this.isIOs()) {
                window.addEventListener('scroll', this.stickyListener)
            } else {
                window.addEventListener('scroll', this.stickyListener)
            }
        }
        setTimeout(this.stickyListener)
    },
    componentWillUnmount: function() {
        if (this.props.enablestickystyle) {
            if (this.isIOs()) {
                window.removeEventListener('scroll', this.stickyListener)
            } else {
                window.removeEventListener('scroll', this.stickyListener)
            }
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        this.historyPush(d)
        if (e) e.preventDefault()
    },
    clearAuthor: function(e) {

        const historyHelpers = this.props.historyHelpers
        const so = historyHelpers.getDataCurrent()
        so.user = ''
        so.page = ''
        const searchtext = historyHelpers.objectToString(so)
        historyHelpers.historyPush({ search: searchtext })
        if (e) e.preventDefault()

    },
    historyPush: function(d) {
        const historyHelpers = this.props.historyHelpers
        historyHelpers.historyPush(d)
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    querySearchText: function() {
        const { historyHelpers } = this.props
        const { store } = this.getHelpers()
        const h = historyHelpers.getDataFromHistory({ store })
        return historyHelpers.objectToString(h)
    },
    getTaxLinkAndSo: function({ key, author, taxonomies }) {
        const {
            history, hierarchy, historyHelpers, posttypepath = '', enableSearchQueryDataInheritance = [
                'text', 'user', 'sgt', 'slt', 'asgt', 'aslt'
            ]
        } = this.props
        const taxso = historyHelpers.getTaxonomyLink(taxonomies, hierarchy[key], posttypepath)
        let link = taxso.link
        if (author) {
            const authorsearch = historyHelpers.getAuthorSearch(author)
            link = (link && link.match(/\?/)) ? link + '&' + authorsearch : link + '?' + authorsearch
            taxso.search = (taxso.search) ? taxso.search + '&' + authorsearch : authorsearch
        }

        let search = this.querySearchText()
        search = search.split('&').filter((t) => enableSearchQueryDataInheritance.find((key) => t.match(new RegExp('&' + key + '+(\\=[^&]*)?(?=&|$)|^' + key + '(\\=[^&]*)?(&|$)')))).join('&')
        const hash = (history.location.hash) ? (history.location.hash.startsWith('#')) ? history.location.hash.slice(1) : history.location.hash : ''

        if (search) {
            taxso.search = (taxso.search) ? taxso.search + '&' + search : search
            link = (link && link.match(/\?/)) ? link + '&' + search : link + '?' + search
        }

        if (hash) {
            taxso.hash = hash
            link = link + '#' + hash
        }

        const name = (hierarchy[key]) ? hierarchy[key].pluralname || hierarchy[key].name : null
        return { link, taxso, name }
    },
    getPostTypeLink: function({ author }) {
        const { posttypepath, historyHelpers } = this.props
        let link = (posttypepath) ? '/' + posttypepath : ''
        const pathname = (posttypepath) ? '/' + posttypepath : ''
        let search = ''
        if (link && author) {
            const authorsearch = historyHelpers.getAuthorSearch(author)
            link = link + '?' + authorsearch
            search = authorsearch
        }
        return { link, to: pathname, search }
    },
    getResultsTextDefault: function({ skip, currentpage, limit, count, length }) {
        let s = Number(skip)
        const l = (currentpage * limit < count) ? currentpage * limit : count
        const r = (count > 1) ? 'results' : 'result'
        let t
        if (s > 0) {
            s = s + 1
            t = 'shown ' + s + '-' + l + ' of ' + count + ' ' + r
        } else {
            t = 'shown ' + length + ' of ' + count + ' ' + r
        }
        return t
    },
    toRender: function() {

        const {
            style = s,
            className,
            posts,
            data,
            author,
            curlang = {}
        } = this.props

        const {
            getResultsText = this.getResultsTextDefault
        } = curlang

        const resultstext = (data && posts && posts.length) ? getResultsText({ ...data, length: posts.length }) : ''
        let rclassName = (className) ? style.breadcrumbs + ' ' + className : style.breadcrumbs
        if (this.state.sticky) rclassName = rclassName + ' ' + style.breadcrumbssticky
        if (author && !author.getName) wapplravatarhelpers({ user: author })
        const postTypeLinkSo = this.getPostTypeLink({ author })


        return { resultstext, author, className: rclassName, postTypeLinkSo }
    },
    capitalizeFirstLetter: function(string) {
        if (string && typeof string == 'string') return string.charAt(0).toUpperCase() + string.slice(1)
        return string
    },
    render: function() {

        const {
            style,
            hierarchy,
            taxonomies,
            posttypepath = '',
            posttypename = '',
            avatarstyle,
            getthumb,
            openfiltersicon
        } = this.props

        const { resultstext, author, className, postTypeLinkSo } = this.toRender()
        const touchEnd = this.touchEnd
        const getTaxLinkAndSo = this.getTaxLinkAndSo
        const capitalizeFirstLetter = this.capitalizeFirstLetter
        const openfiltersiconcontent = (openfiltersicon) ? openfiltersicon() : null
        const hk = Object.keys(hierarchy)

        const hks = [
            ...hk.map(function(key, index) {
                const { taxso, link, name } = (hierarchy[key]) ? getTaxLinkAndSo({ key, author, taxonomies }) : {}
                if (link && name && taxso) return (
                    <span key={index}>
                                <span className={style.breadcrumb}>
                                    <a href={link}
                                       onClick={function(e) {
                                           touchEnd(e, 'click', 'click', taxso)
                                       }}
                                       onTouchStart={function() {
                                       }}>
                                        {name}
                                    </a>
                                </span>
                        {(hk[index + 1]) ? <span className={style.separator}>{' | '}</span> : null}
                            </span>
                )
                return null
            })
        ]

        return (
            <Subheader className={className}>
                <div className={style.breadcrumbsLeft}>
                    {(posttypepath) ?
                        <span>
                            <span className={style.breadcrumb}>
                                <a href={postTypeLinkSo.link}
                                   onClick={function(e) {
                                       touchEnd(e, 'click', 'click', postTypeLinkSo)
                                   }}
                                   onTouchStart={function() {
                                   }}>
                                    {(posttypename) ? posttypename : capitalizeFirstLetter(posttypepath)}
                                </a>
                            </span>
                            {(hks && hks.length && hks[0]) ? <span className={style.separator}>{' | '}</span> : null}
                        </span>
                        : null}
                    {(hks) ? hks : null}
                    {(openfiltersiconcontent) ?
                        <div className={style.openfiltersicon}>{openfiltersiconcontent}</div>
                        : null
                    }
                    {(resultstext && author) ?
                        <div className={style.resultstext}>
                            {resultstext}
                        </div>
                        : null
                    }
                </div>
                <div className={style.breadcrumbsRight}>
                    {(author) ?
                        <div className={style.chip}>
                            <Chip onRequestDelete={function(e) {
                                touchEnd(e, 'clearAuthor', 'click')
                            }}>
                                <div className={style.chipavatar}><WapplrAvatar user={author} style={avatarstyle}
                                                                                getthumb={getthumb} /></div>
                                <div className={style.chipname}>{author.getName()}</div>
                            </Chip>
                        </div> : null
                    }
                    {(resultstext && !author) ?
                        <div className={style.resultstext}>
                            {resultstext}
                        </div>
                        : null
                    }
                </div>
            </Subheader>
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, avatarstyle = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Breadcrumb) : Breadcrumb
        const input = { ...this.props, style, avatarstyle }

        return (
            <R {...input} />
        )
    }
})
