import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import DoneIcon from 'material-ui/svg-icons/action/done'
import ClearIcon from 'material-ui/svg-icons/content/clear'
import FlatButton from 'material-ui/FlatButton'

import s from '../empty.css'

const PostAdmin = createReactClass({
    render: function() {
        const { curlang = {}, approveenable, approvefunction, banenable, banfunction, post } = this.props

        const {
            approvetext = 'Approve',
            bantext = 'Delete permanetly'
        } = curlang

        return (
            <div>
                {(approveenable) ?
                    <FlatButton onTouchTap={function(e) {
                        approvefunction(e, { post })
                    }}
                                onTouchStart={function() {

                                }}
                                icon={<DoneIcon />}
                                label={approvetext}
                    /> : null
                }
                {(banenable) ?
                    <FlatButton onTouchTap={function(e) {
                        banfunction(e, { post })
                    }}
                                onTouchStart={function() {

                                }}
                                icon={<ClearIcon />}
                                label={bantext}
                    /> : null
                }
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <PostAdmin {...this.props} ref={(setRef) ? function(e) {
                setRef('postadmin', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
