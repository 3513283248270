import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents from '../../components'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

import Footer from '../components/Footer'
import fs from '../components/Footer/themes/default/footer.css'
import fsm from '../components/Footer/themes/default/menu.css'

const Wapplrmenu = wapplrcomponents.menu
const Wapplrcontent = wapplrcomponents.content
const Wapplrpage = wapplrcomponents.page

import FC from "../components/Logo/FC";

export default createReactClass({
    getInitialState: function() {

        const { defaultSearchPostType } = this.props
        const posttype = this.getPostType()
        const isDefaultPostType = (defaultSearchPostType && posttype && posttype === defaultSearchPostType)
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath
        const user = this.getUser()

        this.refElements = {}

        return {
            isMounted: false,
            posttype: posttype || '',
            isDefaultPostType: isDefaultPostType,
            posttypepath: posttypepath || '',
            posttypename: posttypename || '',
            user: user || null
        }
    },
    componentDidMount: function() {

        const { defaultSearchPostType } = this.props
        const posttype = this.getPostType()
        const isDefaultPostType = (defaultSearchPostType && posttype && posttype === defaultSearchPostType)
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath
        const user = this.getUser()

        this.historyHook()

        this.state.isMounted = true
        this.setState({
            isMounted: true,
            posttype: posttype || '',
            isDefaultPostType: isDefaultPostType,
            posttypepath: posttypepath || '',
            posttypename: posttypename || '',
            user: user || null
        })
    },
    getUser: function() {
        const { helpers, userConstructHelpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        let user = null
        if (state && state.response && state.response.me) {
            user = state.response.me
            if (userConstructHelpers) userConstructHelpers({ user })
        }
        return user
    },
    getPostType: function() {
        const { helpers, defaultSearchPostType = '' } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return (state && state.query) ? state.query.posttype : defaultSearchPostType
    },
    getPostTypePath: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].path) ? posttypesData[posttype].path : ''
    },
    getPostTypeName: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].pluralname) ? posttypesData[posttype].pluralname : ''
    },
    getCurrentSearchPath: function() {
        const { searchRoute = '/search' } = this.props
        return (this.state.posttypepath && !this.state.isDefaultPostType) ? '/' + this.state.posttypepath + searchRoute : searchRoute
    },
    getTaxonomyCount: function(props = {}) {

        const { taxonomiesData, taxonomiesPath, taxonomyName, posttype } = props
        let count = 0

        if (taxonomiesData && taxonomiesPath && taxonomyName && posttype &&
            taxonomiesData[taxonomiesPath] && taxonomiesData[taxonomiesPath].items) {

            const posstypes = taxonomiesData[taxonomiesPath].posttype

            posstypes.map(function(pt, pti) {
                if (pt === posttype) {
                    taxonomiesData[taxonomiesPath].items.map(function(taxonomy) {
                        if (taxonomy.name === taxonomyName && taxonomy.countbyposttype && taxonomy.countbyposttype.length) {
                            count = taxonomy.countbyposttype[pti] || 0
                        }
                    })
                }
            })

        }

        return count
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getHost: function() {
        const { helpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return (state.runtime && state.runtime.host) ? state.runtime.host : ''
    },
    getFullPath: function() {
        const { helpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return state.url || '/'
    },
    isGasztroPiac: function() {
        const fullPath = this.getFullPath()
        const host = this.getHost()
        if (fullPath.match('/ad') ||
            fullPath.match('/ads') ||
            fullPath === '/ads' ||
            fullPath.match('gasztro')) {
            return true
        }
        return !!(host.match('gasztropiac' && fullPath === '/') || host.match('gasztropiac' && fullPath === ''))

    },
    historyHook: function() {
        const { history } = this.props
        const startLogoProgress = this.startLogoProgress
        this.beforeListenID = history.addBeforeListener(function() {
            startLogoProgress()
            return false
        })
    },
    removeHistoryHook: function() {
        const { history } = this.props
        history.removeBeforeListener(this.beforeListenID)
    },
    componentWillUnmount: function() {
        this.removeHistoryHook()
    },
    startLogoProgress: function() {
        const logo = this.refElements['logo']
        if (logo) {
            logo.loading()
        }
    },
    stopLogoProgress: function() {
        const logo = this.refElements['logo']
        if (logo) {
            logo.stopLoading()
        }
    },
    render: function() {

        const {
            drawerwidth,
            menus,
            name,
            menuName = 'main',
            style,
            headerstyle,
            history,
            logohref,
            onDrawerChange,
            pageRefs,
            children,
            muiTheme,
            menuRefs,
            menuData,
            pageData,
            searchData,
            className
        } = this.props

        this.getCurrentSearchPath()

        const setRef = this.setRef

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(muiTheme)}>

                <Wapplrcontent style={style}>
                    <div className={style.app}
                         ref={function(e) {
                             setRef('app', e)
                         }}>
                        <div className={style.header}>
                            <Wapplrmenu
                                ref={function(e) {
                                    menuRefs(menuName, e)
                                }}
                                data={menuData}
                                sendprops={{ user: this.state.user }}
                                style={headerstyle}
                                menu={menus[menuName]}
                                fixeddrawer={menus.fixeddrawer}
                                enablestickystyle={true}
                                history={history}
                                searchData={searchData}
                                type='drawer'
                                breakpoint={1024}
                                logohref={logohref}
                                swapdirection={true}
                                drawerwidth={drawerwidth || 256}
                                onDrawerChange={function(open, closeFunction) {
                                    return onDrawerChange(open, closeFunction, name, 'drawer1')
                                }}
                            >
                            </Wapplrmenu>
                        </div>
                        <Wapplrpage ref={function(e) {
                            pageRefs(name, e)
                        }}
                                    className={style.container + ' ' + style['page-containerfullwidth'] + ' ' + style['page-padding']}
                                    openClassName={style['page-containerfullwidth-open']}
                                    data={pageData}
                        >
                            <div
                                className={(className) ? (style[className]) ? style[className] : className : style.row}>
                                {(children) ? children : null}
                            </div>
                        </Wapplrpage>
                        <Footer
                            {...this.props}
                            style={fs}
                            menustyle={fsm}
                            footercopyright={
                                <div>
                                    <div style={{height:"16px", cursor: "pointer"}}>
                                        <span style={{verticalAlign: "baseline"}}>
                                            <div style={{display:"inline-block", height:"72%"}}>
                                                <FC />
                                            </div>
                                        </span>
                                        <span style={{marginLeft:"3px"}}>{`© ${new Date().getFullYear()}`}</span>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </Wapplrcontent>
            </MuiThemeProvider>
        )
    }
})
