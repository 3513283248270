import React from 'react'
import createReactClass from 'create-react-class'

import se from '../../themes/components/Wapplrsearch.css'

import wapplrcomponents from '../../components'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import helpers from '../services/requests/helpers'

import Footer from '../components/Footer'
import fs from '../components/Footer/themes/default/footer.css'
import fsm from '../components/Footer/themes/default/menu.css'

import Notification from '../components/Notification'
import nfs from '../components/Notification/default.css'
import FC from '../components/Logo/FC'

const Wapplrmenu = wapplrcomponents.menu
const Wapplrcontent = wapplrcomponents.content
const Wapplrpage = wapplrcomponents.page
const Wapplrsearch = wapplrcomponents.search

const Children = createReactClass({
    getInitialState: function() {
        const { children } = this.props
        return {
            children: children
        }
    },
    updateChildren: function(children) {
        this.setState({
            children: children
        })
    },
    render: function() {
        const { children } = this.state
        return (children) ? <div>{children}</div> : null
    }
})

export default createReactClass({
    getInitialState: function() {

        this.refElements = {}
        const { defaultSearchPostType } = this.props
        const posttype = this.getPostType()
        const isDefaultPostType = (defaultSearchPostType && posttype && posttype === defaultSearchPostType)
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath
        const user = this.getUser()

        return {
            isMounted: true,
            posttype: posttype || '',
            isDefaultPostType: isDefaultPostType,
            posttypepath: posttypepath || '',
            posttypename: posttypename || '',
            user: user || null
        }
    },
    componentDidMount: function() {
        const { updatefunctions } = this.props
        updatefunctions.me = this.updateMe

        const fullPath = this.getFullPath()
        const disableHistoryHook = !!(fullPath && fullPath.match('new') || fullPath && fullPath.match('edit'))
        if (!disableHistoryHook) this.historyHook()
        this.addErrorListener()
    },
    componentWillUnmount: function() {
        this.removeHistoryHook()
        this.removeErrorListener()
    },
    removeErrorListener: function() {
        if (this.handleError && typeof window !== 'undefined') {
            window.removeEventListener('error', this.handleError)
            this.handleError = null
            alert('Remove error listener')
        }
    },
    addErrorListener: function() {
        if (this.handleError) {
            this.removeErrorListener()
        }
        if (typeof window !== 'undefined') {
            if (window.location.search && window.location.search.match('errorsToAlert')) {
                this.handleError = function(evt) {
                    if (evt.message) {
                        alert('error: ' + evt.message + ' at linenumber: ' + evt.lineno + ' of file: ' + evt.filename)
                    } else {
                        alert('error: ' + evt.type + ' from element: ' + (evt.target))
                    }
                }
                window.addEventListener('error', this.handleError, true)
                alert('Add error listener')
            }
        }
    },
    isChanged: function(a, b) {
        let changed = false
        const isChanged = this.isChanged
        if (a && b && typeof a == 'object' && typeof b == 'object') {
            const keys = (a.length === undefined) ? Object.keys(a) : [...a.keys()]
            keys.map(function(key) {
                if (typeof a[key] !== typeof b[key]) changed = true
                if (!changed) {
                    if (a[key] && typeof a[key] == 'object') {
                        changed = isChanged(a[key], b[key])
                    } else {
                        if (a[key] !== b[key]) changed = true
                    }
                }
            })
        } else {
            if (typeof a !== typeof b) changed = true
            if (a !== b) changed = true
        }
        return changed
    },
    componentWillReceiveProps: function(nextProps) {

        const { defaultSearchPostType } = nextProps
        const posttype = this.getPostType()
        const isDefaultPostType = (defaultSearchPostType && posttype && posttype === defaultSearchPostType)
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath
        const user = this.getUser()

        const nextState = {
            isMounted: true,
            posttype: posttype || '',
            isDefaultPostType: isDefaultPostType,
            posttypepath: posttypepath || '',
            posttypename: posttypename || '',
            user: user || null
        }

        this.setState({
            ...nextState
        })
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state

        const changed1 = this.isChanged(state, nextState)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)

        if (changed1 || changed2) {
            return true
        }
        this.updateChildren(nextProps.children)
        return false
    },
    updateChildren: function(children) {
        const childrenComponent = this.refElements['children']
        childrenComponent.updateChildren(children)
    },
    historyHook: function() {
        const { history } = this.props
        const startLogoProgress = this.startLogoProgress
        this.beforeListenID = history.addBeforeListener(function() {
            startLogoProgress()
            return false
        })
    },
    startLogoProgress: function() {
        const logo = this.refElements['logo']
        if (logo) {
            logo.loading()
        }
    },
    stopLogoProgress: function() {
        const logo = this.refElements['logo']
        if (logo) {
            logo.stopLoading()
        }
    },
    removeHistoryHook: function() {
        const { history } = this.props
        history.removeBeforeListener(this.beforeListenID)
    },
    setMe: function(p = {}) {
        const { isMounted } = this.state
        if (isMounted) {
            this.setState({
                user: p.user
            })
        }
    },
    updateMe: async function(p = {}) {

        const { callback, logout } = p
        const { fetch, store, setResponse, deleteUser } = this.getHelpers()
        const userQuery = helpers.defaultUserQuery()
        const reqName = 'me'
        const setMe = this.setMe

        const userRequest = {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: function() {
                return JSON.stringify({
                    query: `{
                        ${reqName} {
                            ${userQuery}
                        }
                    }`
                })
            },
            credentials: 'include'
        }

        const pRbody = userRequest.body()
        const fresp = await fetch('/graphql', { ...userRequest, body: pRbody })
        let response = (fresp && fresp.json) ? await fresp.json() : null
        if (response.data) response = response.data

        if (response && response.me && !response.me.id || response && !response.me || logout) {

            store.dispatch(setResponse({
                name: reqName,
                value: null
            }))

            store.dispatch(deleteUser())

        } else {

            store.dispatch(setResponse({
                name: reqName,
                value: response[reqName]
            }))

        }

        if (logout) {
            store.dispatch(setResponse({
                name: 'user',
                value: null
            }))
        }

        const user = this.getUser()
        setMe({
            user: user
        })

        if (callback) callback({ response })

    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const deleteUser = (states && states.actions && states.actions.deleteUser) ? states.actions.deleteUser : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse, deleteUser }

    },
    getUser: function() {
        const { helpers, userConstructHelpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        let user = null
        if (state && state.response && state.response.me) {
            user = state.response.me
            if (userConstructHelpers) userConstructHelpers({ user })
        }
        return user
    },
    getPostType: function() {
        const { helpers, defaultSearchPostType = '' } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return (state && state.query) ? state.query.posttype : defaultSearchPostType
    },
    getFullPath: function() {
        const { helpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return state.url || '/'
    },
    getPostTypePath: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].path) ? posttypesData[posttype].path : ''
    },
    getPostTypeName: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].pluralname) ? posttypesData[posttype].pluralname : ''
    },
    getCurrentSearchPath: function() {
        const { searchRoute = '/search' } = this.props
        return (this.state.posttypepath && !this.state.isDefaultPostType) ? '/' + this.state.posttypepath + searchRoute : searchRoute
    },
    getSearchHint: function() {

        const { curlang = {}, searchData, taxonomiesData, defaultSearchPostType } = this.props

        const { store } = this.getHelpers()
        const historyHelpers = searchData.getHistoryHelpers()
        const searchDataObject = historyHelpers.getDataFromHistory({ store })
        let posttype = this.getPostType()
        if (!posttype && defaultSearchPostType) posttype = defaultSearchPostType

        function postTypeFilter({ key }) {
            let r = false
            if (!posttype) r = true
            if (posttype && taxonomiesData[key] && taxonomiesData[key].posttype) {
                taxonomiesData[key].posttype.map(function(pt) {
                    if (pt === posttype) r = true
                })
            }
            return r
        }

        const taxKeys = (taxonomiesData) ? Object.keys(taxonomiesData) : null
        let rtaxs = ''

        function getTaxNames({ cid, taxonomy }) {
            taxonomy.items.map(function(tax) {
                if (tax.id === cid) {
                    rtaxs = (rtaxs) ? rtaxs + ', ' + tax.name : tax.name
                }
                if (tax.items) {
                    getTaxNames({ cid, taxonomy: tax })
                }
            })
        }

        taxKeys.map(function(key) {
            const go = postTypeFilter({ key })
            if (go) {
                const taxonomy = taxonomiesData[key]
                const path = taxonomy.path || taxonomy.key || taxonomy.slug
                const currentSearchData = searchDataObject[path]

                if (currentSearchData && taxonomy.items && taxonomy.items.length) {
                    Object.keys(currentSearchData).map(function(cid) {
                        getTaxNames({ cid, taxonomy })
                    })
                }

            }
        })

        const state = (store && store.getState) ? store.getState() : null
        let userobj = null

        if (state && state.query && state.query.user) {
            const user = state.query.user
            if (state.response && state.response.user && state.response.user.id === user) {
                userobj = state.response.user
            }
            if (state.response && state.response.search && state.response.search.data && state.response.search.data.author && state.response.search.data.author.id === user) {
                userobj = state.response.search.data.author
            }
            if (state.response && state.response.archive && state.response.archive.data && state.response.archive.data.author && state.response.archive.data.author.id === user) {
                userobj = state.response.archive.data.author
            }
        }

        let searchHint = curlang.searchhint || 'Search'

        if (posttype && curlang[posttype.toLowerCase() + 'searchhint']) searchHint = curlang[posttype.toLowerCase() + 'searchhint']({
            user: userobj,
            taxs: rtaxs
        })
        if (!posttype && curlang['getsearchhint']) searchHint = curlang['getsearchhint']({
            user: userobj,
            taxs: rtaxs
        })
        return searchHint

    },
    getTaxonomyCount: function(props = {}) {

        const { taxonomiesData, taxonomiesPath, taxonomyName, posttype } = props
        let count = 0

        if (taxonomiesData && taxonomiesPath && taxonomyName && posttype &&
            taxonomiesData[taxonomiesPath] && taxonomiesData[taxonomiesPath].items) {

            const posstypes = taxonomiesData[taxonomiesPath].posttype

            posstypes.map(function(pt, pti) {
                if (pt === posttype) {
                    taxonomiesData[taxonomiesPath].items.map(function(taxonomy) {
                        if (taxonomy.name === taxonomyName && taxonomy.countbyposttype && taxonomy.countbyposttype.length) {
                            count = taxonomy.countbyposttype[pti] || 0
                        }
                    })
                }
            })

        }

        return count
    },

    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getHost: function() {
        const { helpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return (state.runtime && state.runtime.host) ? state.runtime.host : ''
    },
    render: function() {

        const {
            drawerwidth,
            menus,
            name,
            menuName = 'main',
            style,
            headerstyle,
            history,
            logostyle,
            logohref,
            logotext = 'Wapplr',
            logospan = '',
            onDrawerChange,
            pageRefs,
            children,
            muiTheme,
            menuRefs,
            getMenu,
            menuData,
            pageData,
            searchBarOnFocusHandle,
            searchBarOnCloseHandle,
            searchData,
            searchBarData,
            searchBarRefs,
            className,
            routes = {}
        } = this.props

        const { store } = this.getHelpers()

        const searchHint = this.getSearchHint()
        const currentSearchPath = this.getCurrentSearchPath()
        const setRef = this.setRef


        const fullPath = this.getFullPath()

        const user = this.state.user

        !!(
            fullPath && fullPath.match('player') ||
            fullPath && fullPath.match('image')
        )

        const notifications = (user && user.notificationsnumber) ? user.notificationsnumber : 0;

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(muiTheme)}>
                <Wapplrcontent style={style}>
                    <div className={style.app} ref={function(e) {
                        setRef('app', e)
                    }}>
                        <div className={style.header}>
                            <Wapplrmenu
                                ref={function(e) {
                                    menuRefs(menuName, e)
                                }}
                                data={menuData}
                                sendprops={{ user: this.state.user }}
                                style={headerstyle}
                                menu={menus[menuName]}
                                fixeddrawer={menus.fixeddrawer}
                                enablestickystyle={true}
                                history={history}
                                searchData={searchData}
                                type='drawer'
                                breakpoint={1024}
                                logo={(logostyle) ?
                                    <div>
                                        <div style={logostyle}></div>
                                        <h4>{logotext}</h4><span>{logospan}</span></div> :
                                    <div><h4>{logotext}</h4><span>{logospan}</span></div>}
                                logohref={logohref}
                                swapdirection={true}
                                drawerwidth={drawerwidth || 256}
                                forceDrawerOpen={true}
                                onDrawerChange={
                                    function(open, closeFunction) {
                                        setTimeout(function() {
                                            const el = document
                                            const event = document.createEvent('HTMLEvents')
                                            event.initEvent('resize', true, false)
                                            el.dispatchEvent(event)
                                        }, 600)
                                        return onDrawerChange(open, closeFunction, name, 'drawer1')
                                    }
                                }
                            >
                                <div className={style.headerCenter}>
                                    <div style={{ width: '100%' }}>
                                        <Wapplrsearch
                                            ref={function(e) {
                                                searchBarRefs(menuName, e)
                                            }}
                                            history={history}
                                            to={currentSearchPath}
                                            onFocusHandle={function() {
                                                if (searchBarOnFocusHandle && getMenu) return searchBarOnFocusHandle(getMenu(menuName))
                                            }}
                                            onClearHandle={function() {
                                                if (searchBarOnCloseHandle && getMenu) return searchBarOnCloseHandle(getMenu(menuName))
                                            }}
                                            style={se}
                                            hintText={searchHint}
                                            searchData={searchData}
                                            data={searchBarData}
                                            store={store}
                                            disableGoToSearchOnFocus={true}
                                        />
                                    </div>
                                    {(user) ?
                                        <Notification
                                            style={nfs}
                                            containerStyle={{ marginLeft: '15px', marginRight: '-15px' }}
                                            notifications={notifications}
                                            href={'/user' + routes.wapplruserappnotificationsslug + '/' + user.id}
                                            history={history}
                                        />
                                        : null
                                    }
                                </div>
                            </Wapplrmenu>
                        </div>
                        <Wapplrpage ref={function(e) {
                            pageRefs(name, e)
                        }}
                                    className={style.container + ' ' + style['page-container'] + ' ' + style['page-padding']}
                                    openClassName={style.pageContainerOpen}
                                    data={pageData}>
                            <div
                                className={(className) ? (style[className]) ? style[className] : className : style.row}>
                                <Children
                                    ref={function(e) {
                                        setRef('children', e)
                                    }}
                                    children={children}
                                />
                            </div>
                        </Wapplrpage>
                        <Footer
                            {...this.props}
                            style={fs}
                            menustyle={fsm}
                            footercopyright={
                                <div>
                                    <div style={{height:"16px", cursor: "pointer"}}>
                                        <span style={{verticalAlign: "baseline"}}>
                                            <div style={{display:"inline-block", height:"72%"}}>
                                                <FC />
                                            </div>
                                        </span>
                                        <span style={{marginLeft:"3px"}}>{`© ${new Date().getFullYear()}`}</span>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </Wapplrcontent>
            </MuiThemeProvider>
        )
    }
})
