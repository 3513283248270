/*Styles*/
import s from '../../customstyle/default/index.css'
import msdef from '../../../themes/components/Wapplrmenu.css'

export default function(c) {

    /*Styles*/
    const config = c.config || {}
    const history = c.history

    /*Styles*/
    const style = c.style || s
    const headerstyle = msdef

    /*Header logo*/

    const logostyle = null //{backgroundImage:"url('"+logourl+"')", width:"50px"};
    const logohref = '/'

    /*Start logo*/

    const startlogostyle = null //{backgroundImage:"url('"+start_logourl+"')"};

    /*Languages*/
    const languages = config.languages || {}
    const locale = config.currentlang || 'en_us'
    const curlang = languages[locale] || {}
    const routes = config.routes || {}

    const searchRoute = routes.menusearchslug || '/search'
    const logotext = config.logotext || ''
    const logospan = config.logospan || ''
    const defaultSearchPostType = config.defaultsearchposttype || 'Post'
    const defaultPostSearchObjectName = config.defaultpostsearchobjectname || 'id'
    const defaultPostSlug = config.defaultpostslug || 'post'
    const maincolor = config.maincolor || '#ffffff'
    const localstorename = config.localstorename || 'wapplr'

    const mb = 1024 * 1024
    const {
        maxuploadfilesize = mb * 2,
        enableuploadtypes = ['png', 'jpeg']
    } = config

    const {
        getthumb = function({ url }) {
            return url
        }
    } = config

    const termspostid = config.termspostid
    const cookiepostid = config.cookiepostid
    const privancyid = config.privancyid
    const contactpostid = config.contactpostid

    const googleTrackingId = config.googleTrackingId

    const {
        siteurl,
        sitename,
        siteimage,
        getthumbstyle,
        staticscript,
        staticserver
    } = config

    return {
        history,
        style,
        headerstyle,
        logostyle,
        logohref,
        startlogostyle,
        logotext,
        logospan,
        curlang,
        searchRoute,
        defaultSearchPostType,
        defaultPostSearchObjectName,
        defaultPostSlug,
        maincolor,
        localstorename,
        routes,
        maxuploadfilesize,
        enableuploadtypes,
        getthumb,
        termspostid,
        cookiepostid,
        privancyid,
        contactpostid,
        googleTrackingId,
        siteurl,
        sitename,
        siteimage,
        getthumbstyle,
        staticscript,
        staticserver,
    }
}


