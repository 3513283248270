import React from 'react'
import PropTypes from 'prop-types-legacy'
import createReactClass from 'create-react-class'


const ContextType = {
    // Enables critical path CSS rendering
    // https://github.com/kriasoft/isomorphic-style-loader
    insertCss: PropTypes.func.isRequired,
    // Integrate Redux
    // http://redux.js.org/docs/basics/UsageWithReact.html
    store: PropTypes.shape({
        subscribe: PropTypes.func,
        dispatch: PropTypes.func,
        getState: PropTypes.func
    })
}

export default createReactClass({
    propTypes: {
        context: PropTypes.shape(ContextType).isRequired,
        children: PropTypes.element.isRequired
    },
    childContextTypes: ContextType,
    getChildContext() {
        return this.props.context
    },
    render() {
        return React.Children.only(this.props.children)
    }
})
