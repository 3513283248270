import posttypesSetrequired from '../../posttypes/setrequired'

const statuses = {
    'approved': 100,
    'required data & email validated': 70,
    'required data & !email validated': 60,
    '!required data & email validated': 50,
    'registrated': 40,
    'deleted itself': 30,
    'banned': 20
}

export const userstatuses = statuses
export default statuses

export const requireddata = {
    name: {
        first: 1,
        last: 1
    }
}

export function setrequiredByUserType({ user, requireddata }) {
    return posttypesSetrequired.setrequired({ user, requireddata, posttype: 'User' })
}

export function getDefaultUserStatusForUserCollection() {
    return statuses.registrated
}

export function getMinUserStatusWhenAllAccess() {
    const currentminstatus = statuses['required data & email validated']
    return posttypesSetrequired.minpoststatus({ statuses, currentminstatus, posttype: 'User' })
}

export function getOptionsForUserCollection() {
    const options = []
    Object.keys(statuses).map(function(key) {
        options.push({ label: key, value: statuses[key] })
    })
    return options
}

export function getUserStatusData({ user, curlang = {} }) {
    if (user && user.id) {

        const {
            userstatusregistrated = 'registarted',
            userstatusdeleteditself = 'deleted by user',
            userstatusbanned = 'user banned permanently',
            userstatusrequireddata1 = '!required data & email validated',
            userstatusrequireddata2 = 'required data & !email validated',
            userstatusrequireddata3 = 'required data are available',
            userstatusapproves = 'user approved'
        } = curlang



        const deleteorrestorepoint = userstatuses['registrated']
        const currentstatus = user.status || deleteorrestorepoint
        const deleteorrestore = (currentstatus < deleteorrestorepoint) ? 'restore' : 'delete'
        const deletebutton = (currentstatus < deleteorrestorepoint) ? null : 'delete'

        const approveenable = (currentstatus > userstatuses['required data & !email validated'] - 1 && currentstatus < userstatuses['approved'])
        const banenable = (currentstatus > userstatuses['banned'])

        let statusname = userstatusregistrated

        Object.keys(userstatuses).map(function(psk) {
            if (userstatuses[psk] === currentstatus) statusname = psk
        })

        if (statusname === 'deleted itself') {
            statusname = userstatusdeleteditself
        }

        if (statusname === 'banned') {
            statusname = userstatusbanned
        }

        if (statusname === '!required data & email validated') {
            statusname = userstatusrequireddata1
        }

        if (statusname === 'required data & !email validated') {
            statusname = userstatusrequireddata2
        }

        if (statusname === 'required data & email validated') {
            statusname = userstatusrequireddata3
        }

        if (statusname === 'approved') {
            statusname = userstatusapproves
        }

        return {
            statusname: statusname,
            userstatus: user.status,
            deleteorrestore,
            deletebutton: deletebutton,
            approveenable: approveenable,
            banenable: banenable
        }

    }
    return { statusname: 'not found', userstatus: userstatuses['banned'] - 1 }
}
