import posttypesPlugins from '../posttypes'

export default {

    description: 'FlatChooser - Flat presentation application',
    sitenametitle: ' - Flat presentation application',
    welcometitle: 'Present your flat',
    welcometitle1: 'An app created for architect offices, visual designers and contractors to present and sell buildings and flats easier.',

    menuhomename: 'Home',
    menusearchname: 'Search',
    menuhometitle: 'Home',
    menusearchtitle: 'Search',
    menuarchivetitle: 'Archive',
    menuadmin: 'Admin',

    menuallactive: 'All active',
    menushowonpage: 'Which appear in the list',
    menushouldactive: 'Approval is required',
    menumissingdata: 'Missing data',
    menudeleted: 'Deleted',
    menubanned: 'Banned',
    menuauthormissingdata: 'Missing author data',
    menudeletedauthor: 'Deleted author',

    menuterms: 'BETINGELSER',
    menuprivancy: 'PERSONVERN',
    menucookie: 'COOKIES',
    menucontact: 'KONTAKTINFO',
    menufacebook: 'FACEBOOK',
    menuinstagram: 'INSTAGRAM',

    yourprofile: 'YOUR PROFILE',
    loginbutton: 'LOGIN',
    registrationbutton: 'SIGN UP',
    playersbutton: 'PLAYERS',

    searchhint: 'Search',
    getsearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Search'
        if (username && !taxs) r = 'Search in ' + username + '\'s posts'
        if (!username && taxs) r = 'Search in ' + taxs + ' caregory'
        if (username && taxs) r = 'Search in ' + username + '\'s posts, and in ' + taxs + ' category'
        return r
    },
    activesearchsubheadertext: 'Search: ',
    notfoundsearchsubheadertext: 'No matches',
    emptysearchsubheadertext: 'Add a text',
    notresultstext: 'No results',
    uploadtext: 'Upload file',
    notresultstextgallery: 'No image',

    mincharerror: function(a) {
        return 'Minimum ' + a + ' character'
    },
    maxcharerror: function(a) {
        return 'Maximum ' + a + ' character'
    },

    minarrerror: function(a) {
        return 'Minimum ' + a
    },
    maxarrerror: function(a) {
        return 'Maximum ' + a
    },

    minselectnumber: function(a, b = '', c = '') {
        if (!a) return ''
        if (c && a === 1) return 'Please select minimum ' + a + ' ' + c
        return 'Please select minimum ' + a + ' ' + b
    },

    deletepostsubtitle: 'Delete post',
    deletepostquestion: 'Are you sure want to delete your post?',
    savepostdefaultfail: 'Sorry, there was an issue save your post, please try again',
    savepostsuccessmessage: 'Your post has been saved',
    deletepostdefaultfail: 'Sorry, there was an issue delete your post, please try again',
    deletepostsuccessmessage: 'Your post has been deleted',
    accessfail: 'You have not access to this function, please contact to system provider',

    restorepostsubtitle: 'Restore',
    restorepostquestion: 'Are you sure want to restore your post?',

    duplicatepostdefaultfail: 'Sorry, there was an issue duplicate your post, please try again',
    duplicatepostsuccessmessage: 'Your post has been duplicated',

    userpostssubtitle: 'Posts',
    userpoststrashsubtitle: 'Deleted posts',

    duplicatepostsubtitle: 'Duplicate',
    duplicatepostquestion: 'Are you sure want to duplicate your post?',

    submittext: 'Submit',
    canceltext: 'Cancel',
    savepost: 'Save',
    saveandrestore: 'Save and restore',
    selectimage: 'Select image',
    selectimages: 'Select images',

    next: 'Next',
    closetext: 'Close',

    stepsetbasicdata: 'Set basic data',
    stepchooseacover: 'Choose a cover image',
    stepaddsomeimage: 'Add some image',
    stepcategory: 'Select category',

    duplicatedtext: '(duplicated)',
    activeSearchSubHeaderText: 'Search: ',
    notFoundSearchSubHeaderText: 'No matches',
    emptySearchSubHeaderText: 'Add a text',
    clearFiltersText: 'clear filters',
    updateFiltersText: 'update filters',
    showFiltersText: 'show filters',
    showPostsText: 'show posts',
    getResultsText: function({ skip, currentpage, limit, count, length }) {
        let s = Number(skip)
        const l = (currentpage * limit < count) ? currentpage * limit : count
        const r = (count > 1) ? 'results' : 'result'
        let t = ''
        if (s > 0) {
            s = s + 1
            t = 'shown ' + s + '-' + l + ' of ' + count + ' ' + r
        } else {
            t = 'shown ' + length + ' of ' + count + ' ' + r
        }
        return t
    },

    poststatuscreated: 'published',
    poststatusdeleteditself: 'deleted by user',
    poststatusbanned: 'post banned permanently',
    poststatusrequireddata: 'required data are available',
    poststatusapproves: 'post approved',

    approvepostsubtitle: 'Approve',
    approvepostquestion: 'Are you sure want to approve this post?',
    approvepostdefaultfail: 'Sorry, there was an issue approve this post, please try again',
    approvepostsuccessmessage: 'This post has been approved',

    banpostsubtitle: 'Ban',
    banpostquestion: 'Are you sure want to ban this post?',
    banpostdefaultfail: 'Sorry, there was an issue ban this post, please try again',
    banpostsuccessmessage: 'This post has been banned',

    approvetext: 'Approve',
    bantext: 'Delete permanetly',

    wordcategory: 'Category',

    approvecookietext: 'We use cookies and other technologies to ensure everyone who uses our services has the best possible experience.',
    cookieapprovetext: 'I got it',
    moretext: 'more >>',
    loadmoredatatext: 'Load more data',

    publisheddate: 'Published date',
    status: 'Status',
    viewprofile: 'view profile',

    notfoundtext: 'Sorry, this page isn\'t available',
    notfounddesc: 'The link you followed may be broken, or the page may have been removed',
    loggedoutdesc: 'Please try signing in to your account because there are pages that only can see those who have permission for this.',

    gotohomepage: 'Go to homepage',
    gotoprofile: 'Go to your dashboard',
    gotologin: 'Login',
    gotosignup: 'Signup',
    gotobacktoprev: 'Go back to the previous page',

    ...posttypesPlugins.languages({ lang: 'no_no' })

}
