import React from 'react'

import { getEditdata, getTemplates, getUserpageTemplates } from './internal'

import {
    configroutes as userConfigRoutes,
    contents as userContents,
    editdata as userEditdata,
    fetchs as userFetchs,
    getmenus as userGetmenus,
    initmodules as userInitmodules,
    languages as userLanguages,
    requests as userRequests,
    routes as userRoutes,
    templates as userTemplates,
    userpageroutes as userUserpageroutes,
    userpagetemplates as userUserpagetemplates
} from './user'

import {
    configroutes as imageConfigRoutes,
    contents as imageContents,
    editdata as imageEditdata,
    fetchs as imageFetchs,
    languages as imageLanguages,
    requests as imageRequests,
    routes as imageRoutes,
    templates as imageTemplates,
    userpageroutes as imageUserpageroutes,
    userpagetemplates as imageUserpagetemplates
} from './image'

import postConfig from './post/config'

import {
    configroutes as postConfigRoutes,
    contents as postContents,
    editdata as postEditdata,
    fetchs as postFetchs,
    languages as postLanguages,
    requests as postRequests,
    routes as postRoutes,
    templates as postTemplates,
    userpageroutes as postUserpageroutes,
    userpagetemplates as postUserpagetemplates
} from './post'

import {
    configroutes as notificationConfigRoutes,
    contents as notificationContents,
    editdata as notificationEditdata,
    fetchs as notificationFetchs,
    languages as notificationLanguages,
    requests as notificationRequests,
    routes as notificationRoutes,
    templates as notificationTemplates,
    userpageroutes as notificationUserpageroutes,
    userpagetemplates as notificationUserpagetemplates
} from './notification'

import {
    configroutes as playerConfigRoutes,
    contents as playerContents,
    editdata as playerEditdata,
    fetchs as playerFetchs,
    initmodules as playerInitmodules,
    languages as playerLanguages,
    requests as playerRequests,
    routes as playerRoutes,
    templates as playerTemplates,
    userpageroutes as playerUserpageroutes,
    userpagetemplates as playerUserpagetemplates
} from './player'

import playerConfig from './player/config'

const postName = postConfig.name
const postNameLower = postName.toLowerCase()
const postPath = postConfig.path

const playerName = playerConfig.name
const playerNameLower = playerName.toLowerCase()
const playerPath = playerConfig.path

function allLanguages({ lang }) {
    return {
        ...userLanguages[lang],
        ...imageLanguages[lang],
        ...postLanguages[lang],
        ...playerLanguages[lang],
        ...notificationLanguages[lang]
    }
}

function allUserpageroutes(p) {
    return [
        ...userUserpageroutes({ ...p, name: 'users', type: 'User' }),
        ...imageUserpageroutes({ ...p, name: 'images', type: 'Image' }),
        ...postUserpageroutes({ ...p, name: postPath, type: postName }),
        ...playerUserpageroutes({ ...p, name: playerPath, type: playerName }),
        ...notificationUserpageroutes({ ...p, name: 'notifications', type: 'Notification' })
    ]
}

function allRequests(p) {
    return {
        ...userRequests({ ...p, name: 'user' }),
        ...imageRequests({ ...p, name: 'image' }),
        ...postRequests({ ...p, name: postNameLower }),
        ...playerRequests({ ...p, name: playerNameLower }),
        ...notificationRequests({ ...p, name: 'notification' })
    }
}

function allFetchs(p) {
    return {
        ...userFetchs({ ...p, name: 'user' }),
        ...imageFetchs({ ...p, name: 'image' }),
        ...postFetchs({ ...p, name: postNameLower }),
        ...playerFetchs({ ...p, name: playerNameLower }),
        ...notificationFetchs({ ...p, name: 'notification' })
    }
}

function allRoutes(p) {
    return [
        ...userRoutes({ ...p, name: 'user' }),
        ...imageRoutes({ ...p, name: 'image' }),
        ...postRoutes({ ...p, name: postNameLower }),
        ...playerRoutes({ ...p, name: playerNameLower }),
        ...notificationRoutes({ ...p, name: 'notification' })
    ]
}

function alluserpageTemplates(p) {
    return {
        ...getUserpageTemplates({ ...p, posttypeTemplates: userUserpagetemplates, name: 'users' }),
        ...getUserpageTemplates({ ...p, posttypeTemplates: imageUserpagetemplates, name: 'images' }),
        ...getUserpageTemplates({ ...p, posttypeTemplates: postUserpagetemplates, name: postPath }),
        ...getUserpageTemplates({ ...p, posttypeTemplates: playerUserpagetemplates, name: playerPath }),
        ...getUserpageTemplates({ ...p, posttypeTemplates: notificationUserpagetemplates, name: 'notifications' })
    }
}

function allTemplates(p) {
    return {
        ...getTemplates({ ...p, posttypeTemplates: userTemplates, name: 'user' }),
        ...getTemplates({ ...p, posttypeTemplates: imageTemplates, name: 'image' }),
        ...getTemplates({ ...p, posttypeTemplates: postTemplates, name: postNameLower }),
        ...getTemplates({ ...p, posttypeTemplates: playerTemplates, name: playerNameLower }),
        ...getTemplates({ ...p, posttypeTemplates: notificationTemplates, name: 'notification' })
    }
}

function allEditdata(p) {
    return {
        ...getEditdata({ p, posttypeEditdata: userEditdata, name: 'user' }),
        ...getEditdata({ p, posttypeEditdata: imageEditdata, name: 'image' }),
        ...getEditdata({ p, posttypeEditdata: postEditdata, name: postNameLower }),
        ...getEditdata({ p, posttypeEditdata: playerEditdata, name: playerNameLower }),
        ...getEditdata({ p, posttypeEditdata: notificationEditdata, name: 'notification' })
    }
}

function allMenus(p) {
    return {
        ...userGetmenus(p)
    }
}

function allInitmodules(p) {
    return {
        ...userInitmodules(p),
        ...playerInitmodules(p)
    }
}

function allContents(p) {
    return {
        ...userContents({ ...p, posttypeTemplates: userTemplates, name: 'user' }),
        ...imageContents({ ...p, posttypeTemplates: imageTemplates, name: 'image' }),
        ...postContents({ ...p, posttypeTemplates: postTemplates, name: postNameLower }),
        ...playerContents({ ...p, posttypeTemplates: playerTemplates, name: playerNameLower }),
        ...notificationContents({ ...p, posttypeTemplates: notificationTemplates, name: 'notification' })
    }
}

export default {
    configroutes: { ...userConfigRoutes, ...imageConfigRoutes, ...playerConfigRoutes, ...postConfigRoutes, ...notificationConfigRoutes },
    templates: allTemplates,
    editdata: allEditdata,
    getmenus: allMenus,
    initmodules: allInitmodules,
    contents: allContents,
    userpagetemplates: alluserpageTemplates,
    routes: allRoutes,
    fetchs: allFetchs,
    requests: allRequests,
    userpageroutes: allUserpageroutes,
    languages: allLanguages
}
