import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents from '../../components'
import TemplateDefault from '../templates/template_default'

import fs from '../../themes/components/Wapplrfeed.css'
import avs from '../../themes/components/Wapplravatar.css'
import ps from '../../themes/components/Wapplrpost.css'

import NoMatches from '../components/NoMatches'
import nms from '../components/NoMatches/NoMatches.css'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'

import MetaData from '../components/MetaData'
import mds from '../components/MetaData/MetaData.css'
import TimeIcon from 'material-ui/svg-icons/device/access-time'
import StatusIcon from 'material-ui/svg-icons/editor/vertical-align-center'

import { getPostStatusData } from '../server/poststatus/statuses'

import AdminPost from '../components/Admin/Post.js'
import adminpostfunctions from '../components/Admin/postfunctions.js'

import Wapplrdialog from '../components/Wapplrdialog'
import Wapplrsnackbar from '../components/Wapplrsnackbar'
import fetchs from '../services/fetchs'

const Wapplrfeed = wapplrcomponents.feed
const Pagination = wapplrcomponents.pagination
const SearchFeedHeader = wapplrcomponents.searchfeedheader

const TaxonomiesFeeds = createReactClass({
    getData: function({ taxonomiesData, key, searchDataObject, historyHelpers }) {

        const taxonomy = taxonomiesData[key]
        const path = taxonomy.path || taxonomy.key || taxonomy.slug

        const currentSearchData = searchDataObject[path]
        let missingData = null

        if (currentSearchData) {
            const missing = {}
            let wasmiss = false
            Object.keys(currentSearchData).forEach(function(k) {
                let found = false
                taxonomy.items.map(function(m) {
                    if (m.id === k || m.slug === k) found = true
                })
                if (!found) {
                    missing[k] = true
                    wasmiss = true
                }
            })
            missingData = (wasmiss) ? historyHelpers.getTaxonomyByIds(taxonomy.items, missing) : null
        }

        const data = (missingData) ? [...function() {
            const r = []
            Object.keys(missingData).map(function(k) {
                if (missingData[k].id) r.push(missingData[k])
            })
            return r
        }(), ...taxonomy.items] : taxonomy.items

        return { data, taxonomy }

    },
    postTypeFilter({ key }) {
        const { taxonomiesData, posttype } = this.props
        let r = false
        if (!posttype) r = true
        if (posttype && taxonomiesData[key] && taxonomiesData[key].posttype) {
            taxonomiesData[key].posttype.map(function(pt) {
                if (pt === posttype) r = true
            })
        }
        return r
    },
    render: function() {

        const {
            taxonomiesData,
            searchData,
            searchDataObject,
            historyHelpers,
            history,
            searchRoute = '/search',
            feedDatas,
            feedRefs,
            onSelect
        } = this.props
        const getData = this.getData
        const taxKeys = (taxonomiesData) ? Object.keys(taxonomiesData) : null
        const postTypeFilter = this.postTypeFilter

        return (
            <div>
                {(taxKeys && taxKeys.length) ?
                    taxKeys.map(function(key, index) {
                        const go = postTypeFilter({ key })
                        if (go) {
                            const { data, taxonomy } = getData({
                                taxonomiesData,
                                key,
                                searchDataObject,
                                historyHelpers
                            })
                            return <Wapplrfeed
                                key={index}
                                ref={function(e) {
                                    feedRefs('search' + key, e)
                                }}
                                feedData={feedDatas['search' + key]}
                                history={history}
                                style={fs}
                                data={data}
                                selectable={true}
                                onSelect={onSelect}
                                enableLocation={false}
                                to={searchRoute}
                                searchData={searchData}
                                searchObjectName={taxonomy.path}
                            />
                        }
                        return null
                    }) : null
                }
            </div>
        )
    }
})

const Search = createReactClass({
    getInitialState: function() {

        this.paginationProcessing = false
        this.refElements = {}
        const { response } = this.props
        if (response && response.search && response.search.posts) response.search.posts = this.usersToPostsData({ posts: response.search.posts })
        const posts = (response && response.search && response.search.posts) ? response.search.posts : null
        const postsdata = (response && response.search && response.search.data) ? response.search.data : null
        const author = this.getAuthor({ postsdata })

        const posttype = this.getPostType()
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath

        return {
            isMounted: false,
            posts: posts || null,
            showfilterlist: false,
            author: author || null,
            postsdata: postsdata || null,
            posttype: posttype || '',
            posttypepath: posttypepath || '',
            posttypename: posttypename || ''
        }
    },
    showFilters: function(e) {
        if (e) e.preventDefault()
        if ('undefined' !== typeof window) window.scrollTo(0, 0)
        this.setState({ showfilterlist: !this.state.showfilterlist })
    },
    querySearchText: function() {
        const { searchData } = this.props
        const { store } = this.getHelpers()
        const historyHelpers = searchData.getHistoryHelpers()
        const h = historyHelpers.getDataFromHistory({ store })
        return historyHelpers.objectToString(h)
    },
    addToPosts: async function({ response }) {
        if (this.state.isMounted) {

            const posts = (this.state.posts && this.state.posts.length) ? [...this.state.posts] : []
            const postsdata = (this.state.postsdata) ? { ...this.state.postsdata } : {}

            if (response && response.search && response.search.posts) response.search.posts = this.usersToPostsData({ posts: response.search.posts })
            const newposts = (response && response.search && response.search.posts) ? response.search.posts : null
            const newpostsdata = (response && response.search && response.search.data) ? response.search.data : {}

            if (newposts && newposts.length) {
                posts.push(...newposts)

                const pagi = (this.refElements['pagination']) ? this.refElements['pagination'] : {
                    setProcessing: async function() {
                        return true
                    }
                }
                await pagi.setProcessing(false)

                this.paginationProcessing = false

                await this.setState({
                    posts: posts,
                    postsdata: { ...newpostsdata, skip: postsdata.skip, skippedpages: postsdata.skippedpages }
                })
            }
        }
    },
    getAuthor: function({ postsdata }) {
        if (postsdata && postsdata.author) return postsdata.author
        return null
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },

    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },

    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    setPosts: function(a) {
        const p = this.usersToPostsData({ posts: a.posts })
        this.setState({ posts: p })
    },
    componentWillMount: function() {

        const { curlang, history } = this.props
        const posttype = this.getPostType()

        const getHelpers = this.getHelpers
        const dialogClose = this.dialogClose
        const dialogOpen = this.dialogOpen
        const snack = this.snack
        const state = this.state
        const setPosts = this.setPosts

        if (posttype) {
            adminpostfunctions({
                posttype: posttype.toLowerCase(),
                t: this,
                curlang,
                fetchs,
                history,
                getHelpers,
                dialogClose,
                dialogOpen,
                snack,
                state,
                setState: setPosts
            })
        }

    },
    componentDidMount: function() {

        const { response, searchData } = this.props

        this.historyHelpers = searchData.getHistoryHelpers()

        if (response && response.search && response.search.posts) response.search.posts = this.usersToPostsData({ posts: response.search.posts })
        let posts = (response && response.search && response.search.posts) ? response.search.posts : null
        let postsdata = (response && response.search && response.search.data) ? response.search.data : null

        const { helpers } = this.props
        const gstate = (helpers && helpers.store) ? helpers.store.getState() : null
        const queryString = this.querySearchText()

        if (gstate.archivePosts && gstate.archivePosts[queryString]) {
            posts = gstate.archivePosts[queryString].posts
            postsdata = gstate.archivePosts[queryString].postsdata
        }

        const posttype = this.getPostType()
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath

        const author = this.getAuthor({ postsdata })

        this.state.isMounted = true
        this.setState({
            isMounted: true,
            posts: posts,
            author: author,
            postsdata: postsdata,
            posttype: posttype || '',
            posttypepath: posttypepath || '',
            posttypename: posttypename || ''
        })
    },
    componentWillUnmount: function() {
        this.state.isMounted = false
        this.setState({ isMounted: false })
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },


    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    setRef: function(a, e) {
        this.refElements[a] = e
    },

    historyPush: function(page) {
        if (page) {
            const { history } = this.props
            const { store } = this.getHelpers()
            const historyHelpers = this.historyHelpers
            const h = historyHelpers.getDataFromHistory({ store })
            h.page = page
            const searchText = historyHelpers.objectToString(h)
            const hash = (history.location.hash) ? history.location.hash.slice(1) : ''
            historyHelpers.historyPush({ search: searchText, hash }, true)
        }
    },
    paginationOnClick: async function(e) {
        if (e) {
            e.preventDefault()
        }

        if (this.state.isMounted && !this.paginationProcessing) {

            let pagi = (this.refElements['pagination']) ? this.refElements['pagination'] : {
                setProcessing: async function() {
                    return true
                }
            }
            await pagi.setProcessing(true)

            this.paginationProcessing = true

            const scrollDiv = ('undefined' !== typeof document) ? (document.scrollingElement) ? document.scrollingElement : document.body : null
            const { getFetch, helpers } = this.props
            const state = (helpers && helpers.store) ? helpers.store.getState() : null
            state.query.page = (state.query.page) ? Number(state.query.page) + 1 : 2
            const tempHref = window.location.href

            const response = await getFetch({ state })

            if (this.state.isMounted && tempHref === window.location.href) {

                const wy = (scrollDiv && scrollDiv.scrollTop) ? scrollDiv.scrollTop : 0

                await this.addToPosts({ response: response })

                const store = helpers.store
                const states = (helpers.states) ? helpers.states : null
                const setArchivePostsAndReset = (states && states.actions && states.actions.setArchivePostsAndReset) ? states.actions.setArchivePostsAndReset : null
                const queryString = this.querySearchText()

                const { posts, postsdata } = this.state

                store.dispatch(setArchivePostsAndReset({
                    name: queryString,
                    value: {
                        posts,
                        postsdata
                    }
                }))

                if (this.state.isMounted && tempHref === window.location.href) {
                    this.historyPush(state.query.page)
                    if (wy) scrollDiv.scrollTop = wy
                }

            } else {

                pagi = (this.refElements['pagination']) ? this.refElements['pagination'] : {
                    setProcessing: async function() {
                        return true
                    }
                }
                await pagi.setProcessing(false)

                this.paginationProcessing = false

            }
        }
    },
    getStoredQuery: function() {
        const { helpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return (state) ? state.query : {}
    },
    showRenew: function() {
        const refElements = this.refElements
        if (refElements['searchheader'] && refElements['searchheader'].showRenew) {
            refElements['searchheader'].showRenew()
        }
    },
    getPostType: function() {
        const { helpers, defaultSearchPostType = '' } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return (state && state.query) ? state.query.posttype : defaultSearchPostType
    },
    getPostTypePath: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].path) ? posttypesData[posttype].path : ''
    },
    getPostTypeName: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].pluralname) ? posttypesData[posttype].pluralname : ''
    },

    publicicon: function({ post }) {
        const share = post.share
        const author = this.state && this.state.author
        if (author) {
            if (share === 'public' || !share) {
                return <PublicIcon />
            }
            if (share === 'private') {
                return <PrivateIcon />
            }
        }
        return null
    },

    getPostStatus: function({ post }) {
        const { curlang } = this.props
        return getPostStatusData({ post, curlang })
    },
    getUserName: function({ user }) {
        const firstname = (user && user.name && user.name.first) ? user.name.first : ''
        const lastname = (user && user.name && user.name.last) ? user.name.last : ''
        return (firstname && lastname) ? firstname + ' ' + lastname : (firstname) ? firstname : lastname
    },
    usersToPostsData: function({ posts }) {

        const { defaultthumbnail = '/thumb.jpg' } = this.props

        const posttype = this.getPostType()
        if (posttype === 'User') {
            const r = []
            const getUserName = this.getUserName
            posts.map(function(user) {
                if (!user.wasUserDataToPost) {
                    const avatar = (user.avatar) ? user.avatar : defaultthumbnail
                    r.push(
                        {
                            id: user.id,
                            author: user,
                            title: getUserName({ user }),
                            status: user.status,
                            cover: avatar,
                            registratedDate: user.registratedDate,
                            wasUserDataToPost: true
                        }
                    )
                } else {
                    r.push(user)
                }
            })
            return r
        } else {
            return posts
        }
    },

    contentcomponent: function({ post}) {
        if (post) {

            const { curlang = {} } = this.props
            const posttype = this.getPostType()
            const getStatus = (posttype === 'User') ? this.getUserStatus : this.getPostStatus
            const { statusname, approveenable, banenable } = getStatus({ post })
            const me = this.getMe()
            const author = (post && post.author) ? post.author : null
            const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)
            const isAdmin = (me && me.isAdmin || me && me.isEditor)

            const approvefunction = (posttype) ? this['approvefunction' + posttype.toLowerCase()] : function() {
            }
            const banfunction = (posttype) ? this['banfunction' + posttype.toLowerCase()] : function() {
            }

            const data = [
                { icon: <TimeIcon />, name: curlang.publisheddate, value: 'publishedDate' },
                { icon: <TimeIcon />, name: curlang.metaregistrateddate, value: 'registratedDate' },
                {
                    icon: <StatusIcon />, name: curlang.status, value: function(props) {

                        const { post = {}, style } = props
                        if (canEdit) {
                            return (
                                <div>
                                    <div>
                                        {statusname}
                                    </div>
                                    {(isAdmin && approveenable || isAdmin && banenable) ?
                                        <div className={style.innerButtons}>
                                            <AdminPost
                                                curlang={curlang}
                                                approveenable={approveenable}
                                                banenable={banenable}
                                                approvefunction={approvefunction}
                                                banfunction={banfunction}
                                                post={post}
                                            />
                                        </div> : null
                                    }
                                </div>
                            )

                        }
                        return null
                    }
                }
            ]

            return (
                <MetaData
                    post={post}
                    style={mds}
                    data={data}
                />
            )
        }
        return null
    },

    render: function() {

        const {
            style,
            searchData,
            history,
            feedDatas,
            feedRefs,
            postSlug,
            postSearchObjectName,
            curlang,
            getthumb,
            getthumbstyle
        } = this.props

        const { store } = this.getHelpers()
        const historyHelpers = searchData.getHistoryHelpers()
        const searchDataObject = historyHelpers.getDataFromHistory({ store })
        const state = this.state

        const storedQuery = this.getStoredQuery()
        const showFilters = this.showFilters
        const setRef = this.setRef
        const showposts = !!(state.posts && state.posts.length && storedQuery.text && !state.showfilterlist)
        const nomatches = !(state.posts && state.posts.length) && storedQuery.text && !state.showfilterlist
        const showfilterlist = !!(state.posts && state.posts.length && storedQuery.text && state.showfilterlist)
        const {
            notresultstext,
            loadmoredatatext
        } = curlang


        const publicicon = this.publicicon
        const contentcomponent = this.contentcomponent

        const isBot = this.getGlobalState().isBot

        if (isBot && feedDatas['search_posts']) {
            feedDatas['search_posts'].setDataCurrent('showContent', true)
        }

        return (
            <div>
                <SearchFeedHeader
                    style={fs}
                    setRef={setRef}
                    className={style['page-subheader']}
                    enablestickystyle={true}
                    history={history}
                    author={state.author}
                    avatarstyle={avs}
                    historyHelpers={historyHelpers}
                    posts={state.posts}
                    data={state.postsdata}
                    showfilterlist={showposts}
                    showposts={showfilterlist}
                    onShowFilterList={showFilters}
                    getthumb={getthumb}
                    curlang={curlang}
                    disableSearchQueryDatas={['text', 'page', 'limit', 'user', 'secuser', 'posttype', 'sgt', 'slt', 'asgt', 'aslt', 'so', 'sh']}
                    store={store}
                />
                {(showposts) ?
                    <div>
                        <Wapplrfeed
                            ref={function(e) {
                                feedRefs('search_posts', e)
                            }}
                            feedData={feedDatas['search_posts']}
                            history={history}
                            style={fs}
                            data={state.posts}
                            getTitle={function({ post }) {
                                return post.title || post.name
                            }}
                            showauthor={false}
                            enableUserLink={false}
                            avatarstyle={avs}
                            enableLocation={true}
                            enableTitleHref={true}
                            enableThumbHref={true}
                            enablePermalink={true}
                            to={(state.posttype) ? '/' + state.posttype.toLowerCase() : postSlug}
                            selectable={false}
                            searchData={searchData}
                            searchObjectName={postSearchObjectName}
                            publicicon={publicicon}
                            contentcomponent={contentcomponent}
                            getthumb={getthumb}
                            getthumbstyle={getthumbstyle}
                            thumbheight={{ height: '0', paddingBottom: '56%' }}
                            isMounted={true}
                            showContent={isBot}
                            getavatarclassname={function({ className, avatarProps, containerWidth, rootClassName }) {
                                const { style, state } = avatarProps
                                let rClassName = style['avatarbox_' + state.type]
                                if (className) rClassName = rClassName + ' ' + className
                                if (containerWidth > 480 && rootClassName === 'bp2') rClassName = rClassName + ' ' + style['giant']
                                return rClassName
                            }}
                        />
                        {(state.isMounted) ?
                            <Pagination
                                onClick={this.paginationOnClick}
                                ref={function(e) {
                                    setRef('pagination', e)
                                }}
                                infiniteScroll={true}
                                style={fs}
                                className={style['page-subheader']}
                                history={history}
                                historyHelpers={historyHelpers}
                                data={state.postsdata}
                                loadMoreDataText={loadmoredatatext}
                                store={store}
                            /> : null
                        }
                    </div>
                    :
                    <TaxonomiesFeeds
                        {...this.props}
                        onSelect={this.showRenew}
                        searchDataObject={searchDataObject}
                        historyHelpers={historyHelpers}
                        posttype={state.posttype}
                    />
                }
                {(nomatches) ? <NoMatches style={nms} notFoundText={notresultstext} /> : null}
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
                <Wapplrdialog setRef={setRef} className={ps.dialog} />
            </div>
        )
    }
})

export default createReactClass({
    render: function() {
        return (
            <TemplateDefault {...this.props} >
                <Search {...this.props} />
            </TemplateDefault>
        )
    }
})
