import posttypesPlugins from '../../posttypes'
import helpers from './helpers'

export default {
    first: function() {

        const userQuery = helpers.defaultUserQuery()

        return {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: `{
              taxonomies {
                id
                name
                key
                coll
                path
                pluralname
                subpluralname
                defaulticon
                posttype
                items {
                  id
                  name
                  subtitle
                  cover
                  parent
                  content_brief
                  content_extended
                  countbyposttype
                }
              }
              me {
                ${userQuery}
              }
              posttypes {
                name
                path
                pluralname
                defaulticon
                defaultsort
              }
            }`
            }),
            credentials: 'same-origin'
        }
    },
    ...posttypesPlugins.requests({ helpers }),
    search: function({
                         type = 'search',
                         replacequery,
                         taxonomies,
                         storedSearchData,
                         defaultSearchPostType,
                         posttypes
                     }) {

        return {
            path: '/graphql',
            data: {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: function({ state, params, returntype }) {

                    let q = helpers.search({
                        state,
                        params,
                        replacequery,
                        taxonomies,
                        storedSearchData,
                        defaultSearchPostType,
                        posttypes
                    })

                    let postQuery = null
                    if (q.pt) {
                        const ptQueryName = q.pt.toLowerCase() + 'Query'
                        if (helpers[ptQueryName]) postQuery = helpers[ptQueryName]()
                    }
                    if (!postQuery) postQuery = helpers.defaultPostQuery()
                    const postsDataQuery = helpers.defaultPostsDataQuery()

                    q = JSON.stringify(q).replace(/"/g, '\'')

                    const s = type + `(query:"${q}") {posts{${postQuery}} data{${postsDataQuery}}}`

                    if (returntype === 'child') {
                        return s
                    } else {
                        return JSON.stringify({ query: `{${s}}` })
                    }
                },
                credentials: 'include'
            }
        }
    }
}
