import React from 'react'

import ReactDOM from 'react-dom'

import IconButton from 'material-ui/IconButton'
import Drawer from 'material-ui/Drawer'

import CloseIcon from 'material-ui/svg-icons/navigation/close'
import MenuIcon from 'material-ui/svg-icons/navigation/menu'

export default class DrawerComponent extends React.Component {
    constructor(props) {
        super(props)
        this.refElements = {}
        this.state = {
            open: this.props.state.drawerOpen || false
        }
    };

    getElement = (e) => {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    }
    drawerHandler = () => {
        if (this.props.onDrawerChange) {
            this.props.onDrawerChange((this.state.open) ? this.props.drawerwidth || 256 : 0, this.closeDrawer)
        }
    }
    closeDrawer = async () => {
        if (this.state.open) {
            this.props.state.drawerOpen = false
            await this.setState({ open: false })
        }
        if (this.state.open && this.props.data) this.props.data.setDataCurrent('drawerOpen', false)
    }
    handleToggle = async () => {
        this.props.state.drawerOpen = !this.state.open
        if (this.props.data) this.props.data.setDataCurrent('drawerOpen', !this.state.open)
        await this.setState({
            open: !this.state.open
        })
        this.drawerHandler()
    }
    requestChange = async (open) => {
        this.props.state.drawerOpen = open
        if (this.props.data) this.props.data.setDataCurrent('drawerOpen', open)
        await this.setState({
            open: open
        })
        this.drawerHandler()
    }
    componentDidMount = () => {
        this.drawerHandler()
    }
    getDrawerContainer = () => {
        return this.getElement(this.refElements['drawer']).children[1]
    }
    setRef = (a, e) => {
        this.refElements[a] = e
    }

    render() {
        const { children, style, drawerwidth, openmenutooltip, closemenutooltip } = this.props
        const setRef = this.setRef

        return (
            <div className={style.naviconcontainer}>
                <IconButton
                    label='Open Drawer'
                    onTouchTap={this.handleToggle}
                    onTouchStart={function() {
                    }}
                    tooltip={(!this.state.open) ? openmenutooltip : closemenutooltip}
                    tooltipStyles={{ top: '20px' }}
                    tooltipPosition={'bottom-center'}
                    className={(openmenutooltip || closemenutooltip) ? style.naviconcontainerIconTooltip : style.naviconcontainerIcon}
                >
                    {(!this.state.open) ?
                        <MenuIcon className={style.navicon} /> :
                        <CloseIcon className={style.navicon} />
                    }
                </IconButton>
                <Drawer ref={function(e) {
                    setRef('drawer', e)
                }} containerClassName={style.drawer + ' ' + style.transparentDrawer}
                        overlayClassName={style.draweroverlay}
                        docked={false}
                        width={drawerwidth || 256}
                        open={this.state.open}
                        onRequestChange={(open) => this.requestChange(open)}>
                    {children}
                </Drawer>
            </div>
        )
    }
}
