export default function({ state }) {

    const taxonomies = (state && state.response && state.response.taxonomies) ? state.response.taxonomies : {}
    const dataTaxonomies = {}

    if (taxonomies && taxonomies.length) {
        taxonomies.map(function(t) {

            const key = t.path
            dataTaxonomies[key] = { ...t, items: [] }

            if (t.items && t.items.length) {

                const parentsobj = {}
                const rootobj = {}

                t.items.map(function(c) {

                    if (c.parent && c.parent.length) {
                        c.parent.map(function(p) {
                            if (!parentsobj[p]) parentsobj[p] = []
                            parentsobj[p].push(c)
                        })
                    } else {
                        rootobj[c.id] = c
                    }
                })

                t.items.map(function(c) {
                    if (parentsobj[c.id]) {
                        c.items = parentsobj[c.id]
                    }
                })

                Object.keys(rootobj).map(function(ro) {
                    dataTaxonomies[key].items.push(rootobj[ro])
                })

            }
        })
    }

    const posttypes = (state && state.response && state.response.posttypes) ? state.response.posttypes : {}
    const dataPosttypes = {}

    if (posttypes && posttypes.length) {
        posttypes.map(function(t) {
            dataPosttypes[t.name] = t
        })
    }

    return { taxonomies: dataTaxonomies, posttypes: dataPosttypes }
}
