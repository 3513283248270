import React from 'react'
import createReactClass from 'create-react-class'

/*Icons*/
import LoginIcon from 'material-ui/svg-icons/action/account-circle'
import UserIcon from 'material-ui/svg-icons/action/perm-identity'
import ExitIcon from 'material-ui/svg-icons/action/exit-to-app'
import SettingsIcon from 'material-ui/svg-icons/action/settings'
import ListIcon from 'material-ui/svg-icons/action/list'
import ImageIcon from 'material-ui/svg-icons/image/image'
import FilterIcon from 'material-ui/svg-icons/content/filter-list'
import AddIcon from 'material-ui/svg-icons/content/add-circle'
import PersonIcon from 'material-ui/svg-icons/social/person'
import PlayerIcon from 'material-ui/svg-icons/av/play-circle-filled'

import wapplrcomponents from '../../../components'
//noinspection JSUnresolvedVariable
import avs from '../../../themes/components/Wapplravatar.css'

import { getMinPostStatusWhenAllAccess, poststatuses } from '../../server/poststatus/statuses'
import { getMinUserStatusWhenAllAccess, userstatuses } from '../../server/userstatus/statuses'

const WapplrAvatar = wapplrcomponents.avatar

export default function({
                            style,
                            posttypesPlugins,
                            taxonomies,
                            posttypes,
                            menuFromTaxonomies,
                            cookiepostid,
                            privancyid,
                            contactpostid,
                            curlang = {},
                            searchData,
                            routes = {},
                            getthumb,
                            termspostid
                        }) {

    const historyHelpers = searchData.getHistoryHelpers()
    const minuserstatus = getMinUserStatusWhenAllAccess()
    const minpoststatus = getMinPostStatusWhenAllAccess()

    return {
        main: [
            {
                name: curlang.menuloginname,
                link: routes.menuloginslug,
                lefticon: <LoginIcon />,
                justicon: true,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (!user || user && !user.id)
                }
            },
            {
                name: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.getName) ? user.getName() : ''
                },
                link: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.getLink) ? user.getLink() : ''
                },
                Icon: createReactClass({
                    render: function() {
                        const { sendprops = {}, iconClassName } = this.props
                        const { user } = sendprops
                        return <WapplrAvatar user={user} type='menu' style={avs} className={iconClassName}
                                             getthumb={getthumb} />
                    }
                }),
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                },
                justicon: true, disablemoreicon: true, lefticon: 'Icon'
            }
        ],
        fixeddrawer: [
            {
                name: curlang.newplayertitle,
                link: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id && routes && routes.menuplayerslug) ? routes.menuplayerslug + '/new' : '/account'
                },
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id && user.status > (minuserstatus - 1))
                },
                lefticon: <AddIcon />
            },
            {
                name: curlang.myplayers,
                link: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id) ? '/players' + '?user=' + user.id + '&' + 'sgt=' + (50 - 1) : ''
                },
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id && user.status > (minuserstatus - 1))
                },
                lefticon: <PersonIcon />
            },
            { divider: 1 },
            { name: curlang.allplayers, link: '/players', lefticon: <PlayerIcon /> },
            { divider: 1, inset: true },
            {
                name: curlang.filterplayers, lefticon: <FilterIcon />, items: [
                    ...menuFromTaxonomies({
                        taxonomies,
                        historyHelpers,
                        posttypename: 'Player',
                        posttypepath: 'players'
                    })
                ]
            },
            { divider: 1 },
            {
                name: curlang.menuadmin,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id && user.isAdmin || user && user.id && user.isEditor)
                },
                lefticon: <SettingsIcon />,
                items: [
                    {
                        name: curlang.wapplruserappusersname, lefticon: <UserIcon />, items: [
                            { divider: 1, inset: true },
                            { name: curlang.menuallactive, link: '/users' },
                            {
                                name: curlang.menushouldactive, link: function() {
                                    return '/users?aslt=' + userstatuses['approved'] + '&asgt=' + (minuserstatus - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menumissingdata, link: function() {
                                    return '/users?aslt=' + minuserstatus + '&asgt=' + (userstatuses['registrated'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menudeleted, link: function() {
                                    return '/users?aslt=' + (userstatuses['deleted itself'] + 1) + '&asgt=' + userstatuses['banned'] + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menubanned, link: function() {
                                    return '/users?aslt=' + (userstatuses['banned'] + 1) + '#hidetaxs'
                                }
                            }
                        ]
                    },
                    { divider: 1, inset: true },
                    {
                        name: curlang.wapplruserappplayersname, lefticon: <PlayerIcon />, items: [
                            { divider: 1, inset: true },
                            { name: curlang.menushowonpage, link: '/players' },
                            {
                                name: 'Private and approved', link: function() {
                                    return '/players?' + 'sh=private' + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menushouldactive, link: function() {
                                    return '/players?slt=' + poststatuses['approved'] + '&sgt=' + (minpoststatus - 1) + '&asgt=' + (userstatuses['banned'] - 1) + '&sh=all' + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menumissingdata, link: function() {
                                    return '/players?slt=' + minpoststatus + '&sgt=' + (poststatuses['created'] - 1) + '&asgt=' + (userstatuses['banned'] - 1) + '&sh=all' + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menudeleted, link: function() {
                                    return '/players?slt=' + (poststatuses['deleted itself'] + 1) + '&sgt=' + poststatuses['banned'] + '&asgt=' + (userstatuses['banned'] - 1) + '&sh=all' + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menubanned, link: function() {
                                    return '/players?slt=' + (poststatuses['banned'] + 1) + '&asgt=' + (userstatuses['banned'] - 1) + '&sh=all' + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menuauthormissingdata, link: function() {
                                    return '/players?aslt=' + (minuserstatus) + '&asgt=' + userstatuses['deleted itself'] + '&sgt=' + (userstatuses['banned'] - 1) + '&sh=all' + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menudeletedauthor, link: function() {
                                    return '/players?aslt=' + (userstatuses['deleted itself'] + 1) + '&sgt=' + (userstatuses['banned'] - 1) + '&sh=all' + '#hidetaxs'
                                }
                            }
                        ]
                    },
                    { divider: 1, inset: true },
                    {
                        name: curlang.wapplruserappimagesname, lefticon: <ImageIcon />, items: [
                            { divider: 1, inset: true },
                            { name: curlang.menushowonpage, link: '/images' },
                            {
                                name: curlang.menushouldactive, link: function() {
                                    return '/images?slt=' + poststatuses['approved'] + '&sgt=' + (minpoststatus - 1) + '&asgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menumissingdata, link: function() {
                                    return '/images?slt=' + minpoststatus + '&sgt=' + (poststatuses['created'] - 1) + '&asgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menudeleted, link: function() {
                                    return '/images?slt=' + (poststatuses['deleted itself'] + 1) + '&sgt=' + poststatuses['banned'] + '&asgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menubanned, link: function() {
                                    return '/images?slt=' + (poststatuses['banned'] + 1) + '&asgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menuauthormissingdata, link: function() {
                                    return '/images?aslt=' + (minuserstatus) + '&asgt=' + userstatuses['deleted itself'] + '&sgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menudeletedauthor, link: function() {
                                    return '/images?aslt=' + (userstatuses['deleted itself'] + 1) + '&sgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            }
                        ]
                    },
                    { divider: 1, inset: true },
                    {
                        name: curlang.wapplruserapppostsname, lefticon: <ListIcon />, items: [
                            { divider: 1, inset: true },
                            { name: curlang.menushowonpage, link: '/posts' },
                            {
                                name: curlang.menushouldactive, link: function() {
                                    return '/posts?slt=' + poststatuses['approved'] + '&sgt=' + (minpoststatus - 1) + '&asgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menumissingdata, link: function() {
                                    return '/posts?slt=' + minpoststatus + '&sgt=' + (poststatuses['created'] - 1) + '&asgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menudeleted, link: function() {
                                    return '/posts?slt=' + (poststatuses['deleted itself'] + 1) + '&sgt=' + poststatuses['banned'] + '&asgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menubanned, link: function() {
                                    return '/posts?slt=' + (poststatuses['banned'] + 1) + '&asgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menuauthormissingdata, link: function() {
                                    return '/posts?aslt=' + (minuserstatus) + '&asgt=' + userstatuses['deleted itself'] + '&sgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            },
                            {
                                name: curlang.menudeletedauthor, link: function() {
                                    return '/posts?aslt=' + (userstatuses['deleted itself'] + 1) + '&sgt=' + (userstatuses['banned'] - 1) + '#hidetaxs'
                                }
                            }
                        ]
                    },
                    { divider: 1 }

                ]
            },
            {
                name: curlang.wapplrloginapplogoutname, link: routes.menuloginslug, lefticon: <ExitIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            }
        ],
        footer: [
            { name: curlang.menuterms, link: '/post/' + termspostid },
            { name: curlang.menuprivancy, link: '/post/' + privancyid },
            { name: curlang.menucookie, link: '/post/' + cookiepostid },
            { name: curlang.menucontact, link: '/post/' + contactpostid },
            { name: curlang.menufacebook, link: 'https://www.facebook.com/lightroomstudionorge' },
            { name: curlang.menuinstagram, link: 'https://www.instagram.com/lightroom_studio' }
        ],
        ...posttypesPlugins.getmenus({
            style,
            curlang,
            searchData,
            taxonomies,
            posttypes,
            menuFromTaxonomies,
            routes,
            getthumb
        })
    }
}
