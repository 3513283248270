/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE'
export const SET_QUERY = 'SET_QUERY'
export const SET_URL = 'SET_URL'
export const SET_LAST_URL = 'SET_LAST_URL'
export const SET_RESPONSE = 'SET_RESPONSE'
export const SET_ARCHIVEPOSTS = 'SET_ARCHIVEPOSTS'
export const SET_ARCHIVEPOSTS_RESET = 'SET_ARCHIVEPOSTS_RESET'
export const SET_ISBOT = 'SET_ISBOT'
export const DELETE_USER = 'DELETE_USER'
