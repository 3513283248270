export default function(p) {

    const { posttypesPlugins } = p

    const editdata = {
        ...posttypesPlugins.editdata({ ...p, posttypesPlugins: null })
    }

    return { editdata }

}
