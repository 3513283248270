import React from 'react'
import createReactClass from 'create-react-class'

const Fallback = createReactClass({
    render: function() {
        return (
            <div />
        )
    }
})

function getRouteObj({ app, path }) {
    const routes = app.routes
    let foundroute = null
    routes.map(function(route) {
        if (route.path === path) foundroute = route
    })
    return foundroute
}

function getRoute(app, getroute, params) {

    const routes = app.routes
    let r = null

    if (getroute && getRouteObj({ app, path: getroute })) r = { startroute: getroute, status: null }
    if (!r && app.startroute && getRouteObj({ app, path: app.startroute })) r = {
        startroute: app.startroute,
        status: null
    }

    if (!r && params) {
        Object.keys(params).map(function(pr) {
            if (!r && getRouteObj({ app, path: '/' + pr })) r = { startroute: '/' + pr, status: null }
        })
    }

    if (!r && getRouteObj({ app, path: '/notfound' })) r = { startroute: '/notfound', status: 404 }
    if (!r && getRouteObj({ app, path: '/home' })) r = { startroute: '/home', status: 404 }

    if (!r) {
        let startroute = null
        routes.map(function(route, index) {
            if (index === 0) startroute = route.path
        })
        r = { startroute: startroute, status: 404 }
    }

    return r
}

export function getContent(app, getroute, params) {
    const { startroute, status } = getRoute(app, getroute, params)
    const route = getRouteObj({ app, path: startroute })
    const content = route.data.content
    const routedata = route.data
    const contents = app.contents
    return (contents[content]) ? { content: contents[content], status: status, data: routedata } : { status: status }
}

const GetComponent = createReactClass({
    render: function() {
        const { app, getroute, construct } = this.props
        const { content } = getContent(app, getroute)
        const components = app.components
        const component = content.component
        let Component = (components[component]) ? components[component] : Fallback
        return <Component {...content} construct={construct} />
    }
})

export default createReactClass({
    getInitialState() {
        return {
            pathname: null
        }
    },
    getPathName(a) {
        const { app } = this.props
        const propsparentroute = this.props.parentroute

        let pathname = null
        if (a && typeof a == 'object' && a.pathname) pathname = a.pathname
        if (a && typeof a == 'string') pathname = a
        if (app && app.parentroute && pathname && pathname.match(app.parentroute)) pathname = pathname.split(app.parentroute)[1]

        if (propsparentroute) {
            if (pathname && pathname.match(propsparentroute)) pathname = pathname.split(propsparentroute)[1]
        }

        return pathname
    },
    push: function(a) {
        const pathname = this.getPathName(a)
        if (pathname) {
            this.setState({
                pathname: pathname
            })
            return pathname
        }
        return false
    },
    render: function() {

        const { app } = this.props

        const push = this.push
        const props = this.props
        const state = this.state

        const construct = {
            push: push,
            props: props,
            state: state
        }

        return <GetComponent app={app} getroute={this.state.pathname} construct={construct} />
    }
})
