import React from 'react'

import Wapplr, { getContent } from '../../components/Wapplr'

async function getFetch({ helpers, fetchreq, path, state, req, params }) {

    let resp = null
    let respData = null
    const fetch = (helpers) ? helpers.fetch : null

    if (fetchreq && fetch) {

        const store = (helpers && helpers.store) ? helpers.store : null
        const nstate = (state) ? state : (store) ? store.getState() : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null

        const getBody = (typeof fetchreq.data.body == 'function' || typeof fetchreq.data.getBody == 'function') ?
            (typeof fetchreq.data.body == 'function') ? fetchreq.data.body : fetchreq.data.getBody
            : null

        fetchreq.data.body = (getBody) ? getBody({ path, state: nstate, req, params }) : fetchreq.data.body
        fetchreq.data.getBody = getBody

        const cachedResp = (helpers && helpers.cachedResponse) ? helpers.cachedResponse[fetchreq.data.body] : null

        if (cachedResp && false) {
            return cachedResp
        } else {
            if (req && req.headers && req.headers.cookie) fetchreq.data.headers.cookie = req.headers.cookie

            resp = await fetch(fetchreq.path, fetchreq.data)
            if (resp.status !== 200) throw new Error(resp.statusText)
            respData = (resp && resp.json) ? await resp.json() : null

            if (respData.data) respData = respData.data

            if (Object.keys(respData).length) {
                Object.keys(respData).map(function(rk) {
                    const reqname = rk || 'unnamed'
                    if (respData && store && setResponse) {
                        store.dispatch(setResponse({
                            name: reqname,
                            value: respData[reqname] || respData
                        }))
                    }
                })
            }

            if (helpers) {
                if (!helpers.cachedResponse) helpers.cachedResponse = {}
                helpers.cachedResponse[fetchreq.data.body] = respData
            }

            return respData

        }
    }
}

async function action({
                          path,
                          universalPath,
                          next,
                          config,
                          helpers,
                          fetchreq,
                          disablefetchcall,
                          params,
                          req,
                          callback,
                          startroute
                      }) {

    config.app.startroute = (startroute) ? startroute : (path !== '*') ? path : universalPath

    const { content, status } = getContent(config.app, null, params)

    if (content) {
        content.getFetch = async function({ state }) {
            return await getFetch({ helpers, fetchreq, path, req, params, state })
        }
        content.helpers = helpers
        if (fetchreq && !disablefetchcall) {
            content.response = await getFetch({ helpers, fetchreq, path, req, params })
        }
    }

    const title = (content && content.title) ? content.title : ''
    const description = (content && content.description) ? content.description : ''

    let r = {
        title: title,
        description: description,
        component: <Wapplr {...config} />,
        config: config,
        status: status,
        data: {
            path, universalPath, next, config, helpers, fetchreq, params, req, callback, startroute, content
        }
    }

    if (callback) {
        const c = await callback({
            path,
            universalPath,
            next,
            config,
            helpers,
            fetchreq,
            params,
            req,
            callback,
            startroute,
            r,
            content,
            getFetch,
            getContent
        })
        if (c) r = c
    }

    return r

}

function addAction({ routes, helpers, config }) {
    routes.map(function(route) {
        route.action = async function({ req, params, next, path }) {
            return await action({
                path: route.path,
                universalPath: path,
                next,
                config,
                helpers,
                params,
                req, ...route.data
            })
        }
        if (route.children) {
            addAction({ routes: route.children, helpers, config })
        }
    })
}

export function setRoutes(config) {

    let childroutes = []

    if (config && config.app && config.app.routes) {
        const app = config.app
        const routes = app.routes
        const helpers = config.helpers
        addAction({ routes, helpers, config })
        childroutes = routes
    }

    return {
        path: '/',
        children: childroutes,
        async action({ next }) {
            const route = await next()
            const sitename = config.core.sitename || 'Wapplr'
            const { helpers = {} } = config
            const { store } = helpers
            const state = (store) ? store.getState() : null
            route.title = (typeof route.title == 'function') ? route.title({ state }) : `${route.title || 'Untitled Page'} - ` + sitename
            route.description = route.description || ''
            return route
        }
    }
}
