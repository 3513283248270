import React from 'react'
import createReactClass from 'create-react-class'

import Subheader from 'material-ui/Subheader'
import s from '../empty.css'

import CircularProgress from 'material-ui/CircularProgress'

export default createReactClass({
    getInitialState: function() {
        return {
            isMounted: false,
            data: this.props.data,
            enableInifiniteScroll: this.props.enableInifiniteScroll,
            processing: false
        }
    },
    setProcessing: async function(data = false) {
        const { processing } = this.state
        if (processing !== data) {
            await this.setState({
                processing: data
            })
        }
    },
    infiniteScrollWait: async function() {

        const { infiniteScroll, onClick, scrollDiv } = this.props
        const data = this.state.data
        const escrollDiv = (scrollDiv) ? scrollDiv : ('undefined' !== typeof document) ? document.body : null
        const buttonDiv = this.refElements['pagination']

        const bottomTop = (buttonDiv) ? buttonDiv.getBoundingClientRect().top : 0
        const whereisButtonOnScreen = ('undefined' !== typeof window) ? window.innerHeight - bottomTop : -1

        const go = (this.state.isMounted && onClick && infiniteScroll && data && data.pages && data.nextpages &&
            escrollDiv && whereisButtonOnScreen > -150 && whereisButtonOnScreen < 100
        ) ? 1 : 0

        if (go) {
            this.setDisableInifiniteScroll()
            await onClick()
            this.setEnableInifiniteScroll()
        }
    },
    setEnableInifiniteScroll: async function() {
        if (this.state.isMounted) {
            this.setState({ enableInifiniteScroll: true })
            this.state.enableInifiniteScroll = true
            if (this.eventFiredInfiniteScroll) {
                await this.infiniteScroll()
            }
        }
    },
    setDisableInifiniteScroll: function() {
        if (this.state.isMounted) {
            this.setState({ enableInifiniteScroll: false })
            this.state.enableInifiniteScroll = false
        }
    },
    infiniteScroll: async function() {
        if (this.state.enableInifiniteScroll) {
            this.eventFiredInfiniteScroll = false
            await this.infiniteScrollWait()
        } else {
            this.eventFiredInfiniteScroll = true
        }
    },
    isIOs: function() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    },
    componentDidMount() {

        const { scrollDiv, data } = this.props
        let escrollDiv = (scrollDiv) ? scrollDiv : ('undefined' !== typeof window) ? window : null
        if ('undefined' !== typeof document && escrollDiv === document.body) escrollDiv = window

        this.setState({ isMounted: true })
        this.state.isMounted = true

        if (this.props.infiniteScroll && escrollDiv && data && data.pages && data.nextpages) {

            if (this.isIOs()) {
                escrollDiv.addEventListener('scroll', this.infiniteScroll)
            } else {
                escrollDiv.addEventListener('scroll', this.infiniteScroll)
            }
            setTimeout(this.setEnableInifiniteScroll, 1000)
        }

    },
    componentWillUnmount: function() {

        const { scrollDiv } = this.props
        let escrollDiv = (scrollDiv) ? scrollDiv : ('undefined' !== typeof window) ? window : null
        if ('undefined' !== typeof document && escrollDiv === document.body) escrollDiv = window

        this.setState({ isMounted: false })
        this.state.isMounted = false
        if (escrollDiv) {
            if (this.isIOs()) {
                escrollDiv.removeEventListener('scroll', this.infiniteScroll)
            } else {
                escrollDiv.removeEventListener('scroll', this.infiniteScroll)
            }
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        if (this.props.onClick) {
            this.props.onClick(e, d)
        } else {
            this.historyPush(d)
            if (e) e.preventDefault()
        }
    },
    historyPush: function() {
        const { historyHelpers, store } = this.props
        const h = historyHelpers.getDataFromHistory({ store })
        h.page = this.state.data.currentpage + 1
        const searchText = historyHelpers.objectToString(h)
        historyHelpers.historyPush({ search: searchText })
    },
    getHref: function() {

        const { historyHelpers, store } = this.props

        let href = ''
        let searchText = ''

        const data = this.state.data

        if (data && data.pages && data.nextpages) {
            const h = historyHelpers.getDataFromHistory({ store })
            h.page = data.currentpage + 1
            searchText = historyHelpers.objectToString(h)
            href = '?' + searchText
        }

        return href
    },
    refElements: {},
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    render: function() {

        const { style = s, className, loadMoreDataText = 'Load more data', data } = this.props

        const touchEnd = this.touchEnd
        const setRef = this.setRef
        this.state.data = data
        const href = this.getHref()
        const { processing } = this.state

        return (
            <div ref={function(e) {
                setRef('pagination', e)
            }}>
                <Subheader className={(className) ? style.loadmoredata + ' ' + className : style.loadmoredata}>
                    {(data && data.pages && data.nextpages) ?
                        <a
                            style={(processing) ? { cursor: 'default' } : {}}
                            href={href}
                            onClick={(processing) ? null : function(e) {
                                touchEnd(e, 'click', 'click')
                            }}
                            onTouchStart={function() {
                            }}>
                            {(processing) ? <div className={style.circular}><CircularProgress size={24} thickness={3}
                                                                                              style={{ display: 'block' }} />
                            </div> : <span>{loadMoreDataText}</span>}
                        </a>
                        : null
                    }
                </Subheader>
            </div>
        )
    }
})
