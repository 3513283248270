import posttypesPlugins from '../posttypes'

export default {

    description: 'FlatChooser - Flat presentation application',
    sitenametitle: ' - Flat presentation application',
    welcometitle: 'Present your flat',
    welcometitle1: 'An app created for architect offices, visual designers and contractors to present and sell buildings and flats easier.',

    menuhomename: 'Főoldal',
    menusearchname: 'Keresés',
    menuhometitle: 'Főoldal',
    menusearchtitle: 'Keresés',
    menuarchivetitle: 'Archive',
    menuadmin: 'Admin',

    menuallactive: 'Összes aktív',
    menushowonpage: 'Ami a listákban látszik',
    menushouldactive: 'Jóváhagyás szükséges',
    menumissingdata: 'Hiányzó adatok',
    menudeleted: 'Törölt',
    menubanned: 'Bannolt',
    menuauthormissingdata: 'Szerző hiányzó adatai',
    menudeletedauthor: 'Törölt szerző',

    menuterms: 'ÁSZF',
    menuprivancy: 'ADATVÁDELEM',
    menucookie: 'COOKIE',
    menucontact: 'KAPCSOLAT',
    menufacebook: 'FACEBOOK',
    menuinstagram: 'INSTAGRAM',

    yourprofile: 'A PROFIL OLDALAD',
    loginbutton: 'BELÉPÉS',
    registrationbutton: 'REGISZTRÁCIÓ',
    playersbutton: 'LEJÁTSZÓK',

    searchhint: 'Keresés',
    getsearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Keresés'
        if (username && !taxs) r = 'Keresés ' + username + ' bejegyzései között'
        if (!username && taxs) r = 'Keresés a ' + taxs + ' kategóriában'
        if (username && taxs) r = 'Keresés ' + username + ' bejegyzései között, a ' + taxs + ' kategóriában'
        return r
    },
    activesearchsubheadertext: 'Keresés: ',
    notfoundsearchsubheadertext: 'Nincs egyezés',
    emptysearchsubheadertext: 'Írjon be egy szöveget vagy szövegrészletet a kereséshez',
    notresultstext: 'Nincs találat',
    uploadtext: 'File feltöltése',
    notresultstextgallery: 'Nincs kép',

    mincharerror: function(a) {
        return 'Minimum ' + a + ' karakter'
    },
    maxcharerror: function(a) {
        return 'Maximum ' + a + ' karakter'
    },

    minarrerror: function(a) {
        return 'Minimum ' + a
    },
    maxarrerror: function(a) {
        return 'Maximum ' + a
    },

    minselectnumber: function(a, b = '') {
        if (!a) return ''
        if (b) return 'Válasszon legalább ' + a + '-et a ' + b + ' közül'
        return 'Válasszon legalább ' + a + '-et a lehetőségek közül'
    },

    deletepostsubtitle: 'Bejegyzés törlése',
    deletepostquestion: 'Biztos benne, hogy törölni szeretné a bejegyzést?',
    savepostdefaultfail: 'Hiba történt a bejegyzés mentése közben, próbálkozzon újra',
    savepostsuccessmessage: 'A bejegyzés elmentve',
    deletepostdefaultfail: 'Hiba történt a bejegyzés törlése közben, próbálkozzon újra',
    deletepostsuccessmessage: 'A bejegyzés törölve',
    accessfail: 'Nincs jogosultsága ehhez a funkcióhoz, keresse fel a rendszer üzemeletőjét',

    restorepostsubtitle: 'Visszaállítás',
    restorepostquestion: 'Biztos benne, hogy visszaállítja a bejegyzést?',

    duplicatepostdefaultfail: 'Hiba történt a bejegyzés másolása közben, próbálkozzon újra',
    duplicatepostsuccessmessage: 'A bejegyzés lemásolva',

    userpostssubtitle: 'Bejegyzések',
    userpoststrashsubtitle: 'Törölt bejegyzések',

    duplicatepostsubtitle: 'Bejegyzés másolása',
    duplicatepostquestion: 'Biztos benne, hogy lemásolja a bejegyzést?',

    submittext: 'Mehet',
    canceltext: 'Mégsem',
    savepost: 'Bejegyzés mentése',
    saveandrestore: 'Mentés és visszaállítás',
    selectimage: 'Kép kiválasztása',
    selectimages: 'Képek kiválasztása',

    next: 'Következő',
    closetext: 'Bezár',

    stepsetbasicdata: 'Alap adatok beállítása',
    stepchooseacover: 'Borítókép kiválasztása',
    stepaddsomeimage: 'Képek hozzáadása',
    stepcategory: 'Kategória kiválasztása',

    duplicatedtext: '(másolat)',
    activeSearchSubHeaderText: 'Keresés: ',
    notFoundSearchSubHeaderText: 'Nincs találat',
    emptySearchSubHeaderText: ' ',
    clearFiltersText: 'Szűrők törlése',
    updateFiltersText: 'Szűrők frissítése',
    showFiltersText: 'Szűrők mutatása',
    showPostsText: 'Találatok mutatása',
    getResultsText: function({ skip, currentpage, limit, count, length }) {
        let s = Number(skip)
        const l = (currentpage * limit < count) ? currentpage * limit : count

        let t = ''
        if (s > 0) {
            s = s + 1
            t = 'mutatva ' + s + '-' + l + ' az összes ' + count + ' találatból'
        } else {
            t = 'mutatva ' + length + ' az összes ' + count + ' találatból'
        }
        return t
    },

    poststatuscreated: 'publikálva',
    poststatusdeleteditself: 'törölve a szerző által',
    poststatusbanned: 'törölve véglegesen',
    poststatusrequireddata: 'szükséges adatok megadva',
    poststatusapproves: 'ellenőrízve',

    approvepostsubtitle: 'Ellenőrízve',
    approvepostquestion: 'Át szeretnét állítani ennek a bejegyzésnek a státuszát ellenőrzöttre?',
    approvepostdefaultfail: 'Hiba történt a bejegyzés jóváhagyása közben, próbálkozzon újra',
    approvepostsuccessmessage: 'Bejegyzés jóváhagyva',

    banpostsubtitle: 'Tiltás',
    banpostquestion: 'Tiltani szeretné ezt a bejegyzést',
    banpostdefaultfail: 'Hiba történt a bejegyzés tiltása közben, próbálkozzon újra',
    banpostsuccessmessage: 'Bejegyzés letiltva',

    approvetext: 'Jóváhagyás',
    bantext: 'Végleges törlés',

    wordcategory: 'Kategória',

    approvecookietext: 'Sütiket és egyéb technológiákat használunk annak biztosítására, hogy aki a szolgáltatásainkat használja, a lehető legjobb élményt kapja.',
    cookieapprovetext: 'Megértettem',
    moretext: 'bővebben >>',
    loadmoredatatext: 'Továbbiak betöltése',

    publisheddate: 'Létrehozás dátuma',
    status: 'Státusz',
    viewprofile: 'profil megtekintése',

    notfoundtext: 'Ez az oldal nem elérhető',
    notfounddesc: 'Az oldalt törölve lett, vagy nincs hozzá jogosultsága',
    loggedoutdesc: 'Próbáljon belejentkezni a fiókjába mert vannak oldalak, amiket csak az láthat akinek van jogosultsága',

    gotohomepage: 'Főoldal',
    gotoprofile: 'Profil',
    gotologin: 'Belépés',
    gotosignup: 'Regisztráció',
    gotobacktoprev: 'Előző oldal',

    ...posttypesPlugins.languages({ lang: 'hu_hu' })

}
