import React from 'react'

import LabelIcon from 'material-ui/svg-icons/action/label'
import FolderIcon from 'material-ui/svg-icons/file/folder'
import ExtensionIcon from 'material-ui/svg-icons/action/extension'

export default function({ setSearchData, searchDefaultsFromTaxonomies, taxonomies, history }) {



    function menuFromTaxonomiesRecursive(data, currentTaxonomy, skipFirstCategory) {
        const p = []
        if (data && data.length) {
            data.map(function(d) {
                if (!skipFirstCategory) {
                    p.push(d.name)
                }
                if (d.items) {
                    p.push(
                        ...menuFromTaxonomiesRecursive(d.items, currentTaxonomy)
                    )
                }
            })
        }
        return p
    }

    function menuFromTaxonomies({ taxonomies, posttypename = '', skipFirstCategory }) {

        const moft = []
        Object.keys(taxonomies).forEach(function(key) {

            let go = false
            if (!posttypename) go = true
            if (taxonomies[key].posttype) {
                taxonomies[key].posttype.map(function(p) {
                    if (p === posttypename) go = true
                })
            }

            if (go) {
                const data = taxonomies[key].items
                moft.push(...menuFromTaxonomiesRecursive(data, taxonomies[key], skipFirstCategory))
            }

        })
        return moft
    }


    const menus = menuFromTaxonomies({ taxonomies, posttypename: 'Player', posttypepath: 'players' })

    return setSearchData({
        dataSource: menus,
        dataCurrent: {
            text: '',
            page: '',
            user: '',
            secuser: '',
            posttype: '',
            sgt: '',
            slt: '',
            asgt: '',
            aslt: '',
            limit: '',
            sh: '', ...searchDefaultsFromTaxonomies(taxonomies)
        },
        defaults: {
            text: '',
            page: '',
            user: '',
            secuser: '',
            posttype: '',
            sgt: '',
            slt: '',
            asgt: '',
            aslt: '',
            limit: '',
            sh: '', ...searchDefaultsFromTaxonomies(taxonomies)
        }
    }, history)
}
