import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import RaisedButton from 'material-ui/RaisedButton'

const NotFound = createReactClass({
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {

        const { loginHref = '/', signupHref = '/', homeHref = '/', profileHref = '/' } = this.props
        const { type } = d

        if (type === 'home') {
            this.historyPush(homeHref)
        }
        if (type === 'back') {
            this.back()
        }
        if (type === 'profile') {
            this.historyPush(profileHref)
        }
        if (type === 'login') {
            this.historyPush(loginHref)
        }
        if (type === 'signup') {
            this.historyPush(signupHref)
        }
        e.preventDefault()
    },
    back: function() {
        const { history } = this.props
        if (history && history.go) {
            history.go(-1)
        }

    },
    historyPush: function(href) {
        const { history } = this.props
        if (href && history && history.push) {
            history.push(href)
        }

    },
    getStyle: function() {
        const { containerStyle = {} } = this.props
        return { ...containerStyle }
    },
    getClassName: function() {
        const { className, style } = this.props
        if (className) return style.notfound + ' ' + className
        return style.notfound
    },
    render: function() {

        const {
            style,
            notFoundText = 'Sorry, this page isn\'t available',
            notFoundDesc = 'The link you followed may be broken, or the page may have been removed',
            loggedOutDesc,
            backLabel = 'Go back to the previous page',
            loginLabel,
            signupLabel,
            homeLabel,
            profileLabel
        } = this.props
        const touchEnd = this.touchEnd
        const notfoundStyle = this.getStyle()
        const className = this.getClassName()

        return (
            <div className={className} style={notfoundStyle}>
                <div className={style.content}>
                    <div className={style.notFoundText}>
                        {notFoundText}
                    </div>
                    <div className={style.notFoundDesc}>
                        {notFoundDesc}
                    </div>
                    {(loggedOutDesc) ?
                        <div className={style.loggedOutDesc}>
                            {loggedOutDesc}
                        </div>
                        : null
                    }
                </div>
                <div className={style.buttons}>
                    {(backLabel) ?
                        <div className={style.button}>
                            <RaisedButton
                                label={backLabel}
                                onTouchTap={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'back' })
                                }}
                                onTouchStart={function() {

                                }}
                                secondary={true}
                            />
                        </div> : null
                    }
                    {(homeLabel) ?
                        <div className={style.button}>
                            <RaisedButton
                                label={homeLabel}
                                onTouchTap={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'home' })
                                }}
                                onTouchStart={function() {

                                }}
                                secondary={true}
                            />
                        </div> : null
                    }
                    {(profileLabel) ?
                        <div className={style.button}>
                            <RaisedButton
                                label={profileLabel}
                                onTouchTap={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'profile' })
                                }}
                                onTouchStart={function() {

                                }}
                                secondary={true}
                            />
                        </div> : null
                    }
                    {(loginLabel) ?
                        <div className={style.button}>
                            <RaisedButton
                                label={loginLabel}
                                onTouchTap={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'login' })
                                }}
                                onTouchStart={function() {

                                }}
                                secondary={true}
                            />
                        </div> : null
                    }
                    {(signupLabel) ?
                        <div className={style.button}>
                            <RaisedButton
                                label={signupLabel}
                                onTouchTap={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'signup' })
                                }}
                                onTouchStart={function() {

                                }}
                                secondary={true}
                            />
                        </div> : null
                    }
                </div>
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <NotFound {...this.props} ref={(setRef) ? function(e) {
                setRef('notfound', e)
            } : null} />
        )
    }
})


export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
