import { decode, encode } from 'querystring'

export default function() {

    const searchBars = {}

    function searchBarRefs(a, e) {
        searchBars[a] = e
    }

    function getSearchBar(a) {
        return searchBars[a]
    }

    let searchBarDataObjects = {}

    function getSearchBarDataObject(a) {

        searchBarDataObjects[a] = {
            dataCurrent: {
                full: false
            }
        }

        let searchBarData = {
            getDataCurrent: function() {
                return searchBarDataObjects[a].dataCurrent
            },
            setDataCurrent: function(b, v) {
                searchBarDataObjects[a].dataCurrent[b] = v
            },
            setData: function(b) {
                searchBarDataObjects[a] = b
                return searchBarData
            }
        }
        return searchBarData
    }

    function searchBarOnFocusHandle(menu) {
        const appbar = menu
        if (appbar && appbar.centerFullOn) {
            appbar.centerFullOn()
            return true
        }
    }

    function searchBarOnCloseHandle(menu) {
        const appbar = menu
        if (appbar && appbar.centerFullOff) {
            appbar.centerFullOff()
            return true
        }
    }

    let searchDataObject = {}


    let searchData = {
        getDataSource: function() {
            return searchDataObject.dataSource
        },
        getDataCurrent: function() {
            return searchDataObject.dataCurrent
        },
        setDataCurrent: function(a, v) {
            searchDataObject.dataCurrent[a] = v
        },
        getHistoryHelpers: function() {
            return searchDataObject.history
        }
    }

    function addHistoryHelpers(a, h, defaults) {

        a.textToObject = function(text, k) {
            const o = (text && text.split && text.match(/\+/) && !text.match(/\+36/)) ? text.split('+') : null
            if (o && typeof o == 'object' && o.length) {
                const r = {}
                o.forEach(function(value) {
                    r[value] = { id: value }
                })
                return r
            }
            if (k) return { [text]: { id: text } }
            return null
        }
        a.getDataFromHistory = function(p = {}) {

            const { data, store } = p
            const history = h

            const r = {}
            const so = {
                data: {}
            }
            let search = ''

            if (data) {

                so.data = { ...data }

            } else if (store) {

                const state = store.getState()
                so.data = { ...state.query }

            } else {

                search = (history && history.location) ? history.location.search : ''
                if (!search) return r
                if (search.slice(0, 1) === '?') search = search.slice(1)
                so.data = decode(search)
            }

            Object.keys(so.data).forEach(function(key) {
                let e = so.data[key]
                const k = (typeof a.defaults[key] == 'object')
                if (typeof e == 'number' && e.toString) e = e.toString()
                if (e && typeof e == 'string') r[key] = a.textToObject(e, k) || e
            })

            return r
        }
        a.objectToString = function(o) {
            let r = {}
            if (o) {
                Object.keys(o).forEach(function(key) {
                    const e = o[key]
                    if (e) {
                        const to = typeof e
                        if (to === 'string') r[key] = e
                        if (to === 'number' && e.toString) r[key] = e.toString()
                        if (to === 'number' && e.toString) r[key] = e
                        if (to === 'object' && e.toString() === '[object Object]') {
                            let s = ''
                            Object.keys(e).forEach(function(ek) {
                                if (e[ek]) s = (s) ? s + '+' + ek : ek
                            })
                            if (s) r[key] = s
                        }
                    }
                })
            }
            return encode(r)
        }
        a.stringToSearch = function(to, st) {
            const history = h
            if (history && to) {
                if (st) {
                    if (!history.location ||
                        history.location && history.location.pathname !== to ||
                        history.location && history.location.search !== st) {
                        history.push({ pathname: to, search: st })
                    }
                } else {
                    if (!history.location || history.location && history.location.pathname !== to) history.push({ pathname: to })
                }
            }
        }
        a.getDataCurrent = function() {
            return searchData.getDataCurrent()
        }
        a.objectToSearch = function(to, o) {
            let e = o || searchData.getDataCurrent()
            const st = a.objectToString(e)

            a.stringToSearch(to, st)
        }
        a.defaults = defaults || {
            text: '',
            categories: {},
            tags: {}
        }
        a.resetToDefaults = function(ex) {
            Object.keys(a.defaults).forEach(function(key) {
                if (key !== ex) {
                    const def = a.defaults[key]
                    const to = typeof def
                    const ts = (def.toString) ? def.toString() : 'null'
                    if (to === 'string') searchDataObject.dataCurrent[key] = ''
                    if (to === 'object' && ts === '[object Object]') searchDataObject.dataCurrent[key] = {}
                }
            })
        }
        a.refreshFromSearch = function(p = {}) {

            const { store } = p
            const searchDataH = a.getDataFromHistory({ store }) || {}
            const cd = searchData.getDataCurrent()

            Object.keys(searchDataH).forEach(function(key) {
                let go = false

                if (!cd[key]) go = true

                if (cd[key] && cd[key].toString() === '[object Object]') {
                    let empty = true
                    Object.keys(cd[key]).forEach(function() {
                        empty = false
                    })
                    if (empty) go = true
                }

                if (go) searchData.setDataCurrent(key, (searchDataH[key]) ? searchDataH[key] : a.defaults[key])
            })

            return searchData.getDataCurrent()
        }
        a.getTaxonomiesFromSearchRec = function(currentTaxonomy = [], hierarchy, taxonomies, search, deep = 0) {

            if (deep === 0) hierarchy[deep] = taxonomies

            taxonomies.items.map(function(d) {
                if (d.id === search) {
                    currentTaxonomy.push(d)
                    hierarchy[deep + 1] = d
                    const endhierarchy = {}
                    Object.keys(hierarchy).forEach(function(key) {
                        if (Number(key) <= deep + 1) endhierarchy[key] = hierarchy[key]
                    })
                    hierarchy = endhierarchy
                    return { currentTaxonomy, endhierarchy }
                }
                if (d.items && !currentTaxonomy.length) {
                    hierarchy[deep + 1] = d
                    a.getTaxonomiesFromSearchRec(currentTaxonomy, hierarchy, d, search, deep + 1)
                }
            })

            return { currentTaxonomy, hierarchy }

        }
        a.getTaxonomyByIdsRec = function(data, search) {
            data.map(function(d) {
                if (search[d.id]) search[d.id] = d
                if (d.items) a.getTaxonomyByIdsRec(d.items, search)
            })
        }
        a.getTaxonomyByIds = function(currentTaxonomyData, search) {
            const r = { ...search }
            a.getTaxonomyByIdsRec(currentTaxonomyData, r)
            return r
        }
        a.getTaxonomiesFromSearch = function(taxonomies) {

            const history = h
            let taxonomyslug = (history && history.location) ? history.location.pathname : ''
            if (taxonomyslug.slice && taxonomyslug.slice(0, 1) === '/') taxonomyslug = taxonomyslug.slice(1)
            if (taxonomyslug.slice && taxonomyslug.slice(-1) === '/') taxonomyslug = taxonomyslug.slice(0, -1)

            if (taxonomyslug.match('/')) {
                const taxonomyslugs = taxonomyslug.split('/')
                taxonomyslug = taxonomyslugs[taxonomyslugs.length - 1]
            }

            const currentTaxonomies = (taxonomies && taxonomies[taxonomyslug]) ? taxonomies[taxonomyslug] : null
            const pathname = '/' + taxonomyslug

            const findInSearch = (currentTaxonomies) ? currentTaxonomies.path : null
            const searchData = a.getDataFromHistory()
            const currentTaxIdsInSearch = (findInSearch && searchData[findInSearch]) ? searchData[findInSearch] : {}
            let currentFirstId = null

            Object.keys(currentTaxIdsInSearch).forEach(function(key) {
                currentFirstId = key
            })

            const {
                currentTaxonomy,
                hierarchy
            } = (currentFirstId) ? a.getTaxonomiesFromSearchRec([], {}, currentTaxonomies, currentFirstId) : {
                currentTaxonomy: [],
                hierarchy: { 0: currentTaxonomies }
            }

            return { taxonomyslug, pathname, currentTaxonomies, currentTaxonomy: currentTaxonomy[0], hierarchy }
        }
        a.getAuthorSearch = function(author) {
            let search = ''
            if (author && author.id) {
                search = 'user=' + author.id
            }
            return search
        }
        a.getPostType = function() {
            let paths = (h.location && h.location.pathname) ? h.location.pathname : ''
            if (paths && paths.slice(0, 1) === '/') {
                paths = paths.slice(1)
            }
            if (paths && paths.slice(-1) === '/') {
                paths = paths.slice(0, -1)
            }
            let posttype = paths
            if (paths && paths.match('/')) {
                posttype = paths.split('/')[0]
            }
            return posttype
        }
        a.getTaxonomyLink = function(taxonomies, taxonomy, posttype = '') {

            const path = (taxonomies) ? taxonomies.path || taxonomies.slug : h.location.pathname
            const id = (taxonomy) ? taxonomy.id : 0

            let pathname = path
            if (posttype) pathname = posttype + '/' + pathname
            if (pathname && pathname.slice(0, 1) !== '/') pathname = '/' + pathname

            let search = (taxonomy) ? path + '=' + id : ''
            if (taxonomies && taxonomies === taxonomy) search = ''
            if (search && search.slice(0, 1) === '/') search = search.slice(1)


            return { pathname, search, link: (search) ? pathname + '?' + search : pathname }

        }
        a.historyPush = function(o, pushwthtreload) {

            const history = h
            if (o && typeof o == 'string') {
                const urlo = o.split('?')
                o = {
                    to: urlo[0],
                    search: urlo[1]
                }
            }
            const to = o.to
            const hash = o.hash
            const search = o.search

            if (to || hash || hash == '' || search || search == '') {

                const pushHistoryObject = {}

                if (to) pushHistoryObject.pathname = to
                if (search || search == '') pushHistoryObject.search = search
                if (hash || hash == '') pushHistoryObject.hash = hash

                a.resetToDefaults()

                if (pushwthtreload) {
                    let url = ''
                    Object.keys(pushHistoryObject).map(function(k) {
                        let v = pushHistoryObject[k]
                        if (v) {
                            if (k == 'pathname') {
                                if (v.slice(0, 1) == '/') v = v.slice(1)
                                url = url + v
                            }
                            if (k == 'search') {
                                if (v.slice(0, 1) == '?') v = v.slice(1)
                                url = url + '?' + v
                            }
                            if (k === 'hash') {
                                if (v.slice(0, 1) == '#') v = v.slice(1)
                                url = url + '#' + v
                            }
                        }
                    })
                    if (url && typeof window !== 'undefined') {
                        if (pushHistoryObject.search) {
                            history.location.search = (pushHistoryObject.search.slice(0, 1) == '?') ? pushHistoryObject.search : '?' + pushHistoryObject.search
                        }
                        if (pushHistoryObject.hash) {
                            history.location.hash = (pushHistoryObject.hash.slice(0, 1) == '#') ? pushHistoryObject.hash : '#' + pushHistoryObject.hash
                        }
                        if (pushHistoryObject.pathname) {
                            history.location.pathname = (pushHistoryObject.pathname.slice(0, 1) == '/') ? pushHistoryObject.pathname : '/' + pushHistoryObject.pathname
                        }
                        history.location.key = history.location.pathname + history.location.hash + history.location.search
                        window.history.replaceState({}, '', url)
                    }
                } else {
                    history.push(pushHistoryObject)
                }
            }
        }
    }

    function setSearchData(a, history) {
        searchDataObject = a
        searchDataObject.history = {}
        addHistoryHelpers(searchDataObject.history, history, searchDataObject.defaults)
        return searchData
    }

    function searchDefaultsFromTaxonomies(taxonomies) {
        const sdft = {}
        Object.keys(taxonomies).forEach(function(key) {
            const taxonomy = taxonomies[key]
            sdft[taxonomy.path] = {}
        })
        return sdft
    }

    return {
        searchBarOnFocusHandle,
        searchBarOnCloseHandle,
        setSearchData,
        searchData,
        searchDefaultsFromTaxonomies,

        getSearchBarDataObject,
        searchBarRefs,
        getSearchBar
    }
}
