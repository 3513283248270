import React from 'react'
import createReactClass from 'create-react-class'
import { findDOMNode } from 'react-dom'

import IconButton from 'material-ui/IconButton'
import IconMenu from 'material-ui/IconMenu'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import MobileDetect from 'mobile-detect'

import { Manager, Popper, Target } from 'react-popper'
import Portal from 'react-travel'
import Paper from 'material-ui/Paper'
import outy from 'outy'

const IconMenuWithPopper = createReactClass({
    getInitialState: function() {
        const { open = false } = this.props
        return {
            open: open,
            preventDefault: null
        }
    },
    open: function() {
        this.setState({
            open: true
        })
    },
    close: function() {
        this.setState({
            open: false
        })
    },
    toggle: function() {
        const { open } = this.state
        this.setState({
            open: !open
        })
    },
    getPopperRoot: function() {
        const { popperRoot } = this.props
        return (typeof popperRoot == 'function') ? popperRoot() : null
    },

    componentDidMount: function() {
        this.popperRoot = this.getPopperRoot()
        this._setOusideTap()
    },

    componentDidUpdate: function(lastProps, lastState) {
        if (lastState.open !== this.state.open) {
            setTimeout(() => this._setOusideTap())
        }
    },

    componentWillUnmount: function() {
        this.outsideTap.remove()
    },

    '_setOusideTap': function() {
        const elements = [this.target]

        if (this.popper) {
            elements.push(this.popper)
        }

        if (this.outsideTap) {
            this.outsideTap.remove()
        }

        this.outsideTap = outy(
            elements,
            ['mousedown', 'touchend'],
            this._handleOutsideTap
        )
    },

    '_handleOutsideTap': function() {
        this.setState({ open: false })
    },

    Button: function() {
        const { iconButtonElement } = this.props
        const toggle = this.toggle
        const setRef = this.setRef
        return (
            <div style={{ height: '100%' }} ref={function(e) {
                setRef('target', e)
            }}
                 onTouchTap={function(e) {
                     toggle()
                     e.stopPropagation()
                     e.preventDefault()
                 }}
                 onTouchStart={function() {
                 }}
            >
                {iconButtonElement}
            </div>
        )
    },
    Children: function(props) {
        const { children } = this.props
        const { style } = props

        const setRef = this.setRef
        const paperstyle = {
            display: 'flex',
            position: 'relative',
            width: 'auto',
            flexDirection: 'column',
            padding: '8px 0px'
        }

        return (
            <div ref={function(e) {
                setRef('popper', e)
            }} className={style.popper}>
                <Paper zDepth={1} style={paperstyle}>
                    {children}
                </Paper>
            </div>
        )

    },
    refElements: {},
    setRef: function(a, e) {
        this.refElements[a] = e
        if (a === 'popper') {
            this.popper = findDOMNode(e)
        }
        if (a === 'target') {
            this.target = findDOMNode(e)
        }
    },
    render: function() {

        const { style } = this.props
        const { open } = this.state
        const Button = this.Button
        const Children = this.Children

        if (!open) {
            return <Button />
        }

        const renderTo = this.popperRoot
        const boundariesElement = (renderTo) ? renderTo : (typeof window !== undefined) ? window.document.body : null

        return (
            <Manager>
                <Target style={{ height: '100%' }}>
                    <Button />
                </Target>
                <Portal isOpened={true} renderTo={renderTo}>
                    <Popper
                        style={{ zIndex: 2147483647 }}
                        placement={'bottom-start'}
                        eventsEnabled={open}
                        modifiers={{
                            flip: {
                                behavior: ['bottom-end']
                            },
                            preventOverflow: {
                                enable: true,
                                boundariesElement: boundariesElement
                            },
                            wapplrmenu: {
                                enabled: true,
                                'fn': function(data) {
                                    //console.log(data);
                                    return data
                                }
                            }
                        }}
                    >
                        <Children show={open} style={style} />
                    </Popper>
                </Portal>
            </Manager>
        )

    }
})

export default createReactClass({
    getInitialState: function() {
        return {
            openMenu: false,
            preventDefault: null
        }
    },
    componentDidMount: function() {
        const clickableElement = this.refElements['clickableelement']
        if (clickableElement && !this.props.type) {
            this.addEventListener(clickableElement, 'mouseup', this.handleOpenMenu, false)
        }
        if (clickableElement) {
            clickableElement.addEventListener('mouseleave', this.mouseLeave, false)
        }
    },
    componentWillUnmount: function() {
        this.isUnMounted = true
        const clickableElement = this.refElements['clickableelement']

        if (clickableElement && !this.props.type) {
            clickableElement.removeEventListener('mouseup', this.handleOpenMenu, false)
            clickableElement.removeEventListener('touchend', this.handleOpenMenu, false)
        }
        if (clickableElement) {
            clickableElement.removeEventListener('mouseleave', this.mouseLeave, false)
        }
    },

    mouseLeave: function(e) {
        if (this.handleMouseLeave) this.handleMouseLeave(e)
        const iconMenu = this.refElements['iconmenu']
        if (iconMenu && iconMenu.handleMouseLeave) iconMenu.handleMouseLeave(e)
    },
    handleOpenMenu: function(e) {
        if (e.defaultPrevented === true) {
            return
        }

        this.setState({
            preventDefault: e
        })

        const iconMenu = this.refElements['iconmenu']
        if (iconMenu) iconMenu.state.preventDefault = e

        if (this.props.type) {
            e.preventDefault()
        }

    },
    handleMouseLeave: function() {
    },
    handleOnRequestChange: function(value) {
        if (!this.state.openMenu && value || this.state.openMenu && !value) {
            this.setState({
                openMenu: value
            })
        }
    },


    isIOs: function() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    },
    isMobile: function() {
        const md = new MobileDetect(window.navigator.userAgent)
        return md.mobile() || md.tablet() || md.phone()
    },
    isTouchable: function() {
        return 'ontouchstart' in window || 'undefined' !== typeof navigator && navigator.maxTouchPoints
    },
    addEventListener: function(e, t, f, a) {
        const isTouchable = this.isTouchable()
        const isMobile = this.isMobile()
        const touchevents = (isMobile && isTouchable)
        let type = t
        if (touchevents) {
            if (type === 'mouseup') type = 'touchend'
        }
        e.addEventListener(type, f, a)
    },

    refElements: {},
    setRef: function(a, e) {
        this.refElements[a] = e
        if (this.props.setRef) this.props.setRef(a, e)
    },
    getIconMenu: function() {
        return this.refElements['iconmenu']
    },

    render: function() {

        const { children, style, icon, anchorOrgin, targetOrgin, popperRoot, type } = this.props
        const Icon = (icon) ? icon : MoreVertIcon
        const state = this.state

        const getIconMenu = this.getIconMenu
        const setRef = this.setRef
        const IconMenuComponent = (popperRoot) ? IconMenuWithPopper : IconMenu

        const input = {
            ref: function(e) {
                setRef('iconmenu', e)
            },
            open: this.state.openMenu,
            iconButtonElement: <IconButton onTouchStart={function() {
            }}><Icon className={style.navicon} /></IconButton>,
            anchorOrigin: anchorOrgin || { horizontal: 'right', vertical: 'bottom' },
            targetOrigin: targetOrgin || { horizontal: 'right', vertical: 'top' },
            onTouchTap: function(e) {
                if (state.openMenu) {
                    getIconMenu().close()
                }
                e.stopPropagation()
                e.preventDefault()
            },
            onRequestChange: this.handleOnRequestChange
        }

        if (popperRoot) {
            input.popperRoot = popperRoot
            input.style = style
        }

        return (
            <div className={style.naviconcontainer} onClick={(type) ? this.handleOpenMenu : function() {
            }} ref={function(e) {
                setRef('clickableelement', e)
            }}>
                <IconMenuComponent {...input} >
                    {children}
                </IconMenuComponent>
            </div>
        )

    }

})
