import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import Wapplrdialog from '../Wapplrdialog'
import ds from '../Wapplrdialog/Wapplrdialog.css'
import s from '../empty.css'

const Openpostmodal = createReactClass({
    getInitialState() {
        this.refElements = {}
        return {
            isMounted: false
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function(p = {}) {

        const {
            action,
            title,
            text,
            content,
            contentprops,
            submittext,
            submitprops,
            canceltext,
            cancelprops,
            onclosebefore
        } = p;

        const dialog = this.refElements['dialog']

        if (dialog && dialog.dialogOpen) {
            return dialog.dialogOpen({
                action,
                title,
                text,
                content,
                contentprops,
                submittext,
                submitprops,
                canceltext,
                cancelprops,
                onclosebefore
            })
        }

        return {}
    },
    open: function(p) {

        const {
            title = '',
            text = '',
            content = null,
            contentprops = {},
            submittext = 'Submit',
            submitprops = {},
            canceltext = '',
            cancelprops = {},
            onclosebefore,
            action = function({ dialogClose }) {
                dialogClose()
            }
        } = p

        const dialogClose = this.dialogClose
        const dialogOpen = this.dialogOpen

        if (contentprops && typeof contentprops == 'function') {
            contentprops({
                callback: function({ response }) {
                    dialogOpen({
                        title: title,
                        text: text,
                        content: content,
                        contentprops: response,
                        submittext: submittext,
                        submitprops: submitprops,
                        canceltext: canceltext,
                        cancelprops: cancelprops,
                        onclosebefore: onclosebefore,
                        action: function() {
                            action({ dialogClose })
                        }
                    })
                }
            })
        } else {
            dialogOpen({
                title: title,
                text: text,
                content: content,
                contentprops: contentprops,
                submittext: submittext,
                submitprops: submitprops,
                canceltext: canceltext,
                cancelprops: cancelprops,
                onclosebefore: onclosebefore,
                action: function() {
                    action({ dialogClose })
                }
            })
        }
    },
    render: function() {
        const { style } = this.props
        const setRef = this.setRef

        return (
            <div>
                <Wapplrdialog
                    setRef={setRef}
                    className={style.postmodal}
                    bodyClassName={style.postmodalbody}
                    autoScrollBodyContent={true}
                    style={ds}
                />
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Openpostmodal {...this.props} ref={(setRef) ? function(e) {
                setRef('openpostmodal', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {

        const { style = s, disableInitWithStyles } = this.props
        let R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
