import React from 'react'
import store from 'store'

import LabelIcon from 'material-ui/svg-icons/action/label'
import FolderIcon from 'material-ui/svg-icons/file/folder'
import ExtensionIcon from 'material-ui/svg-icons/action/extension'

export default function({ localstorename = 'wapplr' }) {

    const menus = {}

    function menuRefs(a, e) {
        menus[a] = e
    }

    function getMenu(a) {
        return menus[a]
    }

    let menuDataObjects = {}

    function getMenuDataObject(a, defaultStoredData) {

        menuDataObjects[a] = {
            dataSource: {},
            dataCurrent: {
                drawerOpen: false,
                narrow: false
            }
        }

        const storedname = localstorename + '_wapplrmenu_' + a
        const storeddata = store.get(storedname)

        if (storeddata && storeddata.dataCurrent && storeddata.dataCurrent.drawerOpen ||
            storeddata && storeddata.dataCurrent && storeddata.dataCurrent.openmenus ||
            storeddata && storeddata.dataCurrent && storeddata.dataCurrent.drawerScrollTop) {
            menuDataObjects[a].dataCurrent.storedDrawerOpen = storeddata.dataCurrent.drawerOpen || false
            menuDataObjects[a].dataCurrent.openmenus = storeddata.dataCurrent.openmenus || {}
            menuDataObjects[a].dataCurrent.drawerScrollTop = storeddata.dataCurrent.drawerScrollTop || 0
        } else {
            if (defaultStoredData) menuDataObjects[a].dataCurrent = defaultStoredData
        }

        let menuData = {
            getDataSource: function() {
                return menuDataObjects[a].dataSource
            },
            getDataCurrent: function() {
                return menuDataObjects[a].dataCurrent
            },
            setDataCurrent: function(b, v) {
                menuDataObjects[a].dataCurrent[b] = v
                store.set(storedname, menuDataObjects[a])
            },
            setData: function(b) {
                menuDataObjects[a] = b
                return menuData
            }
        }
        return menuData
    }

    const icons = {
        label: LabelIcon,
        folder: FolderIcon,
        extension: ExtensionIcon
    }

    function menuFromTaxonomiesRecursive(data, currentTaxonomy, historyHelpers, Defaulticon, posttype, maxdeep, deep, disableLinkIfDropAndDown, allLabel = 'All') {
        const p = []
        const go = (!maxdeep) ? true : (maxdeep && maxdeep > deep)
        if (data && data.length && go) {
            data.map(function(d) {

                const sendFirstItemToItems = (disableLinkIfDropAndDown && d.items) ? {
                    name: allLabel,
                    link: historyHelpers.getTaxonomyLink(currentTaxonomy, d, posttype).link,
                    lefticon: (Defaulticon) ? <Defaulticon /> : null
                } : null
                const items = (d.items) ? menuFromTaxonomiesRecursive(d.items, currentTaxonomy, historyHelpers, Defaulticon, posttype, maxdeep, deep + 1, disableLinkIfDropAndDown, allLabel) : null

                p.push({
                    name: d.name,
                    link: (disableLinkIfDropAndDown && d.items) ? null : historyHelpers.getTaxonomyLink(currentTaxonomy, d, posttype).link,
                    lefticon: (Defaulticon) ? <Defaulticon /> : null,
                    items: (items) ? (sendFirstItemToItems) ? [sendFirstItemToItems, ...items] : items : null
                })
            })
            p.push({ divider: 1, inset: true })
        }
        return p
    }

    function menuFromTaxonomies({
                                    taxonomies,
                                    historyHelpers,
                                    posttypename = '',
                                    posttypepath = '',
                                    maxdeep = 0,
                                    disableLinkIfDropAndDown,
                                    allLabel
                                }) {

        const moft = []
        Object.keys(taxonomies).forEach(function(key) {

            let go = false
            if (!posttypename) go = true
            if (taxonomies[key].posttype) {
                taxonomies[key].posttype.map(function(p) {
                    if (p === posttypename) go = true
                })
            }

            if (go) {
                const data = taxonomies[key].items
                const Defaulticon = (taxonomies[key].defaulticon && icons[taxonomies[key].defaulticon]) ? icons[taxonomies[key].defaulticon] : null
                moft.push(...menuFromTaxonomiesRecursive(data, taxonomies[key], historyHelpers, Defaulticon, posttypepath, maxdeep, 0, disableLinkIfDropAndDown, allLabel))
            }

        })
        return moft
    }

    function menuFromPostTypes({ posttypes, taxonomies, historyHelpers }) {
        const mofp = []
        Object.keys(posttypes).forEach(function(key) {

            const posttypepath = posttypes[key].path
            const posttypename = posttypes[key].name
            const posttypepluralname = posttypes[key].pluralname
            const Posttypedefaulticon = (posttypes[key].defaulticon && icons[posttypes[key].defaulticon]) ? icons[posttypes[key].defaulticon] : null

            mofp.push(
                { divider: 1 },
                { name: posttypepluralname, link: '/' + posttypepath },
                {
                    name: 'My ' + posttypepluralname,
                    link: function(props) {
                        const { sendprops = {} } = props
                        const { user } = sendprops
                        return (user && user.id) ? '/' + posttypepath + '?user=' + user.id : ''
                    },
                    role: function(props) {
                        const { sendprops = {} } = props
                        const { user } = sendprops
                        return (user && user.id)
                    },
                    lefticon: <Posttypedefaulticon />
                }, { divider: 1, inset: true })

            mofp.push(...menuFromTaxonomies({ taxonomies, historyHelpers, posttypename, posttypepath }))
            mofp.push({ divider: 1 })

        })
        return mofp
    }

    return { menuRefs, getMenu, getMenuDataObject, menuFromTaxonomies, menuFromPostTypes }
}
