import React from 'react'
import createReactClass from 'create-react-class'

import LinkComponent from './LinkComponent'
import A from './A'

export default createReactClass({
    render: function() {
        const { logo, logohref } = this.props
        const input = { ...this.props, A: A }

        if (logo && logohref) return E.logo1(input)
        if (logo) return E.logo2(input)
        return null

    }
})

const E = {
    logo1: function(props) {
        const { style, setRef, searchData, parentroute, logohref, history, A, logo } = props

        const href = (typeof logohref == 'function') ? logohref(props) : parentroute + logohref

        return (
            <div className={style.logo} ref={function(e) {
                setRef('logo', e)
            }}>
                <LinkComponent to={href} searchData={searchData} history={history} Menuitem={A} className={style.a}>
                    {logo}
                </LinkComponent>
            </div>
        )
    },
    logo2: function(props) {
        const { style, setRef, logo } = props
        return (
            <div className={style.logo} ref={function(e) {
                setRef('logo', e)
            }}>
                <div className={style.a}>
                    {logo}
                </div>
            </div>
        )
    }
}
