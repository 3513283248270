import FastClick from 'fastclick'

import {
    beforeRun as beforerun,
    locationChangeCallback as locationchangecallback,
    runInitial as runinitial
} from './export.hooks.js'

import { runInitial as vendorRunInitial } from '../vendor/hooks'

export default {
    locationChangeCallback: function(p) {
        locationchangecallback(p)
    },
    runInitial: function(p) {
        runinitial(p)
        if (vendorRunInitial) vendorRunInitial()
    },
    beforeRun: function(p) {
        beforerun(p)
        FastClick.attach(document.body)
    }
}
