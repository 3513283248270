import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import Slick from 'react-swipeable-views'
import s from './slider.css'

import IconButton from 'material-ui/IconButton'
import ArrowLeft from 'material-ui/svg-icons/hardware/keyboard-arrow-left'
import ArrowRight from 'material-ui/svg-icons/hardware/keyboard-arrow-right'

const Slider = createReactClass({
    getInitialState: function() {
        const { slideIndex = 0, slides = [] } = this.props
        return {
            slideIndex,
            slides
        }
    },
    getSlide: function(slide, i) {
        const Component = slide.component
        const props = slide.props
        return <Component {...props} key={i} />
    },
    handleChange: function(e, a) {
        console.log(e)
        console.log(a)
    },
    nextSlide: function() {
        const state = this.state
        const { slideIndex, slides } = state
        let newSlideIndex = slideIndex + 1
        if (newSlideIndex > (slides.length - 1)) newSlideIndex = 0
        if (state.slideIndex !== newSlideIndex) {
            this.setState({
                slideIndex: newSlideIndex
            })
        }
    },
    prevSlide: function() {
        const state = this.state
        const { slideIndex, slides } = state
        let newSlideIndex = slideIndex - 1
        if (newSlideIndex < 0) newSlideIndex = slides.length - 1
        if (state.slideIndex !== newSlideIndex) {
            this.setState({
                slideIndex: newSlideIndex
            })
        }
    },
    setById: function(id) {
        const { slides } = this.props
        let foundSlide = false
        {
            slides.map(function(slide, i) {
                if (slide.id && slide.id === id) foundSlide = i
            })
        }
        if (foundSlide !== false && this.state.slideIndex !== foundSlide) {
            this.setState({
                slideIndex: foundSlide
            })
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        if (type === 'next') {
            this.nextSlide()
        }
        if (type === 'prev') {
            this.prevSlide()
        }
        e.preventDefault()
    },
    render: function() {

        const { style } = this.props
        const state = this.state
        const { slideIndex, slides } = state
        const getSlide = this.getSlide
        const onChangeIndex = this.handleChange
        const touchEnd = this.touchEnd

        return (
            <div>
                {(slides && slides.length) ?
                    <div>
                        <div className={style.buttonsdiv}>
                            <div className={style.nextprevbuttons}>
                                <IconButton
                                    onTouchTap={function(e) {
                                        touchEnd(e, 'click', 'click', { type: 'prev' })
                                    }}
                                    onTouchStart={function() {

                                    }}
                                >
                                    <ArrowLeft />
                                </IconButton>
                            </div>
                            <div className={style.nextprevbuttons}>
                                <IconButton
                                    onTouchTap={function(e) {
                                        touchEnd(e, 'click', 'click', { type: 'next' })
                                    }}
                                    onTouchStart={function() {

                                    }}
                                >
                                    <ArrowRight />
                                </IconButton>
                            </div>
                        </div>
                        <Slick index={slideIndex} onChangeIndex={onChangeIndex}>
                            {slides.map(function(slide, i) {
                                return getSlide(slide, i)
                            })}
                        </Slick>
                    </div>
                    : null
                }
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Slider {...this.props} ref={(setRef) ? function(e) {
                setRef('slider', e)
            } : null} />
        )
    }
})


export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
