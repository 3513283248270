import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import Wapplr from "../../../components/Wapplr"

let screenfull = {};

const CharliesPlayer = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        this.data = {}
        return {
            isMounted: true,
            screenfullEnabled: false
        }
    },
    componentDidMount: function() {
        screenfull = require('screenfull').default;
        if (screenfull.isEnabled) {
            screenfull.on('change', this.onFullscreenChange)
            this.setState({
                screenfullEnabled: true
            })
        }
    },
    componentWillUnmount: function() {
        if (screenfull.isEnabled) {
            screenfull.off('change', this.onFullscreenChange)
        }
    },
    updateDivRender: function(div) {
        if (div) {
            div.style.display = 'none'

            setTimeout(function() {
                div.style.display = 'flex'
                //console.log("update flex render");
            }, 100)
        }
    },
    onFullscreenChange: function() {
        const isFullscreen = !!screenfull.isFullscreen
        const container = this.refElements['charliescontainer']
        const div = this.getElement(container)

        if (div && screenfull.isEnabled) {

            const controls = this.refElements['controls']

            if (isFullscreen) {
                if (controls) controls.setData({ fullscreen: true })
            } else {
                if (controls) controls.setData({ fullscreen: false })
                this.updateDivRender(div)
            }

        }
    },
    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    setData: function() {
        const { data } = this.props
        return (data && typeof data == 'string') ? JSON.parse(data) : data
    },
    getData: function(p = {}) {
        const { callback } = p
        const app = this.refElements['app']
        if (app.getData) return app.getData({ callback })
        return this.setData()
    },
    onFullScreen: function(e, data = {}) {
        const { fullscreen } = data
        const nextstateIsFullscreen = !fullscreen
        const container = this.refElements['charliescontainer']
        const div = this.getElement(container)

        if (div && screenfull.isEnabled) {
            if (nextstateIsFullscreen) {
                screenfull.toggle(div)
            } else {
                screenfull.toggle(div)
            }
        }

    },
    getStore: function() {
        const data = this.data
        return {
            getState: function() {
                //console.log("Get state", data);
                return data
            },
            setState: function(a) {
                //console.log("Prev state",data, "Next state",a);
                if (a && typeof a == 'object') {
                    Object.keys(a).map(function(key) {
                        data[key] = a[key]
                    })
                }
            }
        }
    },

    render: function() {

        const {
            app,
            style,
            containerStyle = {},
            state,
            parentroute,
            disablehistory,
            customprops = {}
        } = this.props

        const setRef = this.setRef
        const refElements = this.refElements

        const hs = { height: 'auto', ...containerStyle }
        const className = style.player

        const data = this.setData()
        const onFullScreen = this.onFullScreen
        const store = this.getStore()

        return (
            <div className={className} style={hs} ref={function(e) {
                setRef('charliescontainer', e)
            }}>
                <Wapplr
                    setRef={setRef}
                    refElements={refElements}
                    app={app}
                    state={state}
                    store={store}
                    data={data}
                    parentroute={parentroute}
                    disableHistory={disablehistory}
                    {...customprops}
                    onFullScreen={onFullScreen}
                    disablefullscreen={!!(customprops.disablefullscreen || !screenfull.isEnabled)}
                />
            </div>
        )
    }


})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <CharliesPlayer {...this.props} ref={(setRef) ? function(e) {
                setRef('charliesplayer', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})

