import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'
import ReactDOM from 'react-dom'

import { Card, CardActions, CardMedia, CardText, CardTitle } from 'material-ui/Card'
import GridList from 'material-ui/GridList'

import IconButton from 'material-ui/IconButton'
import DoneIcon from 'material-ui/svg-icons/action/done'

import WapplrAvatar from '../Wapplravatar'
import wapplravatarhelpers from '../Wapplravatar/helpers'

import s from '../empty.css'

const CardComponent = createReactClass({
    getInitialState() {
        return {
            selected: !!(this.props.selectable && this.props.selected)
        }
    },
    deselect: function() {
        this.setState({
            selected: false
        })
    },
    parentState: function(selected) {

        const { selectsimple, data, state, searchData, allcard } = this.props
        const selectedSearchData = state.searchData

        if (selectedSearchData && data && data.id || selectedSearchData && data && data.id === 0) {

            if (selectsimple) {
                Object.keys(selectedSearchData).map(function(id) {
                    if (id !== data.id && selectedSearchData[id]) {
                        selectedSearchData[id] = null
                        if (allcard[id] && allcard[id].deselect) allcard[id].deselect()
                    }
                })
            }

            selectedSearchData[data.id] = (selected) ? { id: data.id } : null
            if (searchData) searchData.setDataCurrent(this.props.searchObjectName, selectedSearchData)

        }
    },
    touchEnd: function(e, fn, pt) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e)
        }
    },
    select: function() {
        const { state } = this.props
        if (this.props.selectable) {
            this.parentState(!this.state.selected)
            this.setState({
                selected: !this.state.selected
            })
            if (this.props.onSelect) this.props.onSelect({ posts: state.searchData })
            if (this.props.historyPush) this.props.historyPush()
        }
    },
    click: function(e) {
        const { onClick, data, clickIsSelect, selectable, state } = this.props

        const selectedSearchData = state.searchData
        const foundSelected = Object.keys(selectedSearchData).find(function(id) {
            return selectedSearchData[id]
        })

        if (clickIsSelect && selectable || foundSelected) {
            e.preventDefault()
            this.select()
            return
        }
        if (onClick) {
            onClick(e, data)
        } else {
            const search = this.searchQuery
            const pathname = this.permalink
            this.historyPush(search, pathname)
        }
        if (e) e.preventDefault()
    },
    componentDidMount: function() {
        this.setHistoryHelpers()
    },
    historyPush: function(search, path) {
        const historyHelpers = this.historyHelpers

        const push = { to: this.props.to, hash: this.props.hash }
        if (search) push.search = search
        if (path) push.to = push.to + path

        historyHelpers.historyPush(push)
    },
    setHistoryHelpers: function() {
        const { searchData } = this.props
        if (!this.historyHelpers) {
            this.historyHelpers = (searchData && searchData.getHistoryHelpers) ? searchData.getHistoryHelpers() : null
        }
    },
    setSearchQuery: function() {
        const { author, searchObjectName, posttypepath, data, enablePermalink } = this.props
        this.setHistoryHelpers()

        if (data && data.link && data.link) {
            this.searchQuery = data.link.search || ''
        } else {
            if (this.historyHelpers) {
                const searchQueryObj = this.historyHelpers.getTaxonomyLink({
                    slug: searchObjectName,
                    pluralslug: searchObjectName
                }, data, posttypepath)
                this.searchQuery = searchQueryObj.search
                if (enablePermalink) {
                    this.permalink = (this.searchQuery) ? '/' + this.searchQuery.split('=')[1] : ''
                    this.searchQuery = ''
                }
                if (author) this.searchQuery = (this.searchQuery) ? this.searchQuery + '&' + this.historyHelpers.getAuthorSearch(author) : this.historyHelpers.getAuthorSearch(author)
            }
        }
    },
    getLink: function() {
        const { to, enablePermalink, data } = this.props
        let link = to + '?' + this.searchQuery
        if (enablePermalink) link = to + this.permalink

        if (data && data.link) {
            const pathname = (data.link.pathname) ? data.link.pathname : ''
            const search = (data.link.search) ? data.link.search : ''
            this.permalink = pathname
            link = (!search) ? to + pathname : to + pathname + '?' + search
        }

        return link
    },
    getLinkTarget: function() {
        const { data, linkstarget } = this.props
        if (linkstarget) return linkstarget
        if (data && data.link && data.link.target) return data.link.target
        return null
    },
    getTitleContent() {

        const { enableTitleHref, getTitle, data, publicicon, style } = this.props

        const link = this.getLink()
        const linktarget = this.getLinkTarget()
        const touchEnd = this.touchEnd

        const publiciconcontent = (publicicon) ? publicicon({ post: data, ...this.props }) : null
        let title = data && data.title || data && data.name
        if (typeof getTitle == 'function') {
            title = getTitle({ post: data, ...this.props })
        }

        if (title) {
            if (publiciconcontent) {

                if (enableTitleHref) {
                    return (
                        <div className={style.cardlefticoncont}>
                            {(publiciconcontent) ?
                                <div className={style.cardleftbutton}>{publiciconcontent}</div> : null}
                            <a href={link}
                               target={linktarget}
                               onClick={(linktarget !== '_blank') ? function(e) {
                                   touchEnd(e, 'click', 'click')
                               } : null}
                               onTouchStart={(linktarget !== '_blank') ? function() {
                               } : null}>
                                {title}
                            </a>
                        </div>
                    )
                } else {
                    return (
                        <div className={style.cardlefticoncont}>
                            {(publiciconcontent) ?
                                <div className={style.cardleftbutton}>{publiciconcontent}</div> : null}
                            <div className={style.titletext}>{title}</div>
                        </div>
                    )
                }

            } else {

                if (enableTitleHref) {
                    return (
                        <a className={style.titletext}
                           href={link}
                           target={linktarget}
                           onClick={(linktarget !== '_blank') ? function(e) {
                               touchEnd(e, 'click', 'click')
                           } : null}
                           onTouchStart={(linktarget !== '_blank') ? function() {
                           } : null}>
                            {title}
                        </a>
                    )
                } else {
                    return (
                        <div className={style.titletext}>{title}</div>
                    )
                }

            }
        } else {
            return null
        }

    },
    getAvatarClassName: function(p = {}) {
        const { getavatarclassname, data } = this.props
        let thumb = data.cover || data.thumb
        const { style } = p
        let avatarClass = (thumb) ? style['feed'] : style['marginnull']
        if (getavatarclassname) {
            avatarClass = getavatarclassname({ avatarProps: p, className: avatarClass, ...this.props })
        }
        return avatarClass
    },
    render() {

        const {
            data, style, avatarstyle, selectable, enableThumbHref, showauthor,
            enableUserLink, history, deleteicon, restoreicon, editicon,
            duplicateicon, contentcomponent, getthumb, getthumbstyle, thumbheight = {}, SelectIcon = DoneIcon, gallery
        } = this.props

        const touchEnd = this.touchEnd
        const user = (showauthor && data && data.author && wapplravatarhelpers({ user: data.author })) ? data.author : null
        let thumb = data && data.cover || data && data.thumb
        if (thumb && getthumb) thumb = getthumb({ url: thumb, ...this.props })

        const deleteiconcontent = (deleteicon) ? deleteicon({ post: data, ...this.props }) : null
        const restoreiconcontent = (restoreicon) ? restoreicon({ post: data, ...this.props }) : null
        const editiconcontent = (editicon) ? editicon({ post: data, ...this.props }) : null
        const duplicateiconcontent = (duplicateicon) ? duplicateicon({ post: data, ...this.props }) : null
        const contentcomponentcontent = (contentcomponent) ? contentcomponent({ post: data, ...this.props }) : null
        const thumbstyle = (getthumbstyle) ? getthumbstyle({ post: data, ...this.props }) : {}

        this.setSearchQuery()
        const link = this.getLink()
        const linktarget = this.getLinkTarget()

        const titlecontent = this.getTitleContent()
        const ts = (data && data.thumbstyle) ? (data && typeof data.thumbstyle == 'string') ? JSON.parse(data.thumbstyle) : data.thumbstyle : {}
        const rthumbstyle = { 'backgroundImage': 'url(' + thumb + ')', ...thumbheight, ...ts, ...thumbstyle }

        const avatarClassName = this.getAvatarClassName

        if (data && data.html) {
            return (
                <Card
                    className={(this.state.selected) ? style.card + ' ' + style.selected : style.card + ' ' + style.noselected}>
                    {data.html}
                </Card>
            )
        }

        if (data && data.component) {
            const C = data.component
            const compprops = data && data.props || {}

            const feedprops = (compprops.feedprops) ? { ...compprops.feedprops } : {}

            return (
                <Card
                    className={(this.state.selected) ? style.card + ' ' + style.selected : style.card + ' ' + style.noselected}>
                    <C {...compprops} feedprops={feedprops} />
                </Card>
            )
        }

        if (data) {

            return (
                <Card
                    className={(this.state.selected) ? style.card + ' ' + style.selected : style.card + ' ' + style.noselected}>
                    {(selectable) ?
                        <CardText className={style.selecticoncont}>
                            <div className={style.selecticon}>
                                <IconButton label='Clear' onTouchTap={function(e) {
                                    touchEnd(e, 'select')
                                }} onTouchStart={function() {
                                }}>
                                    <SelectIcon />
                                </IconButton>
                            </div>
                        </CardText>
                        : null
                    }

                    <div className={(!thumb && !user) ? style.cardleft + ' ' + style.cardlefthide : style.cardleft}>
                        {(thumb) ?
                            (enableThumbHref) ?
                                <a className={style.cardthumb} style={thumbheight} href={link}
                                   target={linktarget}
                                   onClick={(linktarget !== '_blank') ? function(e) {
                                       touchEnd(e, 'click', 'click')
                                   } : null}
                                   onTouchStart={(linktarget !== '_blank') ? function() {
                                   } : null}>
                                    <CardMedia>
                                        <img src={thumb} style={thumbheight} alt={''} />
                                        {(data.thumbhtml) ?
                                            <div>
                                                <div className={style.thumbhtml} style={rthumbstyle}
                                                     dangerouslySetInnerHTML={{ __html: data.thumbhtml }} />
                                                <div className={style.thumbhtmllayer} />
                                            </div>
                                            :
                                            <div className={style.thumbimg} style={rthumbstyle} />
                                        }
                                    </CardMedia>
                                </a>
                                :
                                <CardMedia className={style.cardthumb} style={thumbheight}>
                                    <img src={thumb} style={thumbheight} alt={''} />
                                    {(data.thumbhtml) ?
                                        <div>
                                            <div className={style.thumbhtml} style={rthumbstyle}
                                                 dangerouslySetInnerHTML={{ __html: data.thumbhtml }} />
                                            <div className={style.thumbhtmllayer} />
                                        </div>
                                        :
                                        <div className={style.thumbimg} style={rthumbstyle} />
                                    }
                                </CardMedia>
                            :
                            null
                        }
                        {(user) ? <WapplrAvatar user={user} style={avatarstyle} type='list' className={avatarClassName}
                                                targetBlank={(linktarget === '_blank')} enableUserLink={enableUserLink}
                                                history={history} getthumb={getthumb}
                                                getthumbstyle={getthumbstyle} /> : null}
                    </div>
                    {(!gallery) ?
                        <div className={style.cardright}>
                            {(titlecontent || data.subtitle || editiconcontent || duplicateiconcontent || deleteiconcontent || restoreiconcontent || data.content_brief || data.cardActions) ?
                                <div className={style.carddetails}>
                                    {(titlecontent || data.subtitle || editiconcontent || duplicateiconcontent || deleteiconcontent || restoreiconcontent) ?
                                        <div className={style.cardtitlebox}>
                                            <div className={style.cardtitle}>
                                                <CardTitle title={titlecontent} subtitle={data.subtitle} />
                                            </div>
                                            {(editiconcontent) ?
                                                <div className={style.cardrightbutton}>{editiconcontent}</div> : null}
                                            {(duplicateiconcontent) ?
                                                <div
                                                    className={style.cardrightbutton}>{duplicateiconcontent}</div> : null}
                                            {(deleteiconcontent) ?
                                                <div className={style.cardrightbutton}>{deleteiconcontent}</div> : null}
                                            {(restoreiconcontent) ?
                                                <div
                                                    className={style.cardrightbutton}>{restoreiconcontent}</div> : null}
                                        </div>
                                        : null
                                    }
                                    {(data.content_brief) ?
                                        <CardText>
                                            <div className={style.contentbrief}
                                                 dangerouslySetInnerHTML={{ __html: data.content_brief }} />
                                        </CardText>
                                        : null
                                    }
                                    {(contentcomponentcontent) ?
                                        <div className={style.contentcomponent}>{contentcomponentcontent}</div> : null}
                                    {(data.cardActions) ? <CardActions /> : null}
                                </div> : null
                            }
                        </div>
                        :
                        <div>
                            {(editiconcontent) ? <div className={style.cardrightbutton}>{editiconcontent}</div> : null}
                            {(duplicateiconcontent) ?
                                <div className={style.cardrightbutton}>{duplicateiconcontent}</div> : null}
                            {(deleteiconcontent) ?
                                <div className={style.cardrightbutton}>{deleteiconcontent}</div> : null}
                            {(restoreiconcontent) ?
                                <div className={style.cardrightbutton}>{restoreiconcontent}</div> : null}
                        </div>
                    }

                </Card>
            )

        }

        return null;
    }
})

const InnerGrid = createReactClass({
    refElements: {},
    setRef: function(a, e, disableoutside) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef && !disableoutside) setRef(a, e)
    },
    render() {

        const {
            data,
            style,
            avatarstyle,
            state,
            searchData,
            history,
            to,
            tstate,
            realselectable,
            historyPush,
            enableTitleHref,
            getTitle,
            enableThumbHref,
            searchObjectName,
            onSelect,
            showauthor,
            enableUserLink,
            author,
            posttypepath = '',
            enablePermalink,
            deleteicon,
            contentcomponent,
            restoreicon,
            editicon,
            duplicateicon,
            publicicon,
            selectsimple,
            clickIsSelect,
            getthumb,
            getthumbstyle,
            thumbheight,
            onClick,
            linkstarget,
            getavatarclassname
        } = this.props

        let className = (!state.showContent) ? style.hidden : ''
        let gridListClassName = style.gridlist + ' ' + style[state.className]
        if (state.gallery) gridListClassName = gridListClassName + ' ' + style['gallery']

        const setRef = this.setRef
        const refElements = this.refElements

        return (
            <div ref={function(e) {
                setRef('gridlistContainer', e)
            }} className={className}>
                <GridList
                    ref={function(e) {
                        setRef('gridlist', e)
                    }}
                    cols={3}
                    padding={16}
                    cellHeight={'auto'}
                    className={gridListClassName}
                >

                    {(state.isMounted && data) ? data.map(function(f, index) {
                            return <CardComponent
                                ref={function(e) {
                                    if (f) {
                                        setRef(f.id, e, true)
                                    }
                                }}
                                allcard={refElements}
                                showauthor={showauthor}
                                enableUserLink={enableUserLink}
                                key={index}
                                data={f}
                                style={style}
                                avatarstyle={avatarstyle}
                                state={tstate}
                                history={history}
                                to={to}
                                searchData={searchData}
                                searchObjectName={searchObjectName}
                                enableTitleHref={enableTitleHref}
                                getTitle={getTitle}
                                enableThumbHref={enableThumbHref}
                                selectable={realselectable}
                                onSelect={onSelect}
                                selected={!!(realselectable && tstate.searchData[f.id])}
                                selectsimple={selectsimple}
                                clickIsSelect={clickIsSelect}
                                historyPush={historyPush}
                                author={author}
                                posttypepath={posttypepath}
                                enablePermalink={enablePermalink}
                                deleteicon={deleteicon}
                                restoreicon={restoreicon}
                                editicon={editicon}
                                duplicateicon={duplicateicon}
                                contentcomponent={contentcomponent}
                                publicicon={publicicon}
                                getthumb={getthumb}
                                getthumbstyle={getthumbstyle}
                                getavatarclassname={getavatarclassname}
                                thumbheight={thumbheight}
                                gallery={state.gallery}
                                onClick={onClick}
                                rootClassName={state.className}
                                containerWidth={state.width}
                                linkstarget={linkstarget}
                            />
                    }) : null}
                </GridList>
            </div>
        )
    }
})


const GridComponent = createReactClass({
    getInitialState() {
        return {
            searchData: null
        }
    },
    historyPush: function() {
        if (this.props.history && this.props.enableLocation) {
            const history = this.props.history
            const to = this.props.to
            const parentroute = this.props.parentroute
            if (to && history && history.location.pathname === to ||
                parentroute && history && history.location.pathname === parentroute) {
                if (history && to) {
                    this.historyHelpers.objectToSearch(to)
                }
            }
        }
    },
    componentDidMount: function() {

        const { history, to, enableLocation } = this.props

        if (history && enableLocation) {

            let pathname = (history.location) ? history.location.pathname : ''

            if (pathname === to) {
                this.historyHelpers.resetToDefaults()
                this.historyHelpers.refreshFromSearch()
            }
        }
    },
    searchLocationIsEmpty: function() {
        const history = this.props.history
        return (!(history && history.location && history.location.search))
    },
    render() {
        const { selectable, searchData = {}, searchObjectName = '' } = this.props

        const currentData = (searchData.getDataCurrent) ? searchData.getDataCurrent() : null

        const tstate = this.state
        const realselectable = (selectable && currentData && searchObjectName && currentData[searchObjectName])
        tstate.searchData = realselectable ? currentData[searchObjectName] : {}

        this.historyHelpers = (searchData.getHistoryHelpers) ? searchData.getHistoryHelpers() : null
        const historyPush = this.historyPush

        const input = { ...this.props, searchData, searchObjectName, tstate, realselectable, historyPush }

        return <InnerGrid {...input} />
    }
})

export default createReactClass({
    getInitialState() {

        const { type, isMounted = false, showContent = false, dimensionClassName = 'bp0' } = this.props

        return {
            isMounted: isMounted,
            showContent: showContent,
            className: dimensionClassName,
            items: [],
            width: 1920,
            gallery: (type === 'gallery')
        }
    },
    lastWidth: 0,
    gridlistWidth: 0,
    setRef: function(a, e) {
        if (!this.state) this.state = {}
        if (!this.state.items) this.state.items = {}
        this.state.items[a] = e
    },
    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    getDimensions: function() {

        const { type, forceClassNameToDisableChangeColums } = this.props

        const gridlist = this.getElement(this.state && this.state.items && this.state.items.gridlist)
        let width = ('undefined' == typeof window) ? 1920 : window.innerWidth
        if (gridlist) width = gridlist.offsetWidth

        let className = 'bp0'
        if (width <= 800) className = 'bp1'
        if (type === 'list') className = 'list'
        if (width <= 640) className = 'bp2'
        if (forceClassNameToDisableChangeColums) {
            if (typeof forceClassNameToDisableChangeColums == 'function') {
                className = forceClassNameToDisableChangeColums({ className })
            } else {
                className = forceClassNameToDisableChangeColums
            }
        }

        if (this.state && this.state.isMounted) {
            return {
                className: className,
                width: width,
                showContent: true
            }
        }

        return { width: this.lastWidth }

    },
    resizeListener: function() {
        const gridlist = this.getElement(this.state && this.state.items && this.state.items.gridlist)
        if (gridlist && gridlist.offsetWidth !== this.gridlistWidth) {
            this.gridlistWidth = gridlist.offsetWidth
            this.updateDimensions()
        }
    },
    resizeListenerCatchOn: function() {
        this.resizeListener()
        setTimeout(this.resizeListener, 500)
    },
    updateDimensions: function() {
        const newStates = this.getDimensions()
        if (!this.state) this.state = {}
        if (newStates.width !== this.lastWidth && this.state && this.state.isMounted) {
            if (newStates.className !== this.state.className ||
                newStates.showContent !== this.state.showContent) {
                this.lastWidth = newStates.width

                if (this.props.feedData) this.props.feedData.setDataCurrent('className', newStates.className)
                if (this.props.feedData) this.props.feedData.setDataCurrent('showContent', newStates.showContent)

                this.setState(newStates)
            }
        }
    },
    componentDidMount: function() {

        const { disableDimensions = false } = this.props

        if (!this.state.isMounted) {
            this.state.isMounted = true
            this.setState({ isMounted: true })
        }

        if (!disableDimensions) {
            window.addEventListener('resize', this.updateDimensions)
            document.addEventListener('mouseup', this.resizeListenerCatchOn)
            setTimeout(this.updateDimensions, 0)
            setTimeout(this.updateDimensions, 500)
        }
    },
    componentWillUnmount: function() {
        if (this.state.isMounted) {
            this.state.isMounted = false
            this.setState({
                isMounted: false,
                showContent: false
            })
        }
        window.removeEventListener('resize', this.updateDimensions)
        document.removeEventListener('mouseup', this.resizeListenerCatchOn)
    },
    render: function() {
        const { style = s, disableInitWithStyles, feedData } = this.props

        this.state.showContent = (feedData) ? feedData.getDataCurrent().showContent : this.state.showContent
        this.state.className = (feedData) ? feedData.getDataCurrent().className : this.state.className

        this.style = style
        const R = (style && !disableInitWithStyles) ? withStyles(style)(GridComponent) : GridComponent
        const input = { ...this.props, style, state: this.state, setRef: this.setRef }

        return (
            <R {...input} />
        )
    }
})

