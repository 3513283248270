export default function setContents(p = {}) {

    const { wapp } = p

    wapp.router.replace([
        { path: '/', status: 404 }
    ])

}

export async function contentsMiddleware(req) {
}
