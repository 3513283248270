import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'
import Avatar from 'material-ui/Avatar'
import helpers from './helpers'

const AvatarComponent = createReactClass({
    getInitialState() {
        const { user } = this.props
        if (user) this.setUserProto({ user })
        return {
            isMounted: false,
            user: this.props.user || null,
            type: this.props.type || 'menu'
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        this.historyPush(d)
        if (e) e.preventDefault()
    },
    historyPush: function(d) {
        const { history } = this.props
        const historyHelpers = this.historyHelpers
        if (historyHelpers) {
            historyHelpers.historyPush(d)
        } else {
            if (history) {
                const o = d.split('?')
                history.push({
                    pathname: o[0],
                    search: o[1]
                })
            }
        }
    },
    setUser: function({ user }) {
        const ruser = user
        if (ruser) this.setUserProto({ ruser })
        this.setState({
            user: ruser
        })
    },
    componentDidMount: function() {
        const { searchData } = this.props
        this.historyHelpers = (searchData) ? searchData.getHistoryHelpers() : null

        this.setState({
            isMounted: true
        })
    },
    getName: function() {
        const businessname = (this.state.user && this.state.user.businessname) ? this.state.user.businessname : ''
        if (businessname) return businessname
        const firstname = (this.state.user && this.state.user.name && this.state.user.name.first) ? this.state.user.name.first : ''
        const lastname = (this.state.user && this.state.user.name && this.state.user.name.last) ? this.state.user.name.last : ''
        return (firstname && lastname) ? firstname + ' ' + lastname : (firstname) ? firstname : lastname
    },
    getLink: function() {
        const id = (this.state.user && this.state.user.id) ? this.state.user.id : 0
        if (id) return '/user/' + id
        return ''
    },
    setUserProto: function({ user }) {
        if (!user.getName) helpers({ user })
        this.getName = user.getName
        this.setLink = user.setLink
    },
    getN: function({ name }) {
        const businessname = (this.state.user && this.state.user.businessname) ? this.state.user.businessname : ''
        if (businessname) return businessname.slice(0, 1)
        const firstname = (this.state.user && this.state.user.name && this.state.user.name.first) ? this.state.user.name.first : ''
        return (firstname) ? firstname.slice(0, 1) : (name) ? name.slice(0, 1) : ''
    },
    getStyle: function() {
        const { getthumb, getthumbstyle } = this.props
        let avatar = (this.state.user && this.state.user.avatar) ? this.state.user.avatar : ''
        if (avatar && getthumb) avatar = getthumb({ url: avatar, user: this.state.user })
        const avatarstyle = (avatar) ? { backgroundImage: 'url(\'' + avatar + '\')' } : null
        const thumbstyle = (getthumbstyle) ? getthumbstyle({ user: this.state.user }) : {}
        return { ...avatarstyle, ...thumbstyle }
    },
    getClassName: function() {
        const { style, className, big } = this.props
        let rClassName
        if (className) {
            if (typeof className == 'function') {
                rClassName = className({ ...this.props, state: this.state })
            } else {
                rClassName = style['avatarbox_' + this.state.type]
                if (style[className]) {
                    rClassName = rClassName + ' ' + style[className]
                } else {
                    rClassName = rClassName + ' ' + className
                }
                if (big) rClassName = style['big'] + ' ' + rClassName
            }
        } else {
            rClassName = style['avatarbox_' + this.state.type]
            if (big) rClassName = style['big'] + ' ' + rClassName
        }
        return rClassName
    },
    getHref: function() {
        const { href } = this.props
        return (typeof href == 'function') ? href({ user: this.state.user }) : href
    },
    render: function() {
        const { style = s, enableUserLink, getthumb, badge, targetBlank, hovertext } = this.props

        let avatar = (this.state.user && this.state.user.avatar) ? this.state.user.avatar : ''
        if (avatar && getthumb) avatar = getthumb({ url: avatar, user: this.state.user })
        const avatarstyle = this.getStyle()
        const name = this.getName()
        const n1 = this.getN({ name })
        const link = this.getLink()
        const touchEnd = this.touchEnd
        const rClassName = this.getClassName()
        const href = this.getHref()

        return (
            <div className={rClassName}>
                <div className={style['avatar_' + this.state.type]}>
                    {(enableUserLink || href) ?
                        <a className={style.avatarAtag}
                           href={href || link}
                           target={(targetBlank) ? '_blank' : null}
                           onClick={(targetBlank) ? null : function(e) {
                               touchEnd(e, 'click', 'click', href || link)
                           }}
                           onTouchStart={(targetBlank) ? null : function() {
                           }}>
                            {(avatar || !n1) ?
                                <Avatar className={(avatarstyle) ? style.avatar + ' ' + style.image : style.avatar}>
                                    <div className={style.avatarimage} style={avatarstyle} />
                                </Avatar> :
                                <Avatar className={style.avatar}>{n1}</Avatar>
                            }
                            {(hovertext) ? <div className={style.hovertextcont}>
                                <div className={style.hovertext}>{hovertext}</div>
                            </div> : null}
                        </a>
                        :
                        (avatar || !n1) ?
                            <Avatar className={(avatarstyle) ? style.avatar + ' ' + style.image : style.avatar}>
                                <div className={style.avatarimage} style={avatarstyle} />
                            </Avatar> :
                            <Avatar className={style.avatar}>{n1}</Avatar>
                    }
                    {(badge && !name) ? <div className={style.badge}>{badge}</div> : null}
                </div>
                {(name && this.state.type === 'box' || name && this.state.type === 'list') ?
                    (enableUserLink) ?
                        <a href={link}
                           target={(targetBlank) ? '_blank' : null}
                           onClick={(targetBlank) ? null : function(e) {
                               touchEnd(e, 'click', 'click', link)
                           }}
                           onTouchStart={(targetBlank) ? null : function() {
                           }}
                        >
                            <div className={style.name}>
                                {name}
                                {(badge) ? <div className={style.badge}>{badge}</div> : null}
                            </div>
                        </a>
                        :
                        <div className={style.name}>
                            {name}
                            {(badge) ? <div className={style.badge}>{badge}</div> : null}
                        </div>
                    :
                    null
                }
            </div>
        )

    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props

        const R = (style && !disableInitWithStyles) ? withStyles(style)(AvatarComponent) : AvatarComponent
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
