import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import s from '../../empty.css'
import EmptyIcon from 'material-ui/svg-icons/content/inbox'

const NoMatches = createReactClass({
    render: function() {
        const { style = s, notFoundText = 'No Matches' } = this.props
        return (
            <div className={style.notfounditems}>
                <EmptyIcon />
                <div className={style.notfounditemstext}>
                    {notFoundText}
                </div>
            </div>
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props

        this.style = style
        const R = (style && !disableInitWithStyles) ? withStyles(style)(NoMatches) : NoMatches
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
