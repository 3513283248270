import basic from './basic'
import path from 'path'

/*Languages*/
import en_us from '../languages/en_us'
import hu_hu from '../languages/hu_hu'
import no_no from '../languages/no_no'
import posttypesPlugins from '../posttypes'

export default {
    ...basic,
    languages: {
        en_us: en_us,
        hu_hu: hu_hu,
        no_no: no_no
    },

    maxuploadfilesize: 1024 * 1024 * 20,
    enableuploadtypes: ['png', 'jpeg'],

    getthumb: function(p = {}) {
        const { url, size, post, data, user } = p
        let u = url

        if (url !== '/thumb.jpg' &&
            url &&
            !url.match('facebook.com') &&
            !url.match('googleusercontent.com') &&
            !url.match('http:') &&
            !url.match('https:') &&
            !url.match('getimage') &&
            !url.match('getthumb')
        ) {
            const dir = path.dirname(u)
            const filename = path.basename(u)
            let s = (!isNaN(Number(size))) ? Number(size) : 0
            if (s > 1280) s = 1280
            if (!s) s = 810

            if (p.rootClassName) {
                if (p.rootClassName === 'bp2') s = 810
            }

            let topath = path.join('/getthumb', s.toString(), dir)
            let to = path.join(topath, filename)
            if (to) to = to.replace(/\\/g, '/')
            u = to
        }

        if (user && user.avatarprops) {
            u = u + '?crop=' + encodeURI(user.avatarprops)
        }

        return u
    },

    getthumbstyle: function(p = {}) {
        const { post, user } = p
        const r = {}
        if (post && post.coverprops && false) {
            let coverprops = post.coverprops
            try {
                coverprops = JSON.parse(coverprops)
            } catch (e) {
            }
            if (coverprops && typeof coverprops.x == 'number') {
                r.backgroundPositionX = coverprops.x + '%'
            }
            if (coverprops && typeof coverprops.y == 'number') {
                r.backgroundPositionY = coverprops.y + '%'
            }
        }
        if (user && user.avatarprops && false) {
            let avatarprops = user.avatarprops
            try {
                avatarprops = JSON.parse(avatarprops)
            } catch (e) {
            }
            if (avatarprops && typeof avatarprops.x == 'number') {
                r.backgroundPositionX = avatarprops.x + '%'
            }
            if (avatarprops && typeof avatarprops.y == 'number') {
                r.backgroundPositionY = avatarprops.y + '%'
            }
        }
        return r
    },


    routes: {
        menuhomeslug: '/home',
        menusearchslug: '/search',
        ...posttypesPlugins.configroutes
    },

    termspostid: '595289646e6bd517cc42928e',
    privancyid: '595289806e6bd517cc42928f',
    cookiepostid: '595fdfbf2deaa012109a96b7',
    contactpostid: '595ff7c2faff6c0734be22ca'

}


