function messagesToString({ messages }) {
    let thiserrors = ''
    messages.map(function(m) {
        thiserrors = (!thiserrors) ? '[' + m : thiserrors + ', ' + m
    })
    return thiserrors + ']'
}


export default function({ files, curlang = {}, config = {} }) {

    const {
        uploaddefaultfail = 'Invalid file',
        uploadinvalidfiletype = function(a) {
            return 'Invalid file type, enabled types: ' + a
        },
        uploadtoobig = function(a) {
            return 'Too big size, max ' + a + ' MB'
        }
    } = curlang

    const mb = 1024 * 1024

    const {
        maxuploadfilesize = mb * 2,
        enableuploadtypes = ['png', 'jpeg']
    } = config

    const response = {
        success: false,
        message: uploaddefaultfail,
        field: 'dialog'
    }

    const filesdataarray = []

    if (files.length) {
        let allsuccess = true
        const errors = {}
        for (let i = 0; i < files.length; i++) {

            const file = files[i]
            const size = file.size
            const type = file.type || file.mimetype
            const name = file.name

            const filedata = {
                success: true,
                size: size,
                type: type,
                name: name
            }

            let shortname = name || 'file'
            if (name && name.length > 12) {
                const namea = name.split('.')
                const ext = (namea && namea.length) ? namea[namea.length - 1] : ''
                const namewthtext = (ext) ? name.slice(0, ((ext.length + 1) * -1)) : ''
                shortname = (namewthtext) ? namewthtext.slice(0, 8) + '...' + ext : name.slice(0, 8) + '...' + ext
            }

            filedata.shortname = shortname

            const messages = []
            let success = true

            if (maxuploadfilesize < size) {
                success = false
                messages.push(uploadtoobig(maxuploadfilesize / mb))
            }

            let righttype = false
            enableuploadtypes.map(function(t) {
                if (!righttype && type && type.match(t)) {
                    righttype = true
                }
            })

            if (!righttype) {
                success = false
                messages.push(uploadinvalidfiletype(enableuploadtypes.toString()))
            }

            if (!success) {
                allsuccess = false
                filedata.success = false
                filedata.message = messagesToString({ messages })
                errors[shortname] = filedata.message
            }

            filesdataarray.push(filedata)

        }

        if (!allsuccess) {

            response.success = false
            response.errors = errors
            response.allfail = (errors.length === files.length)

            let emessage = ''
            Object.keys(response.errors).map(function(name) {
                emessage = emessage + name + ': ' + response.errors[name] + '; '
            })
            response.message = emessage

        } else {
            response.success = true
            response.message = null
            response.field = null
        }
    }
    response.files = filesdataarray
    return response

}
