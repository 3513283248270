import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

function slug(str) {
    try {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()
        const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
        const to = 'aaaaaeeeeeiiiiooooouuuunc------'
        let i = 0
        const l = from.length
        for (; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }
        return str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '')
    } catch (e) {
        console.log(e)
    }
    return str
}

const MetaData = createReactClass({
    getInitialState: function() {
        const { post, data } = this.props
        return {
            post: post,
            data: data,
            isMounted: false
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    render: function() {

        const { style, title, rowstyle = {}, className } = this.props
        const post = this.state.post
        const datas = this.state.data

        let cn = (className) ? style[className] ? style[className] : className : ''
        cn = (post && datas) ? style.container + ' ' + cn : cn

        return (
            <div className={cn}>
                {(title && post && datas) ?
                    <div className={style.subheadercontainer}>
                        <div className={style.subheader}>
                            {title}
                        </div>
                    </div>
                    : null
                }
                {(post && datas) ?
                    datas.map(function(d, i) {

                        if (post) {
                            let value
                            if (typeof d.value == 'function') {
                                value = d.value({ post: post, style })
                            } else {
                                value = post[d.value]
                            }
                            if (value) {
                                let vs = (d.valuestyle) ? d.valuestyle : {}
                                if (typeof vs === 'function') {
                                    vs = vs({ post, style })
                                }
                                const inserttype = (d.insterttype) ? d.insterttype : null
                                const valuestyle = (d.icon || d.name) ? { paddingLeft: '48px', ...vs } : vs
                                if (d.replace) value = d.replace(value)
                                const rs = (d.rowstyle) ? { ...rowstyle, ...d.rowstyle } : { ...rowstyle }
                                let elementName = d.elementName || d.name || 'metaDataValue'
                                if (elementName) elementName = slug(elementName)

                                return (
                                    <div key={i} className={style.row} style={(rowstyle) ? rs : null}>
                                        {(d.icon || d.name) ?
                                            <div className={style.namecontainer}>
                                                <div className={style.icon}>
                                                    {(d.icon) ? d.icon : null}
                                                </div>
                                                <div className={style.name}>
                                                    {d.name}
                                                </div>
                                            </div>
                                            : null}
                                        {(inserttype === 'html' && typeof value == 'string') ?
                                            <div name={elementName} className={style.value} style={valuestyle}
                                                 dangerouslySetInnerHTML={{ __html: value }} /> :
                                            <div name={elementName} className={style.value}
                                                 style={valuestyle}>{value}</div>}
                                    </div>
                                )
                            }
                        }
                        return null
                    }) : null}
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <MetaData {...this.props} ref={(setRef) ? function(e) {
                setRef('MetaData', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})

