import { decode } from 'querystring'

export function locationChangeCallback(p = {}) {

    const { states, store, history } = p

    const setUrl = (states && states.actions && states.actions.setUrl) ? states.actions.setUrl : null
    const setLastUrl = (states && states.actions && states.actions.setLastUrl) ? states.actions.setLastUrl : null
    const setQuery = (states && states.actions && states.actions.setQuery) ? states.actions.setQuery : null
    const state = store.getState()

    const location = (history && history.location) ? history.location : {
        hash: '',
        search: ''
    }

    const pathname = location.pathname || '/'
    let url = pathname + location.hash + location.search

    if (state.url !== url) {

        const tempurl = state.url
        store.dispatch(setUrl({
            value: url
        }))
        if (tempurl) {
            store.dispatch(setLastUrl({
                value: tempurl
            }))
        }
    }

    let search = location.search
    let queryrefresh = false
    let query = {}

    if (!search) {
        Object.keys(state.query).forEach(function() {
            queryrefresh = true
        })
    } else {
        if (search.slice(0, 1) === '?') search = search.slice(1)
        query = decode(search)
        Object.keys(query).forEach(function(key) {
            const e = state.query[key]
            if (query[key] === e) {
            } else {
                queryrefresh = true
            }
        })
        Object.keys(state.query).forEach(function(key) {
            if (!query[key] && state.query[key]) {
                query[key] = ''
                queryrefresh = true
            }
        })
    }

    if (queryrefresh) {
        store.dispatch(setQuery({
            value: query
        }))
    }

}

export function runInitial({ config = {} }) {
    config.clientData = {}
}

export function beforeRun() {
    if (window.history && 'scrollRestoration' in window.history) window.history.scrollRestoration = 'manual'
}

export default {
    locationChangeCallback: locationChangeCallback,
    beforeRun: beforeRun,
    runInitial: runInitial
}

