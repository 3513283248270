export default function() {

    const feeds = {}

    function feedRefs(a, e) {
        feeds[a] = e
    }

    function getFeed(a) {
        return feeds[a]
    }

    let feedDataObjects = {}

    function getFeedDataObject(a) {
        feedDataObjects[a] = {
            dataCurrent: {
                showContent: false,
                className: 'bp0'
            }
        }
        return {
            getDataCurrent: function() {
                return feedDataObjects[a].dataCurrent
            },
            setDataCurrent: function(b, v) {
                feedDataObjects[a].dataCurrent[b] = v
            }
        }
    }

    return { feedRefs, getFeed, getFeedDataObject }
}
