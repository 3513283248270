export default {
    sitename: 'FlatChooser',
    siteimage: '/siteimage.jpg',
    logotext: 'FlatChooser',
    logospan: '',
    siteurl: "https://flatchooser.reeltime.no",
    staticserver: "app-flatchooser.reeltime.no",
    staticpath: '/data/zoomselect',
    staticscript: '/static.js',
    logourl: '/logo.png',
    emaillogourl: '/email_logo.png',
    maincolor: '#209c75',
    defaultfirstname: 'User',
    defaultsearchposttype: 'Player',
    defaultpostsearchobjectname: 'id',
    defaultpostslug: 'post',
    defaultthumbnail: '/thumb.jpg',
    currentlang: 'no_no',
    googleTrackingId: 'UA-127425100-1'
}
