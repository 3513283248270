import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import s from '../empty.css'
import CloseIcon from 'material-ui/svg-icons/navigation/close'
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever'
import IconButton from 'material-ui/IconButton'

import WapplrAvatar from '../Wapplravatar'
import wapplravatarhelpers from '../Wapplravatar/helpers'
import Formsy from './Formsy'

import ArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down'
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up'

const Post = createReactClass({
    getInitialState() {
        const { hiddencontent = true, enablehiddencontent } = this.props
        return {
            isMounted: true,
            ready: false,
            hiddencontent: (enablehiddencontent) ? hiddencontent : false,
            refresh: 0
        }
    },
    updatePost: function() {
        this.setState({
            refresh: this.state.refresh + 1
        })
    },
    setReady: function() {
        if (!this.state.ready) {
            this.setState({
                ready: true
            })
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    hiddencontent: function() {
        const { hiddencontent } = this.state
        const { onOpenHiddenContent } = this.props
        if (onOpenHiddenContent) {
            onOpenHiddenContent(!hiddencontent)
        } else {
            this.setState({
                hiddencontent: !hiddencontent
            })
        }
    },
    click: function(e, d) {

        const { type } = d
        const { history, deletepost, post } = this.props

        if (type === 'back') {
            history.go(-1)
        }

        if (type === 'deletebutton') {
            if (deletepost) deletepost({ post })
        }

        if (type === 'hiddencontent') {
            this.hiddencontent()
        }

        e.preventDefault()

    },
    render: function() {
        const {
            style = s,
            post,
            className,
            showauthor,
            avatarstyle,
            enablehiddencontent,
            formprops = {},
            history,
            save,
            data,
            close,
            disableclose,
            statusname = '',
            deletebutton,
            restoretext,
            getpublicicon,
            getstatusname,
            getdeletebutton,
            getrestoretext,
            buttons,
            closetooltip,
            deletetooltip,
            FormsyComponent,
            getthumbstyle,
            getthumb
        } = this.props

        const user = (showauthor && post && post.author && wapplravatarhelpers({ user: post.author })) ? post.author : null
        const touchEnd = this.touchEnd

        const state = this.state
        const cn = (className) ? (style[className]) ? style[className] : className : null

        const rpublicicon = (getpublicicon) ? getpublicicon({ post }) : null
        const rstatusname = (getstatusname) ? getstatusname({ post }) : statusname
        const rdeletebutton = (getdeletebutton) ? getdeletebutton({ post }) : deletebutton
        const rrestoretext = (getrestoretext) ? getrestoretext({ post }) : restoretext
        const FC = (FormsyComponent) ? FormsyComponent : Formsy

        return (
            <div className={(cn) ? cn + ' ' + style.postcont : style.postcont}>
                {(post) ?
                    <div className={style.post}>
                        <div className={style.postheader}>
                            <div className={style.titlecontainer}>
                                {(user) ?
                                    <div className={style.avatarbox}>
                                        <WapplrAvatar user={user} style={avatarstyle} enableUserLink={true}
                                                      history={history} getthumbstyle={getthumbstyle}
                                                      getthumb={getthumb} />
                                    </div>
                                    : null
                                }
                                {(rpublicicon) ?
                                    <div className={style.lefticon}>
                                        {rpublicicon}
                                    </div> : null
                                }
                                <div className={style.title}>{post.title}</div>
                                {(enablehiddencontent) ?
                                    <div className={style.titlehiddencontainer}>
                                        <IconButton
                                            onTouchTap={function(e) {
                                                touchEnd(e, 'click', 'click', { type: 'hiddencontent' })
                                            }}
                                            onTouchStart={function() {
                                            }}
                                            className={style.titlehiddenbutton}
                                        >
                                            {(state.hiddencontent) ? <ArrowDown /> : <ArrowUp />}
                                        </IconButton>
                                    </div>
                                    :
                                    null
                                }
                                {(rdeletebutton) ?
                                    <div className={style.titledeletecontainer}>
                                        <IconButton
                                            onTouchTap={function(e) {
                                                touchEnd(e, 'click', 'click', { type: 'deletebutton' })
                                            }}
                                            onTouchStart={function() {
                                            }}
                                            className={style.titledeletebutton}
                                            tooltip={deletetooltip}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div> :
                                    null
                                }
                                {(!disableclose) ?
                                    <div className={style.titleclosecontainer}>
                                        <IconButton
                                            onTouchTap={(close) ? close : function(e) {
                                                touchEnd(e, 'click', 'click', { type: 'back' })
                                            }}
                                            onTouchStart={function() {
                                            }}
                                            className={style.titleclosebutton}
                                            tooltip={closetooltip}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    : null
                                }
                                {(buttons) ? <div className={style.buttons}>{buttons}</div> : null}
                            </div>
                            <div className={style.subtitle}>{rstatusname}</div>
                        </div>
                        {(enablehiddencontent === 'form') ?
                            <div className={style.postcontent}>
                                <FC formdata={data} submitForm={save} style={style}
                                    className={(state.hiddencontent) ? 'hiddencontent' : null} post={post}
                                    restoretext={rrestoretext} hiddencontent={state.hiddencontent}
                                    ready={state.ready} {...formprops} />
                            </div> :
                            (enablehiddencontent && state.hiddencontent) ? null :
                                <div className={style.postcontent}>
                                    <FC formdata={data} submitForm={save} style={style} post={post}
                                        restoretext={rrestoretext} ready={state.ready} {...formprops} />
                                </div>
                        }

                    </div>
                    : null}
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Post {...this.props} ref={(setRef) ? function(e) {
                setRef('editpost', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props

        this.style = style
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
