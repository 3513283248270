import posttypesSetrequired from '../../posttypes/setrequired'

const statuses = {
    'approved': 100,
    'required data': 50,
    'created': 40,
    'deleted itself': 30,
    'banned': 20
}

export const poststatuses = statuses
export default statuses

export const requireddata = {
    title: 1
}

export function setrequiredByPostType({ post, requireddata, posttype }) {
    return posttypesSetrequired.setrequired({ post, requireddata, posttype })
}

export function getDefaultPostStatusForPostCollection() {
    return statuses.created
}

export function getMinPostStatusWhenAllAccess(p = {}) {
    const { posttype = 'Post' } = p
    const currentminstatus = statuses['required data']
    return posttypesSetrequired.minpoststatus({ statuses, currentminstatus, posttype })
}

export function getOptionsForPostCollection() {
    const options = []
    Object.keys(statuses).map(function(key) {
        options.push({ label: key, value: statuses[key] })
    })
    return options
}

export function getPostStatusData({ post, curlang = {} }) {
    if (post && post.id) {

        const {
            poststatuscreated = 'published',
            poststatusdeleteditself = 'deleted by user',
            poststatusbanned = 'post banned permanently',
            poststatusrequireddata = 'required data are available',
            poststatusapproves = 'post approved'
        } = curlang

        const deleteorrestorepoint = poststatuses['created']
        const currentstatus = post.status || deleteorrestorepoint
        const deleteorrestore = (currentstatus < deleteorrestorepoint) ? 'restore' : 'delete'
        const deletebutton = (currentstatus < deleteorrestorepoint) ? null : 'delete'

        const approveenable = (currentstatus > poststatuses['required data'] - 1 && currentstatus < poststatuses['approved'])
        const banenable = (currentstatus > poststatuses['banned'])

        let statusname = poststatuscreated

        Object.keys(poststatuses).map(function(psk) {
            if (poststatuses[psk] === currentstatus) statusname = psk
        })

        if (statusname === 'deleted itself') {
            statusname = poststatusdeleteditself
        }

        if (statusname === 'banned') {
            statusname = poststatusbanned
        }

        if (statusname === 'required data') {
            statusname = poststatusrequireddata
        }

        if (statusname === 'approved') {
            statusname = poststatusapproves
        }

        return {
            statusname: statusname,
            poststatus: post.status,
            deleteorrestore,
            deletebutton: deletebutton,
            approveenable: approveenable,
            banenable: banenable
        }

    }

    return { statusname: 'not found', poststatus: poststatuses['banned'] - 1 }
}
