import React from 'react'
import createReactClass from 'create-react-class'
import MultiSelect from './index'
import Formsy from 'formsy-react'

export default createReactClass({
    mixins: [Formsy.Mixin],
    getInitialState: function() {
        return {
            value: this.props.value,
            isMounted: true
        }
    },
    onChange: function(a, b, c) {
        const { onChange } = this.props
        this.setValue(c)
        if (onChange) onChange(a, b, c)
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    render() {

        const {
            validations, // eslint-disable-line no-unused-vars
            validationError, // eslint-disable-line no-unused-vars
            validationErrors, // eslint-disable-line no-unused-vars
            ...rest
        } = this.props

        const value = this.getValue()

        return (
            <MultiSelect
                {...rest}
                onChange={this.onChange}
                errorText={(value) ? this.getErrorMessage() : null}
                value={value}
            />
        )
    }

})
