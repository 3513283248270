import { beforeinit, wapplrcomponents, wapplrconfig } from '../old/'
import clientConfig from '../old/app/configs/client'
import defaultHooks from '../old/app/client/hooks'
import { setRoutes } from '../old/core/router'
import getFetch from '../old/core/internal/fetch/fetch.client'
import getCreateHelpers from '../old/core/internal/store/createHelpers'
import UniversalRouter from 'universal-router'
import queryString from 'query-string'
import ReactDOM from 'react-dom'
import React from 'react'

import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

const historyBeforeListeners = function({ history }) {

    if (!history.beforeListeners) {
        let ID = 0
        history.beforeListeners = {
            listeners: {},
            get: function() {
                return this.listeners
            },
            set: function(f) {
                ID = ID + 1
                this.listeners[ID] = f
                return { ID, func: f }
            },
            remove: function(IDf) {
                const removedIDs = []
                const listeners = this.listeners
                if (typeof IDf == 'function') {
                    Object.keys(listeners).map(function(id) {
                        if (listeners[id] === IDf) {
                            removedIDs.push(IDf)
                        }
                    })
                } else {
                    removedIDs.push(IDf)
                }
                removedIDs.map(function(id) {
                    if (listeners[id]) {
                        listeners[id] = null
                    }
                })
                this.listeners = listeners
            },
            removeAll: function() {
                this.listeners = {}
            },
            run: function(next) {
                const listeners = this.listeners
                const r = []
                Object.keys(listeners).map(function(id) {
                    if (listeners[id]) {
                        r.push(listeners[id](next))
                    }
                })
                return r
            }
        }
        history.addBeforeListener = function(f) {
            return history.beforeListeners.set(f).ID
        }
        history.removeBeforeListener = function(IDf) {
            history.beforeListeners.remove(IDf)
        }
        history.removeBeforeListeners = function() {
            history.beforeListeners.removeAll()
        }
    }
}

function getRenderComplete({ scrollPositionsHistory }) {

    return function(route, { location, state }) {

        const { pathname = '', hash = '', search = '' } = location

        let scrollX = 0
        let scrollY = 0

        const locationKey = (state.key) ? state.key : pathname + hash + search

        const pos = scrollPositionsHistory[locationKey]

        if (pos) {
            scrollX = pos.scrollX
            scrollY = pos.scrollY
        } else {
            const targetHash = hash && hash.substr(1) || ''
            if (targetHash) {
                const target = document.getElementById(targetHash)
                if (target) {
                    scrollY = window.pageYOffset + target.getBoundingClientRect().top
                }
            }
        }

        window.scrollTo(scrollX, scrollY)

        if (window.ga) {
            window.ga('send', 'pageview', Object.keys(location).map((key) => location[key]).join(''))
        }

    }
}

function getConfigureStore({ createHelpers }) {
    return function configureStore(initialState, helpersConfig, rootReducer) {
        const helpers = createHelpers(helpersConfig)
        const middleware = [thunk.withExtraArgument(helpers)]
        const enhancer = applyMiddleware(...middleware)
        return createStore(rootReducer, initialState, enhancer)
    }
}

function getInternal({ self }) {

    const fetch = getFetch(self)
    const createHelpers = getCreateHelpers(fetch)

    return {
        fetch: fetch,
        createHelpers: createHelpers,
        configureStore: getConfigureStore({ createHelpers })
    }
}

function getContext(p) {
    const { wapp, states, history, internal } = p
    const configureStore = internal.configureStore

    return {
        insertCss: (...styles) => {
            //console.log(styles[0]._module.id)
            const removeFunctions = styles.map((style) => {
                /*const remove = */
                wapp.styles.add(style)
                return () => {
                    //console.log("remove", styles[0]._module.id)
                    //remove();
                }
            })
            return () => {
                return removeFunctions.map((remove) => {
                    remove()
                })
            }
        },
        store: (states && states.reducers) ? configureStore(window.APP_STATE, { history }, states.reducers) : null
    }
}

function config({ wapp, initApp, beforeInit, config = {}, history, components, getInternal, runInitial }) {

    const host = (history && history.location && history.location.host) ? history.location.host : config.host
    const self = ('undefined' !== typeof window && window.self) ? window.self : {
        fetch: function() {
        }
    }

    const internal = getInternal({ self })
    const fetch = internal.fetch.default

    const initAppConfig = (beforeInit) ? beforeInit({ history, wapp }) : initApp({ history, wapp })
    const states = initAppConfig.states
    const context = getContext({ wapp, states, history, internal })
    const store = context.store

    if (runInitial) {
        runInitial({ history, config: initAppConfig, states, context, store, host })
    }

    const runtimeAppConfig = initApp({ wapp, history, store, states, config })
    const routes = setRoutes({ app: runtimeAppConfig, core: config, helpers: { fetch, store: store, states } })
    const App = components['app']

    return { history, states, routes, store, App, context, fetch, internal }

}

function getOnLocationChange(p) {

    const { hooks } = p
    const { runInitial, locationChangeCallback, beforeRun } = hooks

    const { history, states, routes, store, App, context } = config({ ...p, getInternal, runInitial })

    let currentLocation = {}
    let currentHistoryState = {}
    const scrollPositionsHistory = {}

    const onRenderComplete = getRenderComplete({ scrollPositionsHistory })

    const onLocationChange = async function({ location, action, state }, container) {

        const { pathname = '', search = '', hash = '' } = location

        const initial = !Object.keys(currentLocation).length
        if (initial) {
            currentLocation = { pathname, search, hash }
            currentHistoryState = { ...state }
        }

        if (!currentLocation.pathname) {
            currentLocation.pathname = ''
        }
        if (!currentLocation.search) {
            currentLocation.search = ''
        }
        if (!currentLocation.hash) {
            currentLocation.hash = ''
        }

        const currentLocationKey = (currentHistoryState.key) ? currentHistoryState.key : currentLocation.pathname + currentLocation.search + currentLocation.hash
        const locationKey = (state.key) ? state.key : pathname + search + hash

        scrollPositionsHistory[currentLocationKey] = {
            scrollX: window.pageXOffset,
            scrollY: window.pageYOffset
        }

        if (action === 'PUSH') {
            delete scrollPositionsHistory[locationKey]
        }

        currentLocation = { pathname, search, hash }
        currentHistoryState = { ...state }

        if (locationChangeCallback) {
            locationChangeCallback({ states, store, history: { location: { pathname, search, hash } } })
        }

        async function next() {

            history.location = { pathname, search, hash }

            const router = new UniversalRouter(routes, {
                ...context,
                path: pathname,
                query: queryString.parse(search)
            })

            const route = await router.resolve({ path: pathname })

            if (route.redirect) {
                history.push(route.redirect)
                route.status = 302
                return route
            }

            ReactDOM.render(
                <App context={context}>{route.component}</App>,
                container,
                () => onRenderComplete(route, { location, action, state })
            )

            if (initial) {
                const elem = document.getElementById('css')
                if (elem) elem.parentNode.removeChild(elem)
            }

            return route

        }

        historyBeforeListeners({ history })

        const beforeListenersResponses = history.beforeListeners.run(next)

        let prevent = false

        beforeListenersResponses.map(function(rn) {
            if (rn) prevent = true
        })

        if (prevent) {
            return prevent
        }

        return await next()

    }

    if (beforeRun) beforeRun()

    return onLocationChange

}

function initOldApp(props) {

    const { wapp } = props

    const defaultOldApp = {
        beforeInit: beforeinit,
        initApp: wapplrconfig,
        components: wapplrcomponents,
        config: clientConfig,
        hooks: defaultHooks
    }

    const oldApp = (wapp.oldApp?.run) ? wapp.oldApp : (props.oldApp) ? props.oldApp : defaultOldApp

    if (!oldApp.onLocationChange) {

        const newAppConfig = wapp.client.config
        const globals = wapp.globals

        oldApp.config.sitename = newAppConfig.siteName
        oldApp.config.siteurl = (globals.DEV) ? 'http://localhost:3000' : oldApp.config.siteurl

        const history = wapp.client.history
        const push = history.push
        history.push = function(...attributes) {
            if (typeof attributes[0] == 'object' && attributes[0].search) {
                if (attributes[0].search.slice(0, 1) !== '?') {
                    attributes[0].search = '?' + attributes[0].search
                }
            }
            if (typeof attributes[0] == 'object' && attributes[0].hash) {
                if (attributes[0].hash.slice(0, 1) !== '#') {
                    attributes[0].hash = '#' + attributes[0].hash
                }
            }
            return push(...attributes)
        }

        oldApp.onLocationChange = getOnLocationChange({
            wapp,
            ...oldApp,
            history
        })

        const middleware = wapp.client.middlewares.render

        let firstRender = true

        middleware.addHandle({
            oldApp: async function(req, res, next) {

                const { statusCode } = res.wappResponse

                const renderType = res.wappResponse.content?.renderType || ''

                if ((statusCode === 404 && !renderType) || renderType === 'oldApp') {

                    wapp.client.config.styles = {
                        disableClearStyles: true
                    }

                    try {

                        if (typeof res._originalEndFunction === 'undefined') {

                            Object.defineProperty(res, '_originalEndFunction', {
                                configurable: false,
                                writable: false,
                                enumerable: false,
                                value: res.end
                            })

                        }

                        res.end = async function() {
                            if (!res.wappResponse.sended) {
                                Object.defineProperty(res, 'headersSent', {
                                    configurable: false,
                                    enumerable: false,
                                    writable: false,
                                    value: true
                                })
                                firstRender = false
                            }
                        }

                        const route = await oldApp.onLocationChange(req.history, res.wappResponse.container)

                        res.wappResponse.content = route || {}
                        res.wappResponse.content.renderType = 'oldApp'
                        res.wappResponse.status((route?.status) ? route.status : (route?.component) ? 200 : 404)
                        if (route?.status === 302) {
                            res.wappResponse.statusMessage = 'Found'
                        }
                        res.wappResponse.send()

                        next()

                    } catch (err) {
                        next(err)
                    }
                }

            }
        })

    }

    wapp.oldApp = oldApp

    return wapp.oldApp
}

export default function({ wapp }) {
    return initOldApp({ wapp })
};
