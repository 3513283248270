import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import NotificationsIcon from 'material-ui/svg-icons/social/notifications'

const Notification = createReactClass({
    getInitialState: function() {
        const { notifications } = this.props
        return {
            notifications: notifications
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const { onClick } = this.props
        if (type === 'click') {
            if (onClick) {
                onClick(e, d)
            } else {
                this.go()
            }
        }
        e.preventDefault()
    },
    go: function() {
        const { href } = this.props
        this.historyPush(href)
    },
    historyPush: function(href) {
        const { history } = this.props
        if (href && history && history.push) {
            history.push(href)
        }

    },
    getStyle: function() {
        const { containerStyle = {} } = this.props
        return { ...containerStyle }
    },
    getClassName: function() {
        const { className, style } = this.props
        if (className) return style.notification + ' ' + className
        return style.notification
    },
    render: function() {

        const { style } = this.props
        const { notifications } = this.state
        const touchEnd = this.touchEnd
        const notificationStyle = this.getStyle()
        const className = this.getClassName()

        return (
            <div className={className} style={notificationStyle}>
                <div
                    onTouchTap={function(e) {
                        touchEnd(e, 'click', 'click', { type: 'click' })
                    }}
                    onTouchStart={function() {

                    }}
                >
                    <NotificationsIcon />
                    {(notifications) ? <div className={style.notificationindex}>{notifications}</div> : null}
                </div>
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Notification {...this.props} ref={(setRef) ? function(e) {
                setRef('notification', e)
            } : null} />
        )
    }
})


export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
