export default function({ taxonomies, posttypes, getFeedDataObject }) {
    const feedDatas = {}
    Object.keys(taxonomies).forEach(function(key) {
        feedDatas['archive' + key] = getFeedDataObject('archive' + key)
        feedDatas['archive_posts' + key] = getFeedDataObject('archive_posts' + key)
    })

    Object.keys(posttypes).forEach(function(key) {
        const pk = posttypes[key].path
        feedDatas['archive' + pk] = getFeedDataObject('archive' + pk)
        feedDatas['archive_posts' + pk] = getFeedDataObject('archive_posts' + pk)
    })

    Object.keys(taxonomies).forEach(function(key) {
        feedDatas['search' + key] = getFeedDataObject('search' + key)
    })
    feedDatas['search' + '_posts'] = getFeedDataObject('search' + '_posts')
    return feedDatas
}
