import posttypesPlugins from '../posttypes'
import getFilesdataValidate from './uploadvalidation'

const defaultOptions = {
    method: 'post',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    credentials: 'same-origin'
}

const getOptions = function(a = {}) {
    return { ...defaultOptions, ...a }
}

const getResponse = async function({ resp }) {
    if (resp.status !== 200) throw new Error(resp.statusText)
    let respData = (resp && resp.json) ? await resp.json() : null
    if (respData.data) respData = respData.data
    return respData
}

const defaultEnd = function({ respData, processingStart, processingEnd, success, fail, multiple, invalidateForm }) {
    if (respData.length) {
        if (multiple) multiple({ response: respData, processingEnd, invalidateForm })
    } else {
        if (respData.success) {
            if (processingStart && processingEnd) processingEnd(true)
            if (success) success({ response: respData, processingEnd })
        } else {
            if (processingStart && processingEnd) processingEnd(false)
            if (fail) fail({ response: respData, invalidateForm })
        }
    }
}

const replaceData = function({ data, state, replacefromstate }) {
    if (replacefromstate) {
        Object.keys(replacefromstate).map(function(k) {
            if (state && state.response && state.response[k]) {
                Object.keys(replacefromstate[k]).map(function(d) {
                    if (state.response[k][d]) data[d] = state.response[k][d]
                })
            }
        })
    }
}


const iteralFetchsFiles = async function({ data, files, link, processingStart, curlang, uploadconfig }) {

    const r = []

    const filesdata = getFilesdataValidate({ files, curlang, config: uploadconfig }).files

    for (let i = 0; i < files.length; i++) {
        const formData = new FormData()
        formData.append('file', files[i], files[i].name)
        let dataString = '{}'
        try {
            if (typeof data == 'object' && typeof data.length === 'undefined' && data) {
                dataString = JSON.stringify(data)
            }
        } catch (e) {
        }

        formData.append('data', dataString)

        const options = {
            method: 'POST',
            body: formData,
            credentials: 'same-origin'
        }
        let go = true
        const fd = (filesdata && filesdata[i]) ? filesdata[i] : null

        if (fd && !fd.success) go = false
        if (fd) {
            filesdata[i].propcessing = true
            processingStart({ files: filesdata })
        }

        const resp = (go) ? await fetch(link, options) : null
        const ro = (go) ? await getResponse({ resp }) : { success: false, message: fd.message }

        if (fd) {
            filesdata[i].propcessing = false
            filesdata[i].response = ro
            processingStart({ files: filesdata })
        }

        r.push(ro)
    }

    return r
}

const defaultFetchFiles = async function({ data, event, link, processingStart, curlang, uploadconfig }) {
    const files = event.target.files
    return await iteralFetchsFiles({ data, files, link, processingStart, curlang, uploadconfig })
}

const defaultFetch = async function({ fetch, data, link }) {
    const options = getOptions({ body: JSON.stringify(data) })
    const resp = await fetch(link, options)
    return await getResponse({ resp })
}

const defaultRequestFiles = async function({
                                               getHelpers,
                                               curlang,
                                               uploadconfig,
                                               success,
                                               fail,
                                               multiple,
                                               replacefromstate,
                                               data,
                                               event,
                                               invalidateForm,
                                               refs = {},
                                               link
                                           }) {
    const { processingStart, processingEnd } = refs
    const { fetch, state } = getHelpers()
    replaceData({ data, state, replacefromstate })

    if (fetch) {
        if (processingStart && processingEnd) processingStart(true)
        const respData = await defaultFetchFiles({ fetch, data, event, link, processingStart, curlang, uploadconfig })
        defaultEnd({ respData, processingStart, processingEnd, success, fail, multiple, invalidateForm })
    }
}

const defaultRequest = async function({
                                          getHelpers,
                                          success,
                                          fail,
                                          replacefromstate,
                                          data,
                                          invalidateForm,
                                          refs = {},
                                          link
                                      }) {
    const { processingStart, processingEnd } = refs
    const { fetch, state } = getHelpers()
    replaceData({ data, state, replacefromstate })

    if (fetch) {
        if (processingStart && processingEnd) processingStart(true)
        const respData = await defaultFetch({ fetch, data, link })
        defaultEnd({ respData, processingStart, processingEnd, success, fail, invalidateForm })
    }
}

const postNormalise = function({ data }) {
    if (data && data.share !== undefined) {
        if (data.share === true || data.share === 'public') {
            data.share = 'public'
        } else {
            data.share = 'private'
        }
    }
}

export default {
    ...posttypesPlugins.fetchs({
        postNormalise,
        defaultRequest,
        defaultRequestFiles,
        defaultEnd,
        replaceData,
        defaultFetch
    })
}
