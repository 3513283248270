// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RZ1lO {\n    height: 100%;\n    width: auto;\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    color: var(--secondary-bg);\n    fill: var(--secondary-bg)\n}\n\n@media (prefers-color-scheme: dark) {\n    .RZ1lO{\n        color: var(--secondary-color);\n        fill: var(--secondary-color)\n    }\n}\n\n._7cM-M {\n    height: 90%;\n    width: auto;\n}\n\n.RZ1lO .VgS-k {\n    display: flex;\n    justify-content: center;\n    height: 100%;\n}\n\n.RZ1lO img{\n    width: auto;\n    height: 100%;\n    max-width: 90%;\n}\n\n.RZ1lO .wlz5j{\n    display: block;\n}\n\n.RZ1lO ._0goG\\+{\n    display: none;\n}\n\n@media (prefers-color-scheme: dark){\n    .RZ1lO .wlz5j{\n        display: none;\n    }\n\n    .RZ1lO ._0goG\\+{\n        display: block;\n    }\n}\n\n@media print {\n    .RZ1lO .wlz5j{\n        display: block;\n    }\n\n    .RZ1lO ._0goG\\+{\n        display: none;\n    }\n}\n\n.iN0Je {\n    height: 100%;\n    width: auto;\n    display: flex;\n    max-width: 100%;\n}\n\n.iN0Je path{\n    fill: currentColor;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "RZ1lO",
	"icon": "_7cM-M",
	"imgContainer": "VgS-k",
	"lightImg": "wlz5j",
	"darkImg": "_0goG+",
	"svg": "iN0Je"
};
module.exports = ___CSS_LOADER_EXPORT___;
