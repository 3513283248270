export default function getCreateHelpers(fetch) {

    function createGraphqlRequest(fetchKnowingCookie) {
        return async function graphqlRequest(query, variables) {
            const fetchConfig = {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query, variables }),
                credentials: 'include'
            }
            const resp = await fetchKnowingCookie('/graphql', fetchConfig)
            if (resp.status !== 200) throw new Error(resp.statusText)
            return resp.json()
        }
    }

    function createFetchKnowingCookie({ cookie }) {
        if (typeof window == 'undefined') {
            return (url, options = {}) => {
                const isLocalUrl = /^\/($|[^/])/.test(url)

                // pass cookie only for itself.
                // We can't know cookies for other sites BTW
                if (isLocalUrl && options.credentials === 'include') {
                    const headers = {
                        ...options.headers,
                        cookie
                    }
                    return fetch(url, { ...options, headers })
                }

                return fetch(url, options)
            }
        }

        return fetch
    }

    return function createHelpers(config) {
        const fetchKnowingCookie = createFetchKnowingCookie(config)
        const graphqlRequest = createGraphqlRequest(fetchKnowingCookie)

        return {
            fetch: fetchKnowingCookie,
            graphqlRequest,
            history: config.history
        }
    }

}
