import Wapplr from './Wapplr'
import Wapplrhtml from './Wapplrhtml'
import Wapplrapp from './Wapplrapp'
import Wapplrcontent from './Wapplrcontent'
import Wapplrmenu from './Wapplrmenu'
import Wapplravatar from './Wapplravatar'
import Wapplrpage from './Wapplrpage'
import Wapplrpost from './Wapplrpost'
import WapplrpostEdit from './Wapplrpost/edit'
import Wapplrfeed from './Wapplrfeed'
import Wapplrsearch from './Wapplrsearch/SearchBarComponent'
import Wapplrbreadcrumb from './Wapplrfeed/BreadcrumbComponent'
import Wapplrsearchfeedheader from './Wapplrfeed/SearchFeedHeader'
import Wapplrpagination from './Wapplrfeed/Pagination'
import Wapplrgallery from './Wapplrgallery'
import WapplrgalleryFormsy from './Wapplrgallery/formsy'

import WapplrpageHelpers from './Wapplrpage/helpers'
import WapplrmenuHelpers from './Wapplrmenu/helpers'
import WapplrsearchHelpers from './Wapplrsearch/helpers'
import WapplrfeedHelpers from './Wapplrfeed/helpers'
import WapplravatarHelpers from './Wapplravatar/helpers'

export default {
    wapplr: Wapplr,
    html: Wapplrhtml,
    app: Wapplrapp,
    content: Wapplrcontent,
    menu: Wapplrmenu,
    avatar: Wapplravatar,
    page: Wapplrpage,
    post: Wapplrpost,
    editpost: WapplrpostEdit,
    search: Wapplrsearch,
    feed: Wapplrfeed,
    breadcrumb: Wapplrbreadcrumb,
    searchfeedheader: Wapplrsearchfeedheader,
    pagination: Wapplrpagination,
    gallery: Wapplrgallery,
    galleryformsy: WapplrgalleryFormsy
}

export const wapplrhelpers = {
    menu: WapplrmenuHelpers,
    page: WapplrpageHelpers,
    search: WapplrsearchHelpers,
    feed: WapplrfeedHelpers,
    avatar: WapplravatarHelpers
}
