import requests from './requests'
import { getMinPostStatusWhenAllAccess, poststatuses } from '../server/poststatus/statuses'
import { getMinUserStatusWhenAllAccess } from '../server/userstatus/statuses'
import posttypesPlugins from '../posttypes'

function getArchiveRoutes({ posttypes, taxonomies, storedSearchData, searchRoute, defaultSearchPostType = '', limit }) {

    const archiveRoutes = []

    Object.keys(posttypes).forEach(function(key) {
        const path = posttypes[key].path
        const name = posttypes[key].name
        const defaultsort = posttypes[key].defaultsort

        const minuserstatus = getMinUserStatusWhenAllAccess()
        const minpoststatus = getMinPostStatusWhenAllAccess({ posttype: name })

        Object.keys(taxonomies).forEach(function(key) {
            const tpath = taxonomies[key].path
            let go = false
            if (taxonomies[key].posttype) {
                taxonomies[key].posttype.map(function(p) {
                    if (p === name) go = true
                })
            }
            if (go) {
                archiveRoutes.push({
                    path: '/' + path + '/' + tpath,
                    data: {
                        content: 'archive',
                        fetchreq: requests.search({
                            type: 'archive',
                            replacequery: {
                                posttype: name,
                                sgt: (minpoststatus - 1),
                                asgt: (minuserstatus - 1),
                                so: defaultsort,
                                limit: limit
                            },
                            taxonomies,
                            storedSearchData,
                            defaultSearchPostType,
                            posttypes
                        })
                    }
                })
            }
        })

        archiveRoutes.push({
            path: '/' + path,
            data: {
                content: 'archive',
                fetchreq: requests.search({
                    type: 'archive',
                    replacequery: {
                        posttype: name,
                        sgt: (minpoststatus - 1),
                        asgt: (minuserstatus - 1),
                        so: defaultsort,
                        limit: limit
                    },
                    taxonomies,
                    storedSearchData,
                    defaultSearchPostType,
                    posttypes
                })
            }
        })

        if (defaultSearchPostType && defaultSearchPostType !== name) {
            archiveRoutes.push({
                path: '/' + path + searchRoute,
                data: {
                    content: 'search',
                    fetchreq: requests.search({
                        type: 'search',
                        replacequery: {
                            posttype: name,
                            sgt: (minpoststatus - 1),
                            asgt: (minuserstatus - 1),
                            so: defaultsort
                        },
                        taxonomies,
                        storedSearchData,
                        defaultSearchPostType,
                        posttypes
                    })
                }
            })
        } else {
            archiveRoutes.push({
                path: searchRoute,
                data: {
                    content: 'search',
                    fetchreq: requests.search({
                        type: 'search',
                        replacequery: {
                            posttype: name,
                            sgt: (minpoststatus - 1),
                            asgt: (minuserstatus - 1),
                            so: defaultsort
                        },
                        taxonomies,
                        storedSearchData,
                        defaultSearchPostType,
                        posttypes
                    })
                }
            })
        }

    })

    return archiveRoutes

}

async function defaultRootAction({ next, r, helpers, fetchreq, getFetch, path, req, params }) {
    const route = await next()

    if (route && route.status !== 404) {
        if (route.data.fetchreq) {
            route.data.content.getFetch = async function({ state }) {
                return await getFetch({
                    helpers,
                    fetchreq: route.data.fetchreq,
                    path,
                    req,
                    params: route.data.params,
                    state
                })
            }
            route.data.content.response = await getFetch({
                helpers,
                fetchreq: route.data.fetchreq,
                path,
                req,
                params: route.data.params
            })
        }
        return route
    }

    r.data.content.response = await getFetch({ helpers, fetchreq, path, req, params })
    return r
}

export default function(c) {

    /*Set Routes*/

    const taxonomies = c.storedConfig.dataTaxonomies
    const posttypes = c.storedConfig.dataPosttypes
    const storedSearchData = c.storedConfig.storedSearchData
    const defaultSearchPostType = c.storedConfig.defaultSearchPostType || 'post'
    const configroutes = c.storedConfig.configroutes
    const searchRoute = configroutes.menusearchslug

    let limit = 18;
    const minuserstatus = getMinUserStatusWhenAllAccess()
    const minpoststatus = getMinPostStatusWhenAllAccess()

    const archiveRoutes = getArchiveRoutes({
        posttypes,
        taxonomies,
        storedSearchData,
        searchRoute,
        defaultSearchPostType,
        replacequery: { sgt: (minpoststatus - 1), asgt: (minuserstatus - 1) },
        limit
    })

    return [
        {
            path: '/',
            data: {
                content: 'home'
            }
        },
        {
            path: '/offline',
            data: {
                content: 'home'
            }
        },
        {
            path: configroutes.menuhomeslug,
            data: {
                content: 'home'
            }
        },

        /*Post type plugins*/

        ...posttypesPlugins.routes({ configroutes, defaultRootAction, requests, c, poststatuses }),

        ...archiveRoutes,

        {
            path: '*',
            data: {
                content: 'home',
                callback: async function({ r, universalPath }) {
                    if (universalPath === '/') {
                        if (r.status === 404) r.status = null
                        return r
                    }
                    r.status = 404
                    r.title = 'Not found'
                    return r
                }
            }
        }
    ]
}
