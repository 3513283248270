import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import SelectField from 'material-ui/SelectField'

import FloatingActionButton from 'material-ui/FloatingActionButton'
import ContentAdd from 'material-ui/svg-icons/content/add'

const MultiSelect = createReactClass({
    getInitialState: function() {
        const { value = [] } = this.props
        return {
            value: value,
            isMounted: false
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    onChange: function(a, b, c) {
        const { onChange } = this.props
        if (onChange) {
            onChange(a, b, c)
        } else {
            this.setState({ value: c })
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d = {}) {
        const { type } = d
        const { addButtonClick } = this.props
        const refElements = this.refElements
        const onChange = this.onChange

        if (type === 'addbutton') {
            if (addButtonClick) addButtonClick({ e, refElements, onChange })
        }
        if (e) e.preventDefault()
    },
    refElements: {},
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    render() {

        const { style, children, componentStyle, addRender, addButtonClick, ...rest } = this.props

        const { value = [] } = this.state
        const onChange = this.onChange
        const touchEnd = this.touchEnd
        const setRef = this.setRef
        const props = this.props

        const input = {
            ...rest,
            style: { width: '100%' },
            onChange,
            multiple: true,
            value
        }

        return (
            <div style={componentStyle} className={style.multiselect}>
                <div className={style.column + ' ' + style.select}>
                    <SelectField {...input} >
                        {(children) ? children : null}
                    </SelectField>
                </div>
                <div className={style.column + ' ' + style.button}>
                    <FloatingActionButton
                        mini={true}
                        onTouchTap={function(e) {
                            touchEnd(e, 'click', 'click', { type: 'addbutton' })
                        }}
                        onTouchStart={function() {

                        }}
                    >
                        <ContentAdd />
                    </FloatingActionButton>
                </div>
                {(addRender) ? addRender({ ...props, setRef, onChange }) : null}
            </div>
        )
    }

})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <MultiSelect {...this.props} ref={(setRef) ? function(e) {
                setRef('MultiSelect', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
