// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vDw7g {\n    padding-top: 0 !important;\n    z-index: 1500 !important;\n}\n\n.vDw7g > div > div {\n    width: 90% !important;\n}\n\n.ErYaB {\n}\n\n.ErYaB > div {\n}\n\n.iSEta {\n    border-radius: 5px !important;\n    background-color: transparent !important;\n    overflow: hidden !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postmodal": "vDw7g",
	"postmodalbody": "ErYaB",
	"button": "iSEta"
};
module.exports = ___CSS_LOADER_EXPORT___;
