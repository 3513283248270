import { combineReducers } from 'redux'
import {
    DELETE_USER,
    SET_ARCHIVEPOSTS,
    SET_ARCHIVEPOSTS_RESET,
    SET_ISBOT,
    SET_LAST_URL,
    SET_QUERY,
    SET_RESPONSE,
    SET_RUNTIME_VARIABLE,
    SET_URL
} from './constants'

function user(state = {}, action) {
    switch (action.type) {
        case DELETE_USER:
            return null
        default:
            return state
    }
}

function response(state = {}, action) {
    switch (action.type) {
        case SET_RESPONSE:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        default:
            return state
    }
}

function url(state = '/', action) {
    switch (action.type) {
        case SET_URL:
            return action.payload.value
        default:
            return state
    }
}

function lasturl(state = '', action) {
    switch (action.type) {
        case SET_LAST_URL:
            return action.payload.value
        default:
            return state
    }
}

function query(state = {}, action) {
    switch (action.type) {
        case SET_QUERY:
            return action.payload.value
        default:
            return state
    }
}

function runtime(state = {}, action) {
    switch (action.type) {
        case SET_RUNTIME_VARIABLE:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        default:
            return state
    }
}

function archivePosts(state = {}, action) {
    switch (action.type) {
        case SET_ARCHIVEPOSTS:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case SET_ARCHIVEPOSTS_RESET:
            return {
                [action.payload.name]: action.payload.value
            }
        default:
            return state
    }
}

function isBot(state = '', action) {
    switch (action.type) {
        case SET_ISBOT:
            return action.payload.value
        default:
            return state
    }
}


export default combineReducers({
    user,
    runtime,
    response,
    url,
    lasturl,
    query,
    archivePosts,
    isBot
})
