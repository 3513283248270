/* eslint-disable import/prefer-default-export */

import {
    DELETE_USER,
    SET_ARCHIVEPOSTS,
    SET_ARCHIVEPOSTS_RESET,
    SET_ISBOT,
    SET_LAST_URL,
    SET_QUERY,
    SET_RESPONSE,
    SET_RUNTIME_VARIABLE,
    SET_URL
} from './constants'

function deleteUser() {
    return {
        type: DELETE_USER,
        payload: {
            value: null
        }
    }
}

function setRuntimeVariable({ name, value }) {
    return {
        type: SET_RUNTIME_VARIABLE,
        payload: {
            name,
            value
        }
    }
}

function setUrl({ value }) {
    return {
        type: SET_URL,
        payload: {
            value
        }
    }
}

function setLastUrl({ value }) {
    return {
        type: SET_LAST_URL,
        payload: {
            value
        }
    }
}

function setQuery({ value }) {
    return {
        type: SET_QUERY,
        payload: {
            value
        }
    }
}

function setResponse({ name, value }) {
    return {
        type: SET_RESPONSE,
        payload: {
            name,
            value
        }
    }
}

function setArchivePosts({ name, value }) {
    return {
        type: SET_ARCHIVEPOSTS,
        payload: {
            name,
            value
        }
    }
}

function setArchivePostsAndReset({ name, value }) {
    return {
        type: SET_ARCHIVEPOSTS_RESET,
        payload: {
            name,
            value
        }
    }
}

function setIsBot({ value }) {
    return {
        type: SET_ISBOT,
        payload: {
            value
        }
    }
}


export default {
    setQuery: setQuery,
    setUrl: setUrl,
    setLastUrl: setLastUrl,
    setRuntimeVariable: setRuntimeVariable,
    setResponse: setResponse,
    deleteUser: deleteUser,
    setArchivePosts: setArchivePosts,
    setArchivePostsAndReset: setArchivePostsAndReset,
    setIsBot: setIsBot
}
