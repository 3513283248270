import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <a {...this.props}>{children}</a>
        )
    }
})
