import posttypesPlugins from '../posttypes'

export default function(c) {
    /*Set Contents*/

    const curlang = c.storedConfig.curlang
    const meta = c.storedConfig.meta
    const templates = c.templates

    const {
        description = '',
        sitenametitle = ''
    } = curlang

    const {
        siteurl = '',
        sitename = '',
        siteimage = ''
    } = meta

    return {
        'home': {
            title: curlang.menuhometitle + sitenametitle,
            component: 'content',
            content: templates.home,
            description,
            siteurl,
            sitename,
            siteimage
        },
        'search': {
            title: curlang.menusearchtitle + sitenametitle,
            component: 'content',
            content: templates.search,
            description,
            siteurl,
            sitename,
            siteimage
        },
        'archive': {
            title: curlang.menuarchivetitle + sitenametitle,
            component: 'content',
            content: templates.archive,
            description,
            siteurl,
            sitename,
            siteimage
        },

        ...posttypesPlugins.contents({ curlang, templates, c })
    }
}
