import React from 'react'
import createReactClass from 'create-react-class'

import ps from '../../themes/components/Wapplrpost.css'

import IconButton from 'material-ui/IconButton'
import DuplicateIcon from 'material-ui/svg-icons/content/content-copy'

import fetchs from '../services/fetchs'
import { getPostStatusData } from '../server/poststatus/statuses'
import { getUserStatusData } from '../server/userstatus/statuses'

import Wapplrdialog from '../components/Wapplrdialog'
import Wapplrsnackbar from '../components/Wapplrsnackbar'

import wapplrcomponents from '../../components'
import TemplateDefault from '../templates/template_default'

import fs from '../../themes/components/Wapplrfeed.css'
import avs from '../../themes/components/Wapplravatar.css'

import NoMatches from '../components/NoMatches'
import nms from '../components/NoMatches/NoMatches.css'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'

import MetaData from '../components/MetaData'
import mds from '../components/MetaData/MetaData.css'
import TimeIcon from 'material-ui/svg-icons/device/access-time'
import StatusIcon from 'material-ui/svg-icons/editor/vertical-align-center'

import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down'
import UpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up'

import AdminPost from '../components/Admin/Post.js'
import adminpostfunctions from '../components/Admin/postfunctions.js'

const Wapplrfeed = wapplrcomponents.feed
const Breadcrumb = wapplrcomponents.breadcrumb
const Pagination = wapplrcomponents.pagination

function getTaxData(p) {
    const { taxonomiesData, key, posttype, countPropertyName } = p

    const taxonomy = taxonomiesData[key]
    const items = p.items || taxonomy.items

    const data = items.map((item) => {
        const count = getTaxonomyCount({
            taxonomiesData,
            taxonomiesPath: key,
            items,
            taxonomyName: item.name,
            posttype,
            propertyName: countPropertyName
        })
        return {
            ...item,
            name: count ? item.name + ' (' + count + ')' : item.name
        }
    })

    return { data, taxonomy }
}

function getTaxonomyCount(p = {}) {

    const { taxonomiesData, taxonomiesPath, taxonomyName, posttype, propertyName = 'countbyposttype' } = p
    let count = 0

    if (taxonomiesData && taxonomiesPath && taxonomyName && posttype &&
        taxonomiesData[taxonomiesPath] && taxonomiesData[taxonomiesPath].items) {

        const posttypes = taxonomiesData[taxonomiesPath].posttype
        const items = p.items || taxonomiesData[taxonomiesPath].items

        posttypes.map(function(pt, pti) {
            if (pt === posttype) {
                items.map(function(taxonomy) {
                    if (taxonomy.name === taxonomyName && taxonomy[propertyName] && taxonomy[propertyName].length) {
                        count = taxonomy[propertyName][pti] || 0
                    }
                })
            }
        })

    }

    return count
}

const TaxonomiesFeeds = createReactClass({
    postTypeFilter({ key }) {
        const { taxonomiesData, posttype } = this.props
        let r = false

        if (!posttype) r = true
        if (posttype && taxonomiesData[key] && taxonomiesData[key].posttype) {
            taxonomiesData[key].posttype.map(function(pt) {
                if (pt === posttype) r = true
            })
        }

        return r
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    render: function() {

        const {
            taxonomiesData,
            searchData,
            history,
            feedDatas,
            feedRefs,
            posttype,
            onClick,
            countPropertyName
        } = this.props
        const taxKeys = (taxonomiesData) ? Object.keys(taxonomiesData) : null
        const postTypeFilter = this.postTypeFilter
        const props = this.props

        return (
            <div>
                {(taxKeys && taxKeys.length) ?
                    taxKeys.map(function(key, index) {
                        const go = postTypeFilter({ key })
                        if (go) {

                            const { data, taxonomy } = getTaxData({ taxonomiesData, key, posttype, countPropertyName })

                            return <Wapplrfeed
                                key={index}
                                data={data}
                                ref={function(e) {
                                    feedRefs('archive' + key, e)
                                }}
                                feedData={feedDatas['archive' + key]}
                                history={history}
                                style={fs}
                                enableLocation={true}
                                enableTitleHref={true}
                                enableThumbHref={true}
                                to={(props.posttypepath) ? '/' + props.posttypepath + '/' + taxonomy.path : taxonomy.path}
                                selectable={false}
                                searchData={searchData}
                                author={props.author}
                                searchObjectName={taxonomy.path}
                                posttypepath={props.posttypepath}
                                onClick={onClick ? (e, data) => onClick({
                                    e,
                                    data,
                                    taxonomyPath: taxonomy.path
                                }) : null}
                            />
                        }
                        return null
                    }) : null
                }
            </div>
        )
    }
})

const Search = createReactClass({
    getInitialState: function() {

        this.paginationProcessing = false
        this.refElements = {}
        const { response } = this.props
        const { posts, postsdata } = this.getPosts({ response })

        const author = this.getAuthor({ postsdata })
        const posttype = this.getPostType()
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath

        const { taxonomiesData, searchData } = this.props
        this.historyHelpers = searchData.getHistoryHelpers()
        const {
            currentTaxonomies,
            pathname,
            taxonomyslug,
            currentTaxonomy,
            hierarchy
        } = this.historyHelpers.getTaxonomiesFromSearch(taxonomiesData)

        return {
            isMounted: true,
            posts: posts || null,
            author: author || null,
            postsdata: postsdata || null,
            posttype: posttype || '',
            posttypepath: posttypepath || '',
            posttypename: posttypename || '',
            openfilter: false,

            currentTaxonomies: currentTaxonomies,
            currentTaxonomy: currentTaxonomy,
            pathname: pathname,
            taxonomyslug: taxonomyslug,
            hierarchy: hierarchy
        }
    },
    querySearchText: function() {
        const { searchData } = this.props
        const { store } = this.getHelpers()
        const historyHelpers = searchData.getHistoryHelpers()
        const h = historyHelpers.getDataFromHistory({ store })
        return historyHelpers.objectToString(h)
    },
    getPosts: function({ response }) {

        if (response && response.archive && response.archive.posts) response.archive.posts = this.usersToPostsData({ posts: response.archive.posts })
        let posts = (response && response.archive && response.archive.posts) ? response.archive.posts : null
        let postsdata = (response && response.archive && response.archive.data) ? response.archive.data : null

        const { helpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        const queryString = this.querySearchText()

        if (state.archivePosts && state.archivePosts[queryString]) {
            posts = state.archivePosts[queryString].posts
            postsdata = state.archivePosts[queryString].postsdata
            return { posts, postsdata }
        }

        return { posts, postsdata }

    },
    getAuthor: function({ postsdata }) {
        if (postsdata && postsdata.author) return postsdata.author
        return null
    },
    addToPosts: async function({ response }) {
        if (this.state.isMounted) {
            const posts = (this.state.posts && this.state.posts.length) ? [...this.state.posts] : []
            const postsdata = (this.state.postsdata) ? { ...this.state.postsdata } : {}

            const getnewposts = this.getPosts({ response })
            const newposts = getnewposts.posts
            const newpostsdata = getnewposts.postsdata

            if (newposts && newposts.length) {
                posts.push(...newposts)

                const pagi = (this.refElements['pagination']) ? this.refElements['pagination'] : {
                    setProcessing: async function() {
                        return true
                    }
                }
                await pagi.setProcessing(false)

                this.paginationProcessing = false

                await this.setState({
                    posts: posts,
                    postsdata: { ...newpostsdata, skip: postsdata.skip, skippedpages: postsdata.skippedpages }
                })
            }
        }
    },
    getPostType: function() {
        const { helpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return state.query.posttype || ''
    },
    getPostTypePath: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].path) ? posttypesData[posttype].path : ''
    },
    getPostTypeName: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].pluralname) ? posttypesData[posttype].pluralname : ''
    },

    historyPushFetch: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    setPosts: function(a) {
        const p = this.usersToPostsData({ posts: a.posts })
        this.setState({ posts: p })
    },
    getUserName: function({ user }) {
        const firstname = (user && user.name && user.name.first) ? user.name.first : ''
        const lastname = (user && user.name && user.name.last) ? user.name.last : ''
        return (firstname && lastname) ? firstname + ' ' + lastname : (firstname) ? firstname : lastname
    },
    usersToPostsData: function({ posts }) {

        const { defaultthumbnail = '/thumb.jpg' } = this.props

        const posttype = this.getPostType()
        if (posttype === 'User') {
            const r = []
            const getUserName = this.getUserName
            posts.map(function(user) {
                if (!user.wasUserDataToPost) {
                    const avatar = (user.avatar) ? user.avatar : defaultthumbnail
                    r.push(
                        {
                            id: user.id,
                            author: user,
                            title: getUserName({ user }),
                            status: user.status,
                            cover: avatar,
                            registratedDate: user.registratedDate,
                            wasUserDataToPost: true
                        }
                    )
                } else {
                    r.push(user)
                }
            })
            return r
        } else {
            return posts
        }
    },
    componentWillMount: function() {

        const { curlang, history } = this.props
        const posttype = this.getPostType()

        const getHelpers = this.getHelpers
        const dialogClose = this.dialogClose
        const dialogOpen = this.dialogOpen
        const snack = this.snack
        const state = this.state
        const setPosts = this.setPosts

        if (posttype) {
            adminpostfunctions({
                posttype: posttype.toLowerCase(),
                t: this,
                curlang,
                fetchs,
                history,
                getHelpers,
                dialogClose,
                dialogOpen,
                snack,
                state,
                setState: setPosts
            })
        }

    },
    componentDidMount: function() {

        const { searchData, curlang, history } = this.props

        const {
            duplicatepostdefaultfail = 'Sorry, there was an issue duplicate your post, please try again',
            duplicatepostsuccessmessage = 'Your post has been duplicated'
        } = curlang

        this.historyHelpers = searchData.getHistoryHelpers()

        const posttype = this.getPostType()

        if (posttype === 'Player'){

            const dialogClose = this.dialogClose
            const snack = this.snack
            const historyPush = this.historyPushFetch

            this.duplicatefetch = fetchs.duplicateplayer({
                getHelpers: this.getHelpers,
                history: history,
                success: function({ response = {} }) {
                    dialogClose()
                    snack(duplicatepostsuccessmessage)
                    setTimeout(function() {
                        historyPush('/player/' + response.postId)
                    }, 1500)
                },
                fail: function({ response, invalidateForm }) {
                    if (response.messages) {
                        invalidateForm(response.messages)
                    } else {
                        const field = response.field || ''
                        const message = response.message || duplicatepostdefaultfail
                        if (field && message) invalidateForm({ [field]: message })
                    }
                }
            })

        }

    },
    componentWillUnmount: function() {
        this.setState({ isMounted: false })
    },
    historyPush: function(page) {
        if (page) {
            const { history } = this.props
            const { store } = this.getHelpers()
            const historyHelpers = this.historyHelpers
            const h = historyHelpers.getDataFromHistory({ store })
            h.page = page
            const searchText = historyHelpers.objectToString(h)
            const hash = (history.location.hash) ? history.location.hash.slice(1) : ''
            historyHelpers.historyPush({ search: searchText, hash }, true)
        }
    },
    paginationOnClick: async function(e) {
        if (e) {
            e.preventDefault()
        }

        if (this.state.isMounted && !this.paginationProcessing) {

            let pagi = (this.refElements['pagination']) ? this.refElements['pagination'] : {
                setProcessing: async function() {
                    return true
                }
            }
            await pagi.setProcessing(true)

            this.paginationProcessing = true

            const scrollDiv = ('undefined' !== typeof document) ? (document.scrollingElement) ? document.scrollingElement : document.body : null
            const { getFetch, helpers } = this.props
            const state = (helpers && helpers.store) ? helpers.store.getState() : null
            state.query.page = (state.query.page) ? Number(state.query.page) + 1 : 2
            const tempHref = window.location.href

            const response = await getFetch({ state })

            if (this.state.isMounted && tempHref === window.location.href) {

                const wy = (scrollDiv && scrollDiv.scrollTop) ? scrollDiv.scrollTop : 0

                await this.addToPosts({ response: response })

                const store = helpers.store
                const states = (helpers.states) ? helpers.states : null
                const setArchivePostsAndReset = (states && states.actions && states.actions.setArchivePostsAndReset) ? states.actions.setArchivePostsAndReset : null
                const queryString = this.querySearchText()

                const { posts, postsdata } = this.state

                store.dispatch(setArchivePostsAndReset({
                    name: queryString,
                    value: {
                        posts,
                        postsdata
                    }
                }))

                if (this.state.isMounted && tempHref === window.location.href) {
                    this.historyPush(state.query.page)
                    if (wy) scrollDiv.scrollTop = wy
                }

            } else {

                pagi = (this.refElements['pagination']) ? this.refElements['pagination'] : {
                    setProcessing: async function() {
                        return true
                    }
                }
                await pagi.setProcessing(false)

                this.paginationProcessing = false

            }
        }
    },
    publicicon: function({ post }) {
        const share = post && post.share
        const author = this.state && this.state.author
        if (author) {
            if (share === 'public' || !share) {
                return <PublicIcon />
            }
            if (share === 'private') {
                return <PrivateIcon />
            }
        }
        return null
    },

    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },

    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },

    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },

    duplicatefetch: function() {
    },
    duplicatefunction: function(e, d) {

        const { curlang } = this.props

        const {
            duplicatepostsubtitle = 'Duplicate',
            duplicatepostquestion = 'Are you sure want to duplicate your post?',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const { post } = d

        const duplicatefetch = this.duplicatefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: duplicatepostsubtitle,
            text: duplicatepostquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = post
                data.duplicate = true

                const resetForm = function() {
                }
                const invalidateForm = function(data) {
                    console.log(data)
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                duplicatefetch(data, resetForm, invalidateForm, refs)

            }
        })

    },

    setStatusName: function({ statusname }) {
        let s = statusname
        const me = this.getMe()
        const admin = me && me.isAdmin || me && me.isEditor

        if (!admin) {
            if (s === 'deleted itself') {
                s = 'deleted'
            }
            if (s === 'created') {
                s = 'published'
            }
            if (s === 'required data') {
                s = 'published'
            }
            if (s === 'approved') {
                s = 'published'
            }
        }

        return s
    },

    getPostStatus: function({ post }) {
        const { curlang } = this.props
        return getPostStatusData({ post, curlang })
    },

    getUserStatus: function({ post }) {
        const { curlang } = this.props
        return getUserStatusData({ user: post, curlang })
    },

    duplicateicon: function({ post, style }) {
        const { deleteorrestore } = this.getPostStatus({ post })
        const me = this.getMe()
        const posttype = this.getPostType()
        const author = (post && post.author) ? post.author : null

        const canEdit = (me && me.id && author && author.id && author.isEditor || me && me.id && author && author.id && author.isAdmin)

        if (posttype === 'Player' && deleteorrestore === 'delete' && canEdit) {
            const duplicatefunction = this.duplicatefunction
            return (
                <IconButton
                    onTouchTap={function(e) {
                        duplicatefunction(e, { post })
                    }}
                    onTouchStart={function() {

                    }}
                    className={style.cardrightbuttonicon}
                >
                    <DuplicateIcon />
                </IconButton>
            )
        }
        return null
    },

    contentcomponent: function({ post }) {
        if (post) {

            const { curlang = {} } = this.props
            const posttype = this.getPostType()
            const getStatus = (posttype === 'User') ? this.getUserStatus : this.getPostStatus
            const { statusname, approveenable, banenable } = getStatus({ post })
            const me = this.getMe()
            const author = (post && post.author) ? post.author : null
            const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)
            const isAdmin = (me && me.isAdmin || me && me.isEditor)

            const approvefunction = (posttype) ? this['approvefunction' + posttype.toLowerCase()] : function() {
            }
            const banfunction = (posttype) ? this['banfunction' + posttype.toLowerCase()] : function() {
            }

            const data = [
                { icon: <TimeIcon />, name: curlang.publisheddate, value: 'publishedDate' },
                { icon: <TimeIcon />, name: curlang.metaregistrateddate, value: 'registratedDate' },
                {
                    icon: <StatusIcon />, name: curlang.status, value: function(props) {

                        const { post = {}, style } = props
                        if (canEdit) {
                            return (
                                <div>
                                    <div>
                                        {statusname}
                                    </div>
                                    {(isAdmin && approveenable || isAdmin && banenable) ?
                                        <div className={style.innerButtons}>
                                            <AdminPost
                                                curlang={curlang}
                                                approveenable={approveenable}
                                                banenable={banenable}
                                                approvefunction={approvefunction}
                                                banfunction={banfunction}
                                                post={post}
                                            />
                                        </div> : null
                                    }
                                </div>
                            )

                        }
                        return null
                    }
                }
            ]

            return (
                <MetaData
                    post={post}
                    style={mds}
                    data={data}
                />
            )
        }
        return null
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },


    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    setRef: function(a, e) {
        this.refElements[a] = e
    },

    openfilters: function() {
        const { openfilter } = this.state
        if (openfilter) {
            this.setState({
                openfilter: false
            })
        } else {
            this.setState({
                openfilter: true
            })
        }
    },

    openfiltersicon: function() {

        const openfilters = this.openfilters
        const state = this.state

        const showtaxs = !!(state.isMounted && state.currentTaxonomy && state.currentTaxonomy.data || state.isMounted && state.currentTaxonomies)
        const showtaxfeeds = !showtaxs
        const items = (state.currentTaxonomy) ? (state.currentTaxonomy.items) ? state.currentTaxonomy.items : [] : (state.currentTaxonomies) ? state.currentTaxonomies.items : []

        if (items.length || showtaxfeeds) {
            return (
                <div>
                    <IconButton
                        onTouchTap={function(e) {
                            openfilters(e)
                        }}
                        onTouchStart={function() {

                        }}
                    >
                        {(!state.openfilter) ? <DownIcon /> : <UpIcon />}
                    </IconButton>
                </div>
            )
        }

        return null
    },
    disabletaxs: function() {
        const { history } = this.props
        return !!(history.location && history.location.hash && history.location.hash.match('hidetaxs'))

    },
    taxOnClick: function(p) {

        const {
            e,
            data,
            taxonomyPath,
            searchData,
            history,
            postTypePath,
            author,
            enableSearchQueryDataInheritance = [
                'text', 'user', 'sgt', 'slt', 'asgt', 'aslt'
            ]
        } = p

        e.preventDefault()

        let search = this.querySearchText()
        search = search.split('&').filter((t) => enableSearchQueryDataInheritance.find((key) => t.match(new RegExp('&' + key + '+(\\=[^&]*)?(?=&|$)|^' + key + '(\\=[^&]*)?(&|$)')))).join('&')

        const hash = history.location.hash || ''

        let searchQuery = ''
        const historyHelpers = (searchData.getHistoryHelpers) ? searchData.getHistoryHelpers() : null

        if (historyHelpers) {
            const searchQueryObj = historyHelpers.getTaxonomyLink({
                slug: taxonomyPath,
                pluralslug: taxonomyPath
            }, data, postTypePath)

            searchQuery = searchQueryObj.search

            if (author) {
                searchQuery = (searchQuery) ?
                    searchQuery + '&' + historyHelpers.getAuthorSearch(author) :
                    historyHelpers.getAuthorSearch(author)
            }

        }

        const to = (postTypePath) ? '/' + postTypePath + '/' + taxonomyPath : taxonomyPath
        const url = to + ((search) ? '?' + search + '&' : '?') + searchQuery + ((hash) ? hash : '')

        history.push(url)
    },
    render: function() {

        const {
            style,
            history,
            searchData,
            feedRefs,
            feedDatas,
            postSlug,
            postSearchObjectName,
            curlang,
            getthumb,
            helpers,
            getthumbstyle,
            taxonomiesData
        } = this.props
        const state = this.state
        const setRef = this.setRef
        const disabletaxs = this.disabletaxs()
        let showtaxs = !!(state.isMounted && state.currentTaxonomy && state.currentTaxonomy.data || state.isMounted && state.currentTaxonomies)
        let showtaxfeeds = !showtaxs
        if (disabletaxs) {
            showtaxfeeds = false
            showtaxs = false
        }
        const showposts = !!(state.posts && state.posts.length)
        const {
            notresultstext,
            loadmoredatatext
        } = curlang

        const { store } = this.getHelpers()
        const historyHelpers = searchData.getHistoryHelpers()
        const searchDataObject = historyHelpers.getDataFromHistory({ store })
        const publicicon = this.publicicon
        const duplicateicon = this.duplicateicon
        const contentcomponent = this.contentcomponent
        const openfiltersicon = (!disabletaxs) ? this.openfiltersicon : null

        const isBot = this.getGlobalState().isBot

        if (isBot && feedDatas['archive_posts' + state.taxonomyslug]) {
            feedDatas['archive_posts' + state.taxonomyslug].setDataCurrent('showContent', true)
        }

        const me = this.getMe()
        const isAdmin = (me && me.isAdmin || me && me.isEditor)

        const countPropertyName = (searchDataObject.slt === '21' && isAdmin) ? 'countbyposttypeBan' : 'countbyposttype'

        const taxOnClick = this.taxOnClick

        const taxData = (showtaxs) ?
            getTaxData({
                taxonomiesData,
                items: (state.currentTaxonomy) ? (state.currentTaxonomy.items) ? state.currentTaxonomy.items : [] : state.currentTaxonomies.items,
                key: state.taxonomyslug,
                posttype: state.posttype,
                countPropertyName
            }) :
            { data: [], taxonomy: {} }

        return (
            <div>
                {(state.isMounted && state.hierarchy) ?
                    <Breadcrumb
                        style={fs}
                        className={style['page-subheader']}
                        enablestickystyle={true}
                        hierarchy={state.hierarchy}
                        taxonomies={state.currentTaxonomies}
                        historyHelpers={this.historyHelpers}
                        posts={this.state.posts}
                        data={this.state.postsdata}
                        author={this.state.author}
                        avatarstyle={avs}
                        posttypepath={this.state.posttypepath}
                        posttypename={this.state.posttypename}
                        getthumb={getthumb}
                        curlang={curlang}
                        openfiltersicon={openfiltersicon}
                        enableSearchQueryDataInheritance={['text', 'user', 'sgt', 'slt', 'asgt', 'aslt', 'secuser', 'sh']}
                        helpers={helpers}
                        history={history}
                    /> : null}
                {(showtaxfeeds || showtaxs) ?
                    <div>
                        {(state.openfilter) ?
                            <div>
                                <div>
                                    {showtaxfeeds ?
                                        <TaxonomiesFeeds
                                            {...this.props}
                                            author={this.state.author}
                                            searchDataObject={searchDataObject}
                                            historyHelpers={historyHelpers}
                                            posttypepath={this.state.posttypepath}
                                            posttypename={this.state.posttypename}
                                            posttype={this.state.posttype}
                                            onClick={(props) => taxOnClick({
                                                ...props,
                                                searchData,
                                                history,
                                                postTypePath: this.state.posttypepath,
                                                author: this.state.author,
                                                enableSearchQueryDataInheritance: ['text', 'user', 'sgt', 'slt', 'asgt', 'aslt', 'secuser', 'sh']
                                            })}
                                            countPropertyName={countPropertyName}
                                        /> : null}
                                    {showtaxs ?
                                        <div>
                                            <Wapplrfeed
                                                ref={function(e) {
                                                    feedRefs('archive' + state.taxonomyslug, e)
                                                }}
                                                feedData={feedDatas['archive' + state.taxonomyslug]}
                                                history={history}
                                                style={fs}
                                                data={taxData.data}
                                                enableLocation={true}
                                                enableTitleHref={true}
                                                enableThumbHref={true}
                                                to={(this.state.posttypepath) ? '/' + this.state.posttypepath + state.pathname : state.pathname}
                                                selectable={false}
                                                searchData={searchData}
                                                searchObjectName={taxData.taxonomy.path}
                                                author={this.state.author}
                                                posttypepath={this.state.posttypepath}
                                                getthumb={getthumb}
                                                getthumbstyle={getthumbstyle}
                                                onClick={(e, data) => taxOnClick({
                                                    e,
                                                    data,
                                                    taxonomyPath: taxData.taxonomy.path,
                                                    searchData,
                                                    history,
                                                    postTypePath: this.state.posttypepath,
                                                    author: this.state.author,
                                                    enableSearchQueryDataInheritance: ['text', 'user', 'sgt', 'slt', 'asgt', 'aslt', 'secuser', 'sh']
                                                })}
                                            />
                                        </div> : null}
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    null
                }
                {showposts ?
                    <div>
                        <Wapplrfeed
                            ref={function(e) {
                                feedRefs('archive_posts' + state.taxonomyslug, e)
                            }}
                            feedData={feedDatas['archive_posts' + state.taxonomyslug]}
                            history={history}
                            style={fs}
                            data={state.posts}
                            getTitle={function({ post }) {
                                return post && post.title || post && post.name
                            }}
                            showauthor={true}
                            enableUserLink={true}
                            avatarstyle={avs}
                            enableLocation={true}
                            enableTitleHref={true}
                            enableThumbHref={true}
                            enablePermalink={true}
                            to={(this.state.posttype) ? '/' + this.state.posttype.toLowerCase() : postSlug}
                            selectable={false}
                            searchData={searchData}
                            searchObjectName={postSearchObjectName}
                            publicicon={publicicon}
                            duplicateicon={duplicateicon}
                            contentcomponent={contentcomponent}
                            getthumb={getthumb}
                            getthumbstyle={getthumbstyle}
                            thumbheight={{ height: '0', paddingBottom: '56%' }}
                            isMounted={true}
                            showContent={isBot}
                            getavatarclassname={function({ className, avatarProps }) {
                                const { style, state } = avatarProps
                                let rClassName = style['avatarbox_' + state.type]
                                if (className) rClassName = rClassName + ' ' + className
                                return rClassName
                            }}
                        />
                        {(state.isMounted) ?
                            <Pagination
                                onClick={this.paginationOnClick}
                                ref={function(e) {
                                    setRef('pagination', e)
                                }}
                                infiniteScroll={true}
                                style={fs}
                                className={style['page-subheader']}
                                history={history}
                                historyHelpers={historyHelpers}
                                data={this.state.postsdata}
                                loadMoreDataText={loadmoredatatext}
                                store={store}
                            /> : null
                        }
                    </div>
                    :
                    <NoMatches style={nms} notFoundText={notresultstext} />
                }
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
                <Wapplrdialog setRef={setRef} className={ps.dialog} />
            </div>
        )
    }
})

export default createReactClass({
    render: function() {
        return (
            <TemplateDefault {...this.props} >
                <Search {...this.props} />
            </TemplateDefault>
        )
    }
})
