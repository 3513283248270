import React from 'react'

import DrawerComponent from './DrawerComponent'
import MenuComponent from './MenuComponent'
import MenuItems from './MenuItems'
import LogoComponent from './LogoComponent'

import MenuItem from 'material-ui/MenuItem'
import List, { ListItem } from 'material-ui/List'

export default {
    addfixeddrawer1: function(props) {
        const { style, drawerwidth, onDrawerChange, setRef, state, data, openmenutooltip, closemenutooltip } = props
        return (
            <DrawerComponent style={style} drawerwidth={drawerwidth} onDrawerChange={onDrawerChange}
                             ref={function(e) {
                                 setRef('drawer', e)
                             }} state={state} data={data}
                             openmenutooltip={openmenutooltip} closemenutooltip={closemenutooltip}>
                <MenuItems {...props} Menuitem={ListItem} classname={'side'} Parent={List} />
            </DrawerComponent>
        )
    },
    addfixeddrawer2: function(props) {
        const { Fixedmenuicon, popperRoot } = props
        const parentprops = (Fixedmenuicon) ? { icon: Fixedmenuicon, popperRoot } : { popperRoot }
        return <MenuItems {...props} Menuitem={MenuItem} classname={'side'} Parent={MenuComponent}
                          parentprops={parentprops} />
    },
    addfixeddrawer3: function(props) {
        const {
            style,
            drawerwidth,
            onDrawerChange,
            setRef,
            state,
            logo,
            data,
            openmenutooltip,
            closemenutooltip
        } = props
        return (
            <div className={style.swapdirection + ' ' + style.navwithlogowrapper}>
                <DrawerComponent style={style} drawerwidth={drawerwidth} onDrawerChange={onDrawerChange}
                                 ref={function(e) {
                                     setRef('drawer', e)
                                 }} state={state} data={data}
                                 openmenutooltip={openmenutooltip} closemenutooltip={closemenutooltip}>
                    <MenuItems {...props} Menuitem={ListItem} classname={'side'} Parent={List} />
                </DrawerComponent>
                {logo && !state.hidelogo && <LogoComponent {...props} />}
            </div>
        )
    },
    addfixeddrawer4: function(props) {
        const { style, state, logo } = props
        const { Fixedmenuicon, popperRoot } = props
        const parentprops = (Fixedmenuicon) ? { icon: Fixedmenuicon, popperRoot } : { popperRoot }
        return (
            <div className={style.swapdirection + ' ' + style.navwithlogowrapper}>
                <MenuItems {...props} Menuitem={MenuItem} classname={'side'} Parent={MenuComponent}
                           parentprops={parentprops} />
                {logo && !state.hidelogo && <LogoComponent {...props} />}
            </div>
        )
    },
    addsidebymenutype1: function(props) {
        const {
            style,
            state,
            drawerwidth,
            onDrawerChange,
            setRef,
            swapdirection,
            data,
            openmenutooltip,
            closemenutooltip
        } = props
        return (
            <div className={style.swapdirection + ' ' + style.navwithlogowrapper}>
                <DrawerComponent style={style} drawerwidth={drawerwidth} onDrawerChange={onDrawerChange}
                                 ref={function(e) {
                                     setRef('drawer', e)
                                 }} state={state} data={data}
                                 openmenutooltip={openmenutooltip} closemenutooltip={closemenutooltip}>
                    <MenuItems {...props} Menuitem={ListItem} classname={'side'} Parent={List} />
                </DrawerComponent>
                {swapdirection && !state.hidelogo && <LogoComponent {...props} />}
            </div>
        )
    },
    addsidebymenutype2: function(props) {
        const { style, state, swapdirection, popperRoot } = props
        const parentprops = { popperRoot }
        return (
            <div className={style.swapdirection + ' ' + style.navwithlogowrapper}>
                <MenuItems {...props} Menuitem={MenuItem} classname={'side'} Parent={MenuComponent}
                           parentprops={parentprops} />
                {swapdirection && !state.hidelogo && <LogoComponent {...props} />}
            </div>
        )
    },
    addsidebymenutype3: function(props) {
        const { style, state, swapdirection } = props
        return (
            <div className={style.navwithlogowrapper}>
                {swapdirection && !state.hidelogo && <LogoComponent {...props} />}
            </div>
        )
    },
    addsidebymenutype4: function(props) {
        const { style, state, drawerwidth, onDrawerChange, setRef, data, openmenutooltip, closemenutooltip } = props
        return (
            <DrawerComponent style={style} drawerwidth={drawerwidth} onDrawerChange={onDrawerChange}
                             ref={function(e) {
                                 setRef('drawer', e)
                             }} state={state} data={data}
                             openmenutooltip={openmenutooltip} closemenutooltip={closemenutooltip}>
                <MenuItems {...props} Menuitem={ListItem} classname={'side'} Parent={List} />
            </DrawerComponent>
        )
    },
    addsidebymenutype5: function(props) {
        const { popperRoot } = props
        const parentprops = { popperRoot }
        return <MenuItems {...props} Menuitem={MenuItem} classname={'side'} Parent={MenuComponent}
                          parentprops={parentprops} />
    },
    addmenu1: function(props) {
        const { style, state, setRef, swapdirection } = props
        return (
            <div className={style.navwithlogowrapper} ref={function(e) {
                setRef('nav', e)
            }}>
                {!swapdirection && !state.hidelogo && <LogoComponent {...props} />}
                <MenuItems {...props} />
            </div>
        )
    },
    addmenu2: function(props) {
        const { setRef } = props
        return (
            <div ref={function(e) {
                setRef('nav', e)
            }}>
                <MenuItems {...props} />
            </div>
        )
    },
    addmenu3: function(props) {
        const { style, state, setRef, swapdirection } = props
        return (
            <div className={style.navwithlogowrapper} ref={function(e) {
                setRef('nav', e)
            }}>
                {!swapdirection && !state.hidelogo && <LogoComponent {...props} />}
            </div>
        )
    },
    addlist: function(props) {
        return (
            <MenuItems {...props} Menuitem={ListItem} classname={'side'} Parent={List} />
        )
    }
}
