import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import TemplateHome from '../templates/template_home'
import headerstyle from '../../themes/components/Wapplrmenu.css'
import FlatButton from 'material-ui/FlatButton'
import DashboardIcon from 'material-ui/svg-icons/action/dashboard'
import OfferIcon from 'material-ui/svg-icons/maps/local-offer'
import UserIcon from 'material-ui/svg-icons/social/person'
import SignupIcon from 'material-ui/svg-icons/content/add-circle'

import s from '../customstyle/default/home.css'

import Logo from "../components/Logo";
import FC from "../components/Logo/FC";

const Home = createReactClass({
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {

        const { type, href } = d
        const { history } = this.props
        const me = this.getMe()

        if (type === 'href' && href) {
            history.push(href)
            e.preventDefault()
        }

        if (type === 'login') {
            if (me && me.id) {
                history.push('/user/' + me.id)
            } else {
                history.push({
                    pathname: '/account'
                })
            }
        }

        if (type === 'signup') {
            if (me && me.id) {
                history.push('/user/' + me.id)
            } else {
                history.push({
                    pathname: '/account/signup'
                })
            }
        }

        if (type === 'player') {
            history.push('/players')
        }

        e.preventDefault()

    },
    render: function() {
        const { homestyle, curlang = {}, sitename = '' } = this.props



        const touchEnd = this.touchEnd

        const me = this.getMe()

        return (
            <TemplateHome {...this.props} headerstyle={headerstyle} className={'fullwidthcontainer'}>
                <div className={homestyle.homeSliderDiv}>
                    <div className={homestyle.homeslidercontentdiv}>
                        <div className={homestyle.logoContainer}>
                            <div className={homestyle.logo}>
                                <Logo />
                            </div>
                            <div className={homestyle.FC}>
                                <FC />
                            </div>
                        </div>
                        <h1 className={homestyle.homesliderh1}>
                            {curlang.welcometitle}
                        </h1>
                        <h2 className={homestyle.homesliderh2}>
                            <div>{curlang.welcometitle1}</div>
                        </h2>

                        <div className={homestyle.homesliderbuttonsdiv}>
                            {(me && me.id) ?
                                <div>
                                    <FlatButton label={curlang.yourprofile}
                                                onTouchTap={function(e) {
                                                    touchEnd(e, 'click', 'click', { type: 'login' })
                                                }}
                                                onTouchStart={function() {

                                                }}
                                                icon={<DashboardIcon />}
                                    />
                                    <FlatButton label={curlang.playersbutton}
                                                onTouchTap={function(e) {
                                                    touchEnd(e, 'click', 'click', { type: 'player' })
                                                }}
                                                onTouchStart={function() {

                                                }}
                                                icon={<OfferIcon />}
                                    />
                                </div>
                                :
                                <div>
                                    <FlatButton label={curlang.registrationbutton}
                                                onTouchTap={function(e) {
                                                    touchEnd(e, 'click', 'click', { type: 'signup' })
                                                }}
                                                onTouchStart={function() {

                                                }}
                                                icon={<SignupIcon />}
                                    />
                                    <FlatButton label={curlang.loginbutton}
                                                onTouchTap={function(e) {
                                                    touchEnd(e, 'click', 'click', { type: 'login' })
                                                }}
                                                onTouchStart={function() {

                                                }}
                                                icon={<UserIcon />}
                                    />
                                    <FlatButton label={curlang.playersbutton}
                                                onTouchTap={function(e) {
                                                    touchEnd(e, 'click', 'click', { type: 'player' })
                                                }}
                                                onTouchStart={function() {

                                                }}
                                                icon={<OfferIcon />}
                                    />
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </TemplateHome>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Home {...this.props} ref={(setRef) ? function(e) {
                setRef('home', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { homestyle = s, disableInitWithStyles } = this.props
        const R = (homestyle && !disableInitWithStyles) ? withStyles(homestyle)(Middle) : Middle
        const input = { ...this.props, homestyle }
        return (
            <R {...input} />
        )
    }
})
