import React from 'react'
import { getComponents, getContents, getFirstReq, getRoutes, getStates, getTemplates } from './services'
import wapplrcomponents from '../components'

let storedConfig = null

export function beforeInit() {

    const firstreq = getFirstReq()
    const states = getStates()

    storedConfig = {
        states: states,
        firstReq: firstreq
    }

    return storedConfig
}

export default function(c = {}) {

    if (storedConfig && storedConfig.initalized) return storedConfig

    const history = c.history || {}
    const style = storedConfig.style || c.style
    const templates = storedConfig.templates || c.templates || getTemplates({ ...c, history, style, storedConfig })
    const components = storedConfig.components || c.components || getComponents({
        wapplrcomponents,
        storedConfig,
        wapp: c.wapp
    })
    const routes = storedConfig.routes || c.routes || getRoutes({ ...c, storedConfig })
    const contents = storedConfig.contents || c.contents || getContents({ ...c, storedConfig, templates })
    const states = storedConfig.states || c.states || getStates()
    const store = storedConfig.store || c.store || null

    storedConfig = c.app || {
        initalized: true,
        style: style,
        history: history,
        startroute: c.startroute || storedConfig.startroute || '/',
        parentroute: c.parentroute || storedConfig.parentroute || null,
        components: components,
        routes: routes,
        contents: contents,
        states: states,
        store: store
    }

    return storedConfig

}
