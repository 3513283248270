export default function({
                            posttype = 'post',
                            t,
                            curlang = {},
                            fetchs,
                            getHelpers,
                            history,
                            dialogClose,
                            dialogOpen,
                            snack,
                            setState
                        }) {

    const {
        approvepostdefaultfail = 'Sorry, there was an issue approve this post, please try again',
        approvepostsuccessmessage = 'This post has been approved',
        banpostdefaultfail = 'Sorry, there was an issue approve this post, please try again',
        banpostsuccessmessage = 'This post has been approved'
    } = curlang

    if (t) {

        t['approvefetch' + posttype] = fetchs['approve' + posttype]({
            getHelpers: getHelpers,
            history: history,
            success: function({ response }) {
                dialogClose()
                snack(approvepostsuccessmessage)
                const state = t.state
                const posts = state.posts
                if (posts) {
                    posts.map(function(post) {
                        if (post.id === response.postId || post.id === response.userId) {
                            post.status = (response.userStatus) ? response.userStatus : response.postStatus
                        }
                    })
                }
                setState({
                    posts: posts
                })
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || approvepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        t['banfetch' + posttype] = fetchs['ban' + posttype]({
            getHelpers: getHelpers,
            history: history,
            success: function({ response }) {
                dialogClose()
                snack(banpostsuccessmessage)
                const state = t.state
                const posts = state.posts
                if (posts) {
                    posts.map(function(post) {
                        if (post.id === response.postId || post.id === response.userId) {
                            post.status = (response.userStatus) ? response.userStatus : response.postStatus
                        }
                    })
                }
                setState({
                    posts: posts
                })
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || banpostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        t['banfunction' + posttype] = function(e, d) {

            const { post } = d

            const {
                banpostsubtitle = 'Ban',
                banpostquestion = 'Are you sure want to ban this post?',
                submittext = 'Submit',
                canceltext = 'Cancel'
            } = curlang

            const banfetch = t['banfetch' + posttype]

            const { processingStart, processingEnd } = dialogOpen({
                title: banpostsubtitle,
                text: banpostquestion,
                submittext: submittext,
                canceltext: canceltext,
                action: function() {

                    const data = post
                    const resetForm = function() {
                    }
                    const invalidateForm = function(data) {
                        console.log(data)
                        dialogClose()
                    }
                    const refs = {
                        processingEnd: function() {
                            if (processingEnd) processingEnd()
                        },
                        processingStart: function() {
                            if (processingStart) processingStart()
                        }
                    }
                    banfetch(data, resetForm, invalidateForm, refs)
                }
            })
        }

        t['approvefunction' + posttype] = function(e, d) {
            const { post } = d

            const {
                approvepostsubtitle = 'Approve',
                approvepostquestion = 'Are you sure want to approve this post?',
                submittext = 'Submit',
                canceltext = 'Cancel'
            } = curlang

            const approvefetch = t['approvefetch' + posttype]

            const { processingStart, processingEnd } = dialogOpen({
                title: approvepostsubtitle,
                text: approvepostquestion,
                submittext: submittext,
                canceltext: canceltext,
                action: function() {

                    const data = post
                    const resetForm = function() {
                    }
                    const invalidateForm = function(data) {
                        console.log(data)
                        dialogClose()
                    }
                    const refs = {
                        processingEnd: function() {
                            if (processingEnd) processingEnd()
                        },
                        processingStart: function() {
                            if (processingStart) processingStart()
                        }
                    }
                    approvefetch(data, resetForm, invalidateForm, refs)

                }
            })
        }

    }
}
