import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
    getInitialState() {
        const { data } = this.props

        const drawerOpen = (data) ? data.getDataCurrent().drawerOpen : false
        const left = (data) ? data.getDataCurrent().left : false

        return {
            drawerOpen: drawerOpen,
            left: left
        }
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        return nextState.drawerOpen !== state.drawerOpen
    },
    open(left) {

        const { data } = this.props

        this.setState({
            drawerOpen: true,
            left: (left) ? left + 25 : 0
        })

        if (data) data.setDataCurrent('drawerOpen', true)
        if (data) data.setDataCurrent('left', left + 25)

        return true
    },
    close() {

        const { data } = this.props

        if (this.state && this.state.drawerOpen || !this.state) {
            this.setState({
                drawerOpen: false,
                left: 0
            })
        }

        if (data) data.setDataCurrent('drawerOpen', false)
        if (data) data.setDataCurrent('left', 0)

        return null
    },
    render: function() {
        const { children, className = '', openClassName = 'open', containerStyle = {} } = this.props

        return (
            <div className={(this.state && this.state.drawerOpen) ? className + ' ' + openClassName : className}
                 style={(this.state && this.state.drawerOpen && this.state.left) ?
                     {
                         paddingLeft: this.state.left + 'px',
                         ...containerStyle
                     }
                     :
                     { ...containerStyle }
                 }>
                {children}
            </div>
        )
    }
})
