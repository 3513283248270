import React from 'react'
import createReactClass from 'create-react-class'

function getTemplate({ input, name, Template }) {
    return createReactClass({
        render() {
            return (
                <Template
                    {...input}
                    {...this.props}
                    name={name}
                />
            )
        }
    })
}

export function getTemplates({ input, posttypeTemplates, name }) {
    const r = {}
    Object.keys(posttypeTemplates).map(function(t) {
        r[name + t] = getTemplate({ input, name, Template: posttypeTemplates[t] })
        r[name + t].displayName = name + t
    })
    return r
}

export function getEditdata({ p, posttypeEditdata, name }) {
    return {
        [name]: posttypeEditdata(p)
    }
}

export function getUserpageTemplates({ curlang, posttypeTemplates, name }) {
    const r = {}
    Object.keys(posttypeTemplates).map(function(t) {
        const rname = (t === 'posts') ? name : name + t
        r[rname] = {
            template: posttypeTemplates[t],
            name: 'user' + rname,
            title: curlang['wapplruserapp' + rname + 'title']
        }
        r[rname].template.displayName = rname
    })
    return r
}
