import React from 'react'
import createReactClass from 'create-react-class'
import SvgIcon from 'material-ui/SvgIcon'

export default createReactClass({
    render: function() {
        return (
            <SvgIcon {...this.props} viewBox={'0 -3 33 40'}>
                <path
                    d='M 17.996,32L 12,32 L 12,16 l-4,0 l0-5.514 l 4-0.002l-0.006-3.248C 11.993,2.737, 13.213,0, 18.512,0l 4.412,0 l0,5.515 l-2.757,0 c-2.063,0-2.163,0.77-2.163,2.209l-0.008,2.76l 4.959,0 l-0.585,5.514L 18,16L 17.996,32z' />
            </SvgIcon>
        )
    }
})
