import React from 'react'
import createReactClass from 'create-react-class'

import ReactDOM from 'react-dom'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import s from '../empty.css'

import AutoComplete from 'material-ui/AutoComplete'
import IconButton from 'material-ui/IconButton'
import CloseIcon from 'material-ui/svg-icons/navigation/close'
import SearchIcon from 'material-ui/svg-icons/action/search'
import BackIcon from 'material-ui/svg-icons/navigation/arrow-back'

import MobileDetect from 'mobile-detect'

const Searchbar = createReactClass({
    getInitialState() {
        const { data } = this.props
        const { queryName = 'text' } = this.props
        return {
            searchText: data.getDataCurrent()[queryName],
            isMounted: false,
            full: data.getDataCurrent().full,
            items: {},
            focused: false
        }
    },
    handleUpdateInput: function(searchText) {
        const { queryName = 'text' } = this.props
        const waitTime = 1000
        const now = new Date().getTime()
        const lastChange = (this.lastChange) ? this.lastChange : now - waitTime
        if (this.waiter) clearTimeout(this.waiter)
        if (now - lastChange > waitTime - 1) {
            this.lastChange = now
            this.setState({
                searchText: searchText
            })
            this.props.searchData.setDataCurrent(queryName, searchText)
        } else {
            const t = this
            this.waiter = setTimeout(function() {
                if (t.waiter) clearTimeout(t.waiter)
                const searchTextField = (t.state && t.state.items &&
                    t.state.items.autocomplete && t.state.items.autocomplete.refs &&
                    t.state.items.autocomplete.refs.searchTextField) ? t.state.items.autocomplete.refs.searchTextField : null
                const input = (searchTextField) ? searchTextField.input : null
                const searchText = (input) ? input.value : null
                if (typeof searchText == 'string' && t.state.isMounted && t.state.searchText !== searchText) {
                    t.lastChange = new Date().getTime()
                    t.setState({
                        searchText: searchText
                    })
                    t.props.searchData.setDataCurrent(queryName, searchText)
                }
            }, waitTime)
        }
    },
    handleSubmit: function(e) {
        if (e.keyCode === 13) {
            const searchTextField = (this.state && this.state.items &&
                this.state.items.autocomplete && this.state.items.autocomplete.refs &&
                this.state.items.autocomplete.refs.searchTextField) ? this.state.items.autocomplete.refs.searchTextField : null
            const input = searchTextField.input
            if (input) input.blur()
            e.preventDefault()
        }
    },
    handleNewRequest: function(searchText) {
        const { queryName = 'text' } = this.props
        this.setState({
            searchText: searchText
        })
        this.props.searchData.setDataCurrent(queryName, searchText)
        const { history, to } = this.props
        this.historyPush(searchText, (history.location.pathname !== to))
    },
    historyPush: function(searchText, saveLastLocation, e) {

        const { store, history, to, parentroute, disableGoToSearchOnFocus } = this.props
        const iOS = this.isIOs()

        if (to && history && history.location.pathname === to ||
            parentroute && history && history.location.pathname === parentroute || e || iOS || disableGoToSearchOnFocus) {
            if (saveLastLocation && history) {
                this.lastLocation.pathname = history.location.pathname
                this.lastLocation.hash = history.location.hash
                this.lastLocation.search = history.location.search
                this.lastLocation.length = history.length
            }
            if (history && to) {
                this.historyHelpers.refreshFromSearch({ store })
                const search = this.historyHelpers.getDataCurrent()
                search.page = ''
                search.id = ''
                this.historyHelpers.objectToSearch(to, search)
            }
        }

        if (e) e.preventDefault()

    },
    handleFocus: async function(e) {

        const history = this.props.history
        const { disableGoToSearchOnFocus } = this.props
        let to = this.props.to
        const iOS = this.isIOs()

        if (history && to && !history.location && !iOS && !disableGoToSearchOnFocus ||
            history && to && history.location && history.location.pathname !== to && !iOS && !disableGoToSearchOnFocus) {

            this.historyPush(null, true, e)

        } else {

            if (e) e.preventDefault()

            const r = {
                full: this.state.full,
                focused: this.state.focused
            }

            if (!this.state.full && this.props.onFocusHandle) {
                const opened = this.props.onFocusHandle()
                if (opened) {
                    this.props.data.setDataCurrent('full', true)
                    r.full = true
                }
            } else if (this.state.full && this.props.onFocusHandle) {
                this.props.onFocusHandle()
            }

            if (!this.state.focused) {
                r.focused = true
            }

            if (r.full !== this.state.full || r.focused !== this.state.focused) {
                await this.setState(r)
            }

        }

    },
    touchEnd: function(e, fn, p) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === p) {
            const t = this
            if (t[fn]) t[fn](e)
        }
    },
    touchStart: function(e, fn, p) {
        if (e && e.type === 'mousedown' || e && e.type === 'touchstart' || e && e.type === p) {
            const t = this
            if (t[fn]) t[fn](e)
        }
    },
    lastLocation: {},
    clearText: function(e) {

        const { store } = this.props
        const { queryName = 'text' } = this.props

        if (this.state.searchText) {

            this.setState({
                searchText: '',
                focused: false
            })

            this.props.searchData.setDataCurrent(queryName, '')
            const so = this.historyHelpers.getDataFromHistory({ store })
            so[queryName] = ''
            so.page = ''
            const searchtext = this.historyHelpers.objectToString(so)
            this.historyHelpers.historyPush({ search: searchtext })

        } else {

            this.setState({
                searchText: '',
                focused: false
            })
            this.props.searchData.setDataCurrent(queryName, '')
            if (this.props.onClearHandle) {
                const closed = this.props.onClearHandle()
                this.props.data.setDataCurrent('full', false)
                if (closed && this.state.full) {
                    this.setState({
                        full: false
                    })
                }
            }
            const history = this.props.history
            const backpath = this.lastLocation.pathname
            const backsearch = this.lastLocation.search
            const backhash = this.lastLocation.hash
            const parentroute = this.props.parentroute
            if (history) history.push({ pathname: parentroute || backpath || '/', search: backsearch, hash: backhash })
            this.historyHelpers.resetToDefaults()

        }

        e.preventDefault()


    },
    openClick: function(e) {
        const history = this.props.history
        const { disableGoToSearchOnFocus } = this.props
        let to = this.props.to
        const iOS = this.isIOs()

        if (history && to && !history.location && !iOS && !disableGoToSearchOnFocus ||
            history && to && history.location && history.location.pathname !== to && !iOS && !disableGoToSearchOnFocus) {
            this.historyPush(null, true, e)
        } else {
            this.open(e)
        }
    },
    isMobile: function() {
        const md = new MobileDetect(window.navigator.userAgent)
        return md.mobile() || md.tablet() || md.phone()
    },
    isIOs: function() {
        const us = ('undefined' !== typeof window) ? window.navigator.userAgent : null
        if (us) {
            const md = new MobileDetect(window.navigator.userAgent)
            return (md.is('iPhone') || md.is('iOS'))
        }
        return null
    },
    open: async function(e) {

        const searchTextField = (this.state && this.state.items &&
            this.state.items.autocomplete && this.state.items.autocomplete.refs &&
            this.state.items.autocomplete.refs.searchTextField) ? this.state.items.autocomplete.refs.searchTextField : null
        const input = (searchTextField) ? searchTextField.input : null

        if (input) {
            if (this.isIOs()) {
                await this.handleFocus(e)
            } else {
                input.focus()
            }
        }

        if (e) {
            e.preventDefault()
            return false
        }

    },
    back: function(e) {
        if (this.props.onClearHandle) {
            const closed = this.props.onClearHandle()
            if (closed && this.state.full) {
                this.props.data.setDataCurrent('full', false)
                this.setState({
                    full: false
                })
            }
        }
        const searchTextField = (this.state && this.state.items &&
            this.state.items.autocomplete && this.state.items.autocomplete.refs &&
            this.state.items.autocomplete.refs.searchTextField) ? this.state.items.autocomplete.refs.searchTextField : null
        const input = searchTextField.input
        if (input) input.blur()

        this.setState({
            focused: false
        })

        e.preventDefault()
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    setRef: function(a, e) {
        this.state.items[a] = e
    },
    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    getMatch: function(searchText, key) {
        let sT = searchText
        if (sT.toLocaleLowerCase) sT = sT.toLocaleLowerCase()
        sT = (sT.normalize) ? sT.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : sT
        sT = (sT.replace) ? sT.replace(/\s/g, '') : sT

        let k = key
        if (k.toLocaleLowerCase) k = k.toLocaleLowerCase()
        k = (k.normalize) ? k.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : k
        k = (k.replace) ? k.replace(/\s/g, '') : k

        if (typeof k == 'string' && k && typeof sT == 'string' && sT) {
            if (k.indexOf(sT) !== -1) {
                return true
            }

            const sa = sT.split(/(?=[\0-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])/)
            const sauniq = sa.filter(function(value, index, self) {
                return self.indexOf(value) === index
            })
            const sTus = sauniq.join('')

            if (k.indexOf(sTus) !== -1) {
                return true
            }

        }
        return false
    },
    getTextSearchNumber: function(searchText, key) {
        const getMatch = this.getMatch
        const sa = (searchText && searchText.split) ? searchText.split(' ') : []
        const ka = (key && key.split) ? key.split(' ') : []
        let r = 0

        sa.map(function(sw) {
            ka.map(function(kw) {
                const found = getMatch(sw, kw)
                if (found) r = r + 1
            })
        })

        return r
    },
    getDataSource: function(dataSource, searchText) {
        const getTextSearchNumber = this.getTextSearchNumber
        const dataSourceN = dataSource.map(function(key) {
            return { n: getTextSearchNumber(searchText, key), key }
        })
        dataSourceN.sort(function(a, b) {
            return b.n - a.n
        })
        return dataSourceN.map(function(d) {
            return d.key
        })
    },
    render: function() {

        const {
            style = s, hintText = '', searchData, data, Closeicon = CloseIcon,
            Searchicon = SearchIcon, Backicon = BackIcon, history, to, maxSearchResults = 5, store
        } = this.props

        const { queryName = 'text' } = this.props

        const setRef = this.setRef
        const touchEnd = this.touchEnd

        this.state.dataSource = searchData.getDataSource()
        this.state.searchText = searchData.getDataCurrent()[queryName]
        this.state.full = data.getDataCurrent().full
        this.historyHelpers = searchData.getHistoryHelpers()

        let pathname = (history.location) ? history.location.pathname : ''
        let focus = false

        if (pathname === to) {
            const searchData = this.historyHelpers.refreshFromSearch({ store })
            const searchText = searchData[queryName]
            if (searchText === '') {
                focus = true
                if (this.isIOs()) {
                    this.open()
                }
            } else if (this.state.full) {
                if (this.props.onFocusHandle) this.props.onFocusHandle()
            }
        }

        const getDataSource = this.getDataSource

        return (
            <div className={(!this.state.full) ? style.searchcontainer : style.searchcontainer + ' ' + style.full}>
                <form method='get' className={style.searchform} onKeyDown={this.handleSubmit}>
                    <div className={style.backbutton}>
                        <IconButton label='Back' onTouchTap={function(e) {
                            touchEnd(e, 'back', 'click')
                        }} onTouchStart={function() {
                        }}>
                            <Backicon />
                        </IconButton>
                    </div>
                    <div className={style.searchbutton}>
                        <IconButton label='Search' onTouchTap={function(e) {
                            touchEnd(e, 'openClick', 'click')
                        }} onTouchStart={function() {
                        }} onClick={function() {
                        }}>
                            <Searchicon />
                        </IconButton>
                    </div>
                    <div
                        className={(this.state.searchText && this.state.isMounted || history && history.location && history.location.pathname === this.props.to && this.state.isMounted) ? style.closebutton : style.closebuttonhide}>
                        <IconButton label='Clear' onTouchTap={function(e) {
                            touchEnd(e, 'clearText', 'click')
                        }} onTouchStart={function() {
                        }}>
                            <Closeicon />
                        </IconButton>
                    </div>
                    <div className={style.inputcontainer}>
                        {(this.state.isMounted) ?
                            <AutoComplete
                                ref={function(e) {
                                    setRef('autocomplete', e)
                                }}
                                hintText={hintText}
                                searchText={this.state.searchText}
                                onFocus={this.handleFocus}
                                onUpdateInput={this.handleUpdateInput}
                                onNewRequest={this.handleNewRequest}
                                dataSource={getDataSource(this.state.dataSource, this.state.searchText)}
                                filter={
                                    function() {
                                        return true
                                    }
                                }
                                openOnFocus={false}
                                fullWidth={true}
                                autoFocus={focus}
                                maxSearchResults={maxSearchResults}
                            />
                            : null}
                    </div>
                </form>
            </div>
        )
    }
})

export default createReactClass({
    render() {
        const { style = s, disableInitWithStyles } = this.props
        const input = { ...this.props, style: style }
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Searchbar) : Searchbar
        return (
            <R {...input} />
        )
    }
})
