import React from 'react'
import createReactClass from 'create-react-class'

import ToggleComponent from './index.js'
import Formsy from 'formsy-react'

export default createReactClass({
    mixins: [Formsy.Mixin],
    getInitialState: function() {
        return {
            value: this.props.value
        }
    },
    handleUpdate: async function(e, nextValue) {
        const { onChange } = this.props
        await this.setValue(nextValue)
        if (onChange) onChange(e, nextValue)
    },
    render() {

        const {
            validations, // eslint-disable-line no-unused-vars
            validationError, // eslint-disable-line no-unused-vars
            validationErrors, // eslint-disable-line no-unused-vars
            ...rest
        } = this.props

        const required = this.props.required
        const v = this.getValue() || false

        return (
            <ToggleComponent
                {...rest}
                onChange={this.handleUpdate}
                errorText={(required) ? '' : this.getErrorMessage()}
                value={v}
            />
        )
    }

})
